import { Toast } from 'vant';
import { configProp, levelProp } from "@/api/config";
const liveModule = {
    state: {
      liveInfo: {}, //直播间信息
      liveList: [], //直播列表
      liveAE: {}, // 
    },
    mutations:{
      setState(state, data = {}) {
        Object.keys(data).forEach(key => {
          state[key] = data[key];
        });
      } 
    },
    actions:{
      // 座驾列表
      configProp({ commit }) {
        configProp().then(res => {
            if (res.code === 0 || res.code === '0') {
              commit("setState", { allGift: res.data });
            } else {
              if (res.msg) Toast(res.msg);
            }
        }).catch(err => {
            console.log(err);
        });
      },
      // 等级特效
      getLevelProp({ commit }) {
        levelProp().then(res => {
          if (res.code === 0 || res.code === '0') {
            commit("setState", { levelEffects: res.data });
          } else {
            if (res.msg) Toast(res.msg);
          }
        }).catch(err => {
            console.log(err);
        });
      },
    }
}

export default liveModule