<template>
  <div class="leaderboard next">
    <div class="title">
      <div class="fixed" @click="$router.push('/home')">
        <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="12456" width="0.3rem" height="0.3rem">
          <path
            d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
            fill="#fff" p-id="12457"></path>
        </svg>
      </div>

      <div class="leaderTitle">
        <van-tabs v-model:active="activeName" background="transparent" color="#fff" title-active-color="#fff"
          @click-tab="changeTab" title-inactive-color="#fff" :ellipsis="false">
          <van-tab v-for="(item, index) in topTab" :key="`${index}--tab-leaderboard`" :title="item.nickname"
            :name="item.id">
          </van-tab>
        </van-tabs>
      </div>

      <div class="suTabBox">
        <van-tabs v-model:active="activeSuName" background="transparent" color="#fff" title-active-color="#fff"
          @click-tab="changeSuTab" title-inactive-color="#ff54a8" :ellipsis="false">
          <van-tab v-for="(item, index) in suTab" :key="`${index}--sutab-leaderboard`" :title="item.nickname"
            :name="item.rankPeriodCode">
          </van-tab>
        </van-tabs>
      </div>
    </div>

    <div class="topBox flexBetweenTop">
      <div @click="goUserDetail(second)" v-if="second.uid" class="topItem hasvalue">
        <div class="topBg">
          <div id="level2"></div>
          <!-- <img class="topUserBg" src="../../assets/img/home/leaderboard/2.png" />
          <img v-if="!second.rankHidden" class="topUser" v-real-img="second.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
          <img v-else class="topUser" src="../../assets/img/live/mysteryMan.png"/> -->
          <img class="topUserInLive" v-if="second.liveId" src="../../assets/img/home/leaderboard/live.png" />
        </div>
        <div class="topUserName">{{ second.rankHidden ? $t('liveRoom.mysteryMan') : second.nickname }}</div>
        <div class="UserProof flexCenterBL">
          <div class="userLevel">
            <img v-real-img="getImg(second.rankObjectLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)" />
            <span :class="{ 'small': second.rankObjectLevel < 10 }">{{ second.rankObjectLevel }}</span>
          </div>
          <img v-if="second.sex && second.sex == 2" src="../../assets/img/home/leaderboard/woman.png" />
          <img v-else src="../../assets/img/home/leaderboard/man.png" />
        </div>
        <div class="money">
          <img src="../../assets/img/gold.png" />
          <span>{{ second.rankValue }}</span>
        </div>

        <div v-show="second.uid != member.uid" @click.stop="follow(second, 'second')" class="btn"
          :class="second.isFollow ? 'default' : 'active'">
          {{ second.isFollow ? $t('my.follow.followed') : `+ ${$t('my.follows')}` }}
        </div>
      </div>
      <div v-else class="topItem"></div>


      <div @click="goUserDetail(first)" v-if="first.uid" class="topItem hasvalue">
        <div class="topBg">
          <div id="level1"></div>
          <!-- <img class="topUserBg" src="../../assets/img/home/leaderboard/1.png" />
          <img v-if="!first.rankHidden" class="topUser" v-real-img="first.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
          <img v-else class="topUser" src="../../assets/img/live/mysteryMan.png"/> -->
          <img class="topUserInLive" v-if="first.liveId" src="../../assets/img/home/leaderboard/live.png" />
        </div>
        <div class="topUserName">{{ first.rankHidden ? $t('liveRoom.mysteryMan') : first.nickname }}</div>
        <div class="UserProof flexCenterBL">
          <div class="userLevel">
            <img v-real-img="getImg(first.rankObjectLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)" />
            <span :class="{ 'small': first.rankObjectLevel < 10 }">{{ first.rankObjectLevel }}</span>
          </div>
          <img v-if="first.sex && first.sex == 2" src="../../assets/img/home/leaderboard/woman.png" />
          <img v-else src="../../assets/img/home/leaderboard/man.png" />
        </div>
        <div class="money">
          <img src="../../assets/img/gold.png" />
          <span>{{ first.rankValue }}</span>
        </div>

        <div v-show="first.uid != member.uid" @click.stop="follow(first, 'first')" class="btn"
          :class="first.isFollow ? 'default' : 'active'">
          {{ first.isFollow ? $t('my.follow.followed') : `+ ${$t('my.follows')}` }}
        </div>
      </div>
      <div v-else class="topItem"></div>

      <div @click="goUserDetail(third)" v-if="third.uid" class="topItem hasvalue">
        <div class="topBg">
          <div id="level3"></div>
          <!-- <img class="topUserBg" src="../../assets/img/home/leaderboard/3.png" />
          <img v-if="!third.rankHidden" class="topUser" v-real-img="third.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
          <img v-else class="topUser" src="../../assets/img/live/mysteryMan.png"/> -->
          <img class="topUserInLive" v-if="third.liveId" src="../../assets/img/home/leaderboard/live.png" />
        </div>
        <div class="topUserName">{{ third.rankHidden ? $t('liveRoom.mysteryMan') : third.nickname }}</div>
        <div class="UserProof flexCenterBL">
          <div class="userLevel">
            <img v-real-img="getImg(third.rankObjectLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)" />
            <span :class="{ 'small': third.rankObjectLevel < 10 }">{{ third.rankObjectLevel }}</span>
          </div>
          <img v-if="third.sex && third.sex == 2" src="../../assets/img/home/leaderboard/woman.png" />
          <img v-else src="../../assets/img/home/leaderboard/man.png" />
        </div>
        <div class="money">
          <img src="../../assets/img/gold.png" />
          <span>{{ third.rankValue }}</span>
        </div>

        <div v-show="third.uid != member.uid" @click.stop="follow(third, 'third')" class="btn"
          :class="third.isFollow ? 'default' : 'active'">
          {{ third.isFollow ? $t('my.follow.followed') : `+ ${$t('my.follows')}` }}
        </div>
      </div>
      <div v-else class="topItem"></div>
    </div>

    <div class="leaderCont">
      <div v-for="(item, index) in list" :key="`${index}--list-leaderboard-liveRoom`" @click="goUserDetail(item)"
        class="leaderContItem flexCenter">
        <div class="flexCenter leaderContItemLeft">
          <div class="sort">{{ index + 4 }}</div>
          <div class="useraAvatarBox">
            <img v-if="!item.rankHidden" class="useraAvatar" v-real-img="item.avatar"
              :errorimg="require(`../../assets/img/errorImg/2.png`)" />
            <img v-else class="useraAvatar" src="../../assets/img/live/mysteryMan.png" />
            <img class="userInLive" v-if="item.liveId" src="../../assets/img/home/leaderboard/live.png" />
          </div>
          <div class="userInfo">
            <p>{{ item.rankHidden ? $t('liveRoom.mysteryMan') : item.nickname }}</p>
            <p>{{ $t('my.finance.currency') }}: {{ item.rankValue }}</p>
          </div>
        </div>

        <div class="flexCenter leaderContItemRight">
          <div class="flexCenter">
            <div class="userLevel">
              <img v-real-img="getImg(item.rankObjectLevel)" :errorimg="require(`../../assets/img/errorImg/3.png`)" />
              <span :class="{ 'small': item.rankObjectLevel < 10 }">{{ item.rankObjectLevel }}</span>
            </div>
            <img v-if="item.sex && item.sex == 2" src="../../assets/img/home/leaderboard/woman.png" />
            <img v-else src="../../assets/img/home/leaderboard/man.png" />
          </div>
          <div>
            <div v-show="item.uid != member.uid" @click.stop="follow(item, 'list')" class="btn"
              :class="item.isFollow ? 'default' : 'active'">
              {{ item.isFollow ? $t('my.follow.followed') : `+ ${$t('my.follows')}` }}
            </div>
          </div>

        </div>
      </div>
    </div>

    <userInfo v-if="showUserInfo" @colsePopup="colsePopup" @refresh="refresh"></userInfo>

    <otherWeb></otherWeb>
  </div>
</template>

<script>
import { configRank } from "@/api/config";
import { rankDetail, liveFollow } from "@/api/index";
import userInfo from "@/pages/components/userInfo.vue";
import { mapState } from 'vuex';
import otherWeb from "@/pages/components/otherWeb.vue";
import interLive from '@/pages/mixin/interRoom';
export default {
  name: "leaderboard",
  mixins: [interLive],
  components: {
    otherWeb,
    userInfo
  },
  data() {
    return {
      activeName: -1,
      topTab: [],
      activeSuName: -1,
      first: {},
      second: {},
      third: {},
      list: [],
      showUserInfo: false
    }
  },
  created() {
    this.getRank();
  },
  computed: {
    ...mapState(['member', 'levelEffects']),
    suTab() {
      let arr = [];
      if (this.topTab.length > 0) {
        let index = this.topTab.findIndex(item => {
          return item.id == this.activeName
        });

        if (index != -1 && this.topTab[index].children && this.topTab[index].children.length > 0) {
          arr = this.topTab[index].children;
        }
      }

      return arr;
    },
    isManage() {
      let flag = false;
      if (this.member && this.member.badgeList && this.member.badgeList.length > 0) {
        let index = this.member.badgeList.findIndex(item => {
          return item == '4'
        });

        if (index != -1) {
          flag = true;
        }
      }
      return flag;
    }
  },
  methods: {
    // 关闭用户资料弹框
    colsePopup() {
      this.showUserInfo = false;
    },
    // 刷新列表
    refresh() {
      this.getRankDetail();
    },
    follow(item, key) {
      let params = {
        isFollow: item.isFollow ? false : true,
        targetId: item.uid
      }
      liveFollow(params).then(res => {
        if (res.code === 0 || res.code === '0') {
          let str = item.isFollow ? this.$t('my.follow.Unfollowed') : this.$t('my.follow.followeSuccess');
          this.$toast(str);
          if (key == 'list') {
            let index = this[key].findIndex(xitem => {
              return xitem.uid == item.uid
            });

            if (index != -1) {
              this[key][index].isFollow = params.isFollow;
            }
          } else {
            this[key].isFollow = params.isFollow;
          }
          
          // this.getRankDetail();
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 点击用户头像
    goUserDetail(item) {
      if (!item.liveId) {
        let obj = JSON.parse(JSON.stringify(item));
        obj.isFresh = true;
        this.$store.commit("setState", { betPramas: obj });
        this.showUserInfo = true;
      } else {
        this.goLive(item);
      }
    },
    // 点击直播间
    goLive(item) {
      if (!this.member) return this.$router.push('/login');
      if (item.isAd && this.CheckUrl(item.adJumpUrl)) {
        this.$store.commit("setState", { otherWebUrl: item.adJumpUrl, isShowOtherWeb: true });
      }
      if (!item.anchorId) return;

      this.getAnchorBase(item);
    },
    // 校验是否是有效URL
    CheckUrl(url) {
      let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
      return !!reg.test(url);
    },
    getRankDetail() {
      let params = {
        rankType: this.activeSuName, //1日榜2周榜3月榜4昨日榜
        type: this.activeName,  //1主播top2送礼3pk4游戏(彩票)
      }

      this.first = {};
      this.second = {};
      this.third = {};
      this.list = [];

      rankDetail(params).then(res => {
        if (res.code === 0 || res.code === '0') {
          if (res.data.length > 0) {
            this.first = res.data[0];
            setTimeout(() => {
              this.initMachineSVGA(res.data[0], 'level1', 1);
            }, 100);

            if (res.data.length > 1) {
              this.second = res.data[1];
              setTimeout(() => {
                this.initMachineSVGA(res.data[1], 'level2', 2);
              }, 100);
            }

            if (res.data.length > 2) {
              this.third = res.data[2];
              setTimeout(() => {
                this.initMachineSVGA(res.data[2], 'level3', 3);
              }, 100);
            }

            if (res.data.length > 3) {
              this.list = res.data.slice(3);
            }
          }
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(err => {
        console.log(err);
      });
    },
    changeTab() {
      this.activeSuName = this.suTab[0].rankPeriodCode;
      this.getRankDetail();
    },
    changeSuTab() {
      this.getRankDetail();
    },
    // 匹配用户等级图片
    getImg(val) {
      if (val <= 10) {
        return require("../../assets/img/noble/level/level_1-10.png");
      }

      if (10 < val && val <= 20) {
        return require("../../assets/img/noble/level/level_11-20.png");
      }

      if (20 < val && val <= 30) {
        return require("../../assets/img/noble/level/level_21-30.png");
      }

      if (30 < val && val <= 40) {
        return require("../../assets/img/noble/level/level_31-40.png");
      }

      if (40 < val && val <= 50) {
        return require("../../assets/img/noble/level/level_41-50.png");
      }

      if (50 < val && val <= 60) {
        return require("../../assets/img/noble/level/level_51-60.png");
      }

      if (60 < val && val <= 70) {
        return require("../../assets/img/noble/level/level_61-70.png");
      }

      if (70 < val && val <= 80) {
        return require("../../assets/img/noble/level/level_71-80.png");
      }

      if (80 < val && val <= 90) {
        return require("../../assets/img/noble/level/level_81-90.png");
      }

      if (90 < val) {
        return require("../../assets/img/noble/level/level_91-105.png");
      }
    },
    getRank() {
      // 排行榜列表
      configRank().then(res => {
        if (res.code === 0 || res.code === '0') {
          this.topTab = res.data;
          if (this.topTab.length > 0) {
            this.activeName = this.topTab[0].id;
          }
          if (this.suTab.length > 0) {
            this.activeSuName = this.suTab[0].rankPeriodCode;
          }
          this.getRankDetail();
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 第一 - 第三 相框
    initMachineSVGA(item, domId, num) {
      if (!item) return;
      let that = this;
      this[`player${num}`] = new SVGA.Player(`#${domId}`);
      this[`parser${num}`] = new SVGA.Parser(`#${domId}`);
      this[`player${num}`].loops = 0;

      let resourceUrl = '',
        resourceIndex = this.levelEffects.findIndex(item => {
          return item.level == -num
        });

      if (resourceIndex != -1) {
        resourceUrl = this.levelEffects[resourceIndex].resourceUrl
      }

      // 必须是服务器地址或者是线上的地址 本地是不可以的 会报错
      this[`parser${num}`].load(`${resourceUrl}`, (videoItem) => {
        that[`player${num}`].setVideoItem(videoItem);
        let url = '';

        if (item.rankHidden) {
          url = require("../../assets/img/live/mysteryMan.png")
        } else {
          url = item.avatar ? item.avatar : require("../../assets/img/errorImg/2.png")
        }

        that[`player${num}`].setImage(url, 'avatardttxk');
        that[`player${num}`].startAnimation();
      }, err => {
        console.log(err);
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.leaderboard {
  font-size: 0.28rem;
  color: #fff;
  background-color: #fff;

  &.next {
    padding-top: 1.78rem;
    overflow-x: hidden;
    overflow-y: scroll;

    .title {
      background-color: #fa8faf;
      box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0);
      height: 1.8rem;
    }
  }

  .leaderTitle {
    margin-left: 0.88rem;
  }

  .topBox {
    width: 100%;
    height: 5.2rem;
    margin: 0 auto;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    box-align: flex-end;
    -moz-box-align: flex-end;
    -webkit-box-align: flex-end;
    background-image: url("../../assets/img/home/leaderboard/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 0.28rem 0.8rem;

    .topItem {
      margin-right: 0.12rem;
      flex: 1;
      height: 2.8rem;
      border-radius: 0.2rem;
      position: relative;
      padding: 0 0.1rem;
      font-size: 0.24rem;

      &.hasvalue {
        background-color: #fff;
      }

      &:last-child {
        margin-right: 0;

        .topBg {
          .topUserBg {
            width: 1.7rem;
          }

          .topUser {
            top: -0.26rem;
            left: 0.06rem;
          }

          .topUserInLive {
            bottom: 0.32rem;
          }
        }
      }

      &:nth-child(2) {
        margin-bottom: 0.6rem;
      }

      .topBg {
        width: 1.88rem;
        height: 1.7rem;
        margin: -1rem auto 0;
        position: relative;

        #level2,
        #level1,
        #level3 {
          height: 1.7rem;
        }

        .topUserBg {
          width: 1.88rem;
        }

        .topUser {
          position: absolute;
          right: 0;
          left: 0;
          top: 0.1rem;
          margin: auto;
          width: 0.9rem;
          height: 0.9rem;
          bottom: 0;
          border-radius: 50%;
        }

        .topUserInLive {
          position: absolute;
          right: 0.3rem;
          bottom: 0.15rem;
          width: 0.44rem;
        }
      }

      .topUserName {
        color: #333;
        margin-bottom: 0.13rem;
      }

      .money {
        font-size: 0.24rem;
        color: #ff54a8;
        vertical-align: middle;
        margin: 0.16rem 0;

        img {
          width: 0.28rem;
          vertical-align: middle;
          margin-right: 0.1rem;
        }

        span {
          vertical-align: middle;
        }
      }

      .btn {
        margin-top: 0.1rem;
      }
    }

    .UserProof {
      text-align: center;

      .userAvatar {
        width: 0.28rem;
        margin-left: 0.1rem;
      }
    }
  }

  .leaderCont {
    margin-top: 0.4rem;

    .leaderContItem {
      padding: 0 0.28rem;
      margin-bottom: 0.4rem;

      .leaderContItemLeft {
        color: #333;
        width: 50%;

        .sort {
          min-width: 0.5rem;
          margin-right: 0.1rem;
          text-align: left;
        }

        .useraAvatarBox {
          position: relative;
          margin-right: 0.25rem;

          .useraAvatar {
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
          }

          .userInLive {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0.44rem;
          }
        }

        .userInfo {
          text-align: left;

          p:last-child {
            font-size: 0.2rem;
            color: #999;
            margin-top: 0.1rem;
          }
        }
      }

      .leaderContItemRight {
        width: 50%;

        .btn {
          margin-left: 0.4rem;
          min-width: 1.8rem;
        }
      }
    }
  }

  .btn {
    width: 100%;
    padding: 0.08rem 0.2rem;
    border-radius: 0.4rem;

    &.active {
      background: -webkit-linear-gradient(#ffb2cd, #f15f9d, #ffb2cd);
      background: linear-gradient(#ffb2cd, #f15f9d, #ffb2cd);
      color: #fff;
    }

    &.default {
      color: #999;
      border: 1px solid #999;
    }
  }

  .userLevel {
    width: 0.6rem;
    height: 0.3rem;
    line-height: 0.3rem;
    position: relative;
    margin-left: 0.1rem;
    display: inline-block;
    margin-right: 0.1rem;

    img {
      width: 100%;
      margin-left: 0;
    }

    span {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0.05rem;
      color: #fff;

      &.small {
        right: 0.12rem;
      }
    }
  }
}
</style>
<style lang="scss">
.suTabBox {
  margin-top: 0.2rem;

  .van-tabs--line .van-tabs__wrap {
    height: 0.52rem;
  }

  .van-tabs__line {
    height: 0;
  }

  .van-tab {
    margin-right: 0.1rem;
    background-image: url('../../assets/img/home/leaderboard/btn.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &:last-child {
      margin-right: 0;
    }

    &.van-tab--active {
      background-image: url('../../assets/img/home/leaderboard/btnA.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}</style>