<template>
    <div>
        <!-- 玩法说明 -->
        <lotteryRule v-if="showRule" @closeLotteryPopup="closeLotteryPopup"></lotteryRule>

        <!-- 彩票弹框 -->
        <van-popup
            v-model:show="isShowLottery"
            round
            class="gameListDialog"
            :safe-area-inset-bottom="true"
            position="bottom"
            @close="close"
            >
            <div class="lotteryHead">
                <div class="lotteryIssue flexBetween">
                    <div class="flexCenterBL">
                        <img v-if="betPramas.name" class="gameIcon" v-real-img="betPramas.icon" :errorimg="require(`../../../assets/img/errorImg/3.png`)"/>
                        <div>{{ betPramas.chinese }}</div>
                        <img @click="goRule" class="icon" src="../../../assets/img/live/lottery/rule.png" />
                    </div>
                    <div>{{ $t('liveRoom.result') }}</div>
                </div>

                <div class="lotteryRecord flexBetween">
                    <div class="flexCenterBL lotteryIsBet">
                        <div v-show="parseInt(second)<=54">{{$t('lottery.inBet')}}</div>
                        <div class="red" v-show="parseInt(second)>54">{{$t('lottery.closure')}}</div>
                        <div class="lotteryIsBetTime">
                            <span>00</span>:<span v-show="parseInt(second)<=54">{{second > 9 ? second : '0'+ second}}</span>
                            <span v-show="parseInt(second)>54">00</span>
                        </div>
                    </div>

                    <div v-if="lastResult.expect" @click="goLotteryResult(betPramas)" class="lotteryRecordNow">
                        <div class="resultItemRight" v-if="betPramas.name == 'sd' || betPramas.name == 'yuxx' || betPramas.name == 'jsks'">
                            <img v-for="(xitem, xindex) in lastResult.lotteryResult" :key="`${xindex}-liveRoom-lottery-lastResult`" v-real-img="require(`../../../assets/img/lottery/${betPramas.name}/${xitem}.png`)" />
                        </div>

                        <div class="resultItemRight" v-else-if="betPramas.name == 'pk10' || betPramas.name == 'txssc'">
                            <span v-for="(xitem, xindex) in lastResult.lotteryResult" :key="`${xindex}-liveRoom-lottery-lastResult`">{{xitem}}</span>
                        </div>

                        <div class="resultItemRight" v-else-if="betPramas.name == 'yflhc' && lastResult.lotteryResult.length == 8">
                            <span class="gameItem" v-for="(xitem, xindex) in lastResult.lotteryResult" :key="`${xindex}-liveRoom-lottery-lastResult`" v-show="xindex < 6"> {{xitem}}</span>
                            <van-icon name="plus" />
                            <span class="gameItem" v-show="lastResult.lotteryResult[7] == 1" style="background-color:#f90007;"> {{lastResult.lotteryResult[6]}}</span> 
                            <span class="gameItem" v-show="lastResult.lotteryResult[7] == 2" style="background-color:#04f906;"> {{lastResult.lotteryResult[6]}}</span>
                            <span class="gameItem" v-show="lastResult.lotteryResult[7] == 3" style="background-color:#2a00f2;"> {{lastResult.lotteryResult[6]}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div v-if="betPramas.name != 'sd' && gameData.length > 0">
                    <van-tabs v-model:active="betType">
                        <!-- :title-class="{'noBorder': betType > 0 && betType-1 == index }"  -->
                        <van-tab v-for="(item, index) in gameData" :key="`${index}--gameData`" :title="item.lotteryTypeShow">
                            <div class="lotteryCont">
                                <div v-for="(xitem, xindex) in item.arr" :key="`${xindex}-gameData-item.arr`" @click="lotteryItem(xitem)" class="lotteryContItem" 
                                :class="[`${xitem.className}`, {'active': getActive(xitem.playName) }]">
                                    <div v-if="!isNaN(xitem.methodShow[0]) && xitem.methodShow.length > 0 && supportImg.indexOf(xitem.lottery) != -1">
                                        <img v-for="(sitem, sindex) in xitem.methodArr" :key="`${sindex}--xitem.methodShow--live--${xindex}-${index}`" 
                                        v-real-img="`${xitem[`icon${sindex}`]}`" :class="{ 'allBorder': xitem.methodArr.length > 1, 'oneBorder': xitem.methodArr.length == 1 }"/>
                                    </div>
                                    <span v-else>{{ xitem.methodShow }}</span>
                                    <div> {{ xitem.lines }}</div>
                                </div>
                            </div>
                        </van-tab>
                    </van-tabs>
                </div>

                <!-- 色蝶排版 -->
                <div v-if="betPramas.name == 'sd' && gameData.length > 0" class="lotteryCont lotteryContSd">
                    <div class="lotterySdItem">
                        <div v-for="(item, index) in gameData" :key="`${index}-liveRoom-sd-one`" >
                            <div v-if="index<3" @click="lotteryItem(item)" class="lotterySdItemItem" :class="{'active': getActive(item.playName)}">
                                <div class="flexCenterBL">
                                    <img v-for="(xitem, xindex) in item.methodArr" :key="`${xindex}-liveRoom-sd-one-xitem`" v-real-img="require(`../../../assets/img/lottery/sd/${xitem}.png`)" />
                                </div>
                                <div>{{ item.lines }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="lotterySdItemMiddle">
                        <div v-for="(item, index) in sdMiddle" :key="`${index}-liveRoom-sd-two`" @click="lotteryItem(item)" class="lotterySdItemMiddleItem" :class="{'active': getActive(item.playName)}">
                            <div>{{ item.methodShow }}</div>
                            <div>{{ item.lines }}</div>
                        </div>
                    </div>

                    <div class="lotterySdItem">
                        <div v-for="(item, index) in gameData" :key="`${index}-liveRoom-sd-third`" >
                            <div v-if="index>=3 && !item.methodArr1" @click="lotteryItem(item)" class="lotterySdItemItem" :class="{'active': getActive(item.playName)}">
                                <div class="flexCenterBL">
                                    <img v-for="(xitem, xindex) in item.methodArr" :key="`${xindex}-liveRoom-sd-third-xitem`" v-real-img="require(`../../../assets/img/lottery/sd/${xitem}.png`)" />
                                </div>
                                <div>{{ item.lines }}</div>
                            </div>

                            <div v-if="index>=3 && item.methodArr1" @click="lotteryItem(item)" class="lotterySdItemItem" :class="{'lotterySdItemItem-4h4b': item.methodArr1, 'active': getActive(item.playName)}">
                                <div class="flexCenterBL">
                                    <img v-for="(xitem, xindex) in item.methodArr" :key="`${xindex}-liveRoom-sd-third-xitem-suXitem`" v-real-img="require(`../../../assets/img/lottery/sd/${xitem}.png`)" />
                                </div>
                                <div class="flexCenterBL">
                                    <img v-for="(xitem, xindex) in item.methodArr1" :key="`${xindex}-liveRoom-sd-third-xitem-suXitem-sd`" v-real-img="require(`../../../assets/img/lottery/sd/${xitem}.png`)" />
                                </div>
                                <div>{{ item.lines }}</div>
                            </div>
                        </div>
                        <!-- <div class="lotterySdItemItem">
                            <div class="flexCenterBL">
                                <img src="../../../assets/img/lottery/sd/1.png" />
                                <img src="../../../assets/img/lottery/sd/1.png" />
                                <img src="../../../assets/img/lottery/sd/1.png" />
                                <img src="../../../assets/img/lottery/sd/1.png" />
                            </div>
                            <div>1.98</div>
                        </div>
                        <div class="lotterySdItemItem">
                            <div class="flexCenterBL">
                                <img src="../../../assets/img/lottery/sd/1.png" />
                                <img src="../../../assets/img/lottery/sd/1.png" />
                                <img src="../../../assets/img/lottery/sd/1.png" />
                                <img src="../../../assets/img/lottery/sd/1.png" />
                            </div>
                            <div>1.98</div>
                        </div>
                        <div class="lotterySdItemItem lotterySdItemItem-4h4b">
                            <div class="flexCenterBL">
                                <img src="../../../assets/img/lottery/sd/1.png" />
                                <img src="../../../assets/img/lottery/sd/1.png" />
                                <img src="../../../assets/img/lottery/sd/1.png" />
                                <img src="../../../assets/img/lottery/sd/1.png" />
                            </div>
                            <div class="flexCenterBL">
                                <img src="../../../assets/img/lottery/sd/2.png" />
                                <img src="../../../assets/img/lottery/sd/2.png" />
                                <img src="../../../assets/img/lottery/sd/2.png" />
                                <img src="../../../assets/img/lottery/sd/2.png" />
                            </div>
                            <div>1.98</div>
                        </div> -->
                    </div>
                </div>
            </div>
            

            <div class="flexBetween dialogFotter">
                <div class="flexCenter">
                    <div class="myselfAmount flexCenter">
                        <img src="../../../assets/img/gold.png" />
                        {{ member && member.goldCoin ? numFormat(member.goldCoin) : '0.00'}}
                    </div>

                    <div @click="goRechangeComfirm" class="rechageBtn">{{ $t('my.menuList.recharge') }}</div>
                </div>
                <div class="flexCenter">
                    <div @click="showChips=true" class="weight">
                        {{ (weightListConfirm == (weightList.length -1) && customizeValueConfirm) ? customizeValueConfirm : (weightList[weightListConfirm]) }}
                    </div>
                    <div @click="comfirtBet" class="sureBtn">{{$t('lottery.betNow')}}</div>
                </div>
            </div>
        </van-popup>

        <!-- 筹码弹框 -->
        <lotteryChips v-if="showChips" @closeLotteryPopup="closeLotteryPopup"></lotteryChips>
    </div>
</template>
  
<script>
import { getHistorLottery } from "@/api/index";
import { mapState } from 'vuex';
import lotteryRule from "@/pages/liveRoom/lottery/rule.vue";
import lotteryChips from "@/pages/liveRoom/lottery/chips.vue";
import rechangeEntry from '@/pages/mixin/rechangeEntry';
export default {
    name: "liveRoomGameList",
    mixins: [rechangeEntry],
    props: ['gameData', 'sdMiddle', 'second', 'lotteryActvieBet'],
    components: {
        lotteryRule,
        lotteryChips
    },
    data() {
        return {
            showRule: false, //玩法说明弹框是否展示
            supportImg: ['yuxx', 'jsks', 'sd'],
            // second: 0, // 倒计时时间
            timer: null, //倒计时容器 展示
            time: null, //倒计时容器 逻辑开启关闭使用
            betType: 0, //二级标签选中状态
            // showLottery: false, //是否展示彩票弹框
            showChips: false, //筹码弹框是否展示
            // gameData: [], //游戏标题及赔率详情
            lastResult: {}, //单个游戏最后一起结果
            isShowLottery: false, //游戏弹框
            // activeBet: [],
            chipsNum: 0,
            betNum: [1, 2, 5, 10, 20],
        }
    },
    computed: {
        ...mapState(['member', 'weightList', 'issue', 'betPramas', 'weightListConfirm', 'customizeValueConfirm', "liveInfo"]),
        betNumActive() {
            return this.$store.state.multiple;
        }
    },
    watch: {
        issue() {
            let that = this;
            this.time = setTimeout(()=> {
                that.getLotteryResultHistory();
            }, 3000);
        },
    },
    created() {
        let that = this;
        setTimeout(() => {
            that.isShowLottery = true;
        }, 10);
        this.getLotteryResultHistory();
    },
    methods: {
        betNumClick(item) {
            this.$store.commit("setState", { multiple: item });
        },
        // 前往投注信息界面 下注前
        comfirtBet() {
            if (this.lotteryActvieBet.length == 0) return;
            this.$emit('openPopup', 'showLotteryBetSure');
        },
        // 点击
        lotteryItem(item) {
            this.$emit('lotteryItem', item);
            // let index = this.activeBet.findIndex(xitem => {
            //     return xitem.playName == item.playName;
            // });
            // if (index != -1) {
            //     this.activeBet.splice(index, 1);
            // } else {
            //     this.activeBet.push(item);
            // }
        },
        // 是否选中
        getActive(val) {
            let index = this.lotteryActvieBet.findIndex(item => {
                return item.playName == val;
            });
            if (index != -1) {
                return true;
            }
            return false;
        },
        // 前往规则弹框
        goRule() {
            this.showRule = true;
        },
        // 关闭弹框 
        closeLotteryPopup(val) {
            this[val] = false;
        },
        // 单个游戏开奖记录
        goLotteryResult() {
            this.$emit('gosingleLotteryResult', this.betPramas)
        },
        close() {
            this.isShowLottery = false;
            let that = this;
            clearInterval(this.timer);
            setTimeout(() => {
                that.$emit('colsePopup', 'showSingleLottery')
            }, 500)
        },
        // 获取一种彩种的最后一期的开奖结果
        getLotteryResultHistory() {
            let params = {
                name: this.betPramas.name,
                uid: this.member.uid
            }

            this.finished = true;
            this.lastResult = {};
            getHistorLottery(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (res.data.length > 0) {
                        let obj = JSON.parse(JSON.stringify(res.data[0]));
                        obj.lotteryResult = obj.code.split(',');
                        this.lastResult = JSON.parse(JSON.stringify(obj));
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>
  
<style lang="scss" scoped>
.lotteryHead {
    padding: 0.2rem 0.28rem;
    font-size: 0.28rem;

    .lotteryIssue {
        padding: 0.1rem 0;

        .gameIcon {
            width: 0.56rem;
            height: 0.56rem;
            border-radius: 50%;
            margin-right: 0.2rem;
        }

        .icon {
            margin-left: 0.2rem;
            width: 0.28rem;
        }
    }

    .lotteryRecord {
        .lotteryIsBet {
            font-size: 0.24rem;
            .red {
                color: #C41F1A ;
            }

            .lotteryIsBetTime {
                margin-left: 0.1rem;

                span {
                    display: inline-block;
                    color: #ffc603;
                    font-size: 0.2rem;
                    border-radius: 0.05rem;
                    padding: 0.05rem;
                }
            }
        }

        .lotteryRecordNow {
            img {
                width: 0.36rem;
                height: 0.36rem;
                margin-right: 0.2rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            span {
                display: inline-block;
                width: 0.3rem;
                height: 0.35rem;
                background-color: #eb457e;
                line-height: 0.37rem;
                color: #fff;
                margin-right: 0.05rem;
                font-size: 0.24rem;
            }
        }
    } 
}

.lotteryCont {
    height: 3.4rem;
    overflow: hidden;
    text-align: left;

    .lotteryContItem {
        &.thirdItem {
            width: calc(100% / 3);
        }

        &.fourthItem {
            width: calc(100% / 4);
        }

        &.secondItem {
            width: calc(100% / 2);
        }

        &.fifthItem {
            width: calc(100% / 5);
        }

        display: inline-block;
        text-align: center;

        img {
            width: 0.7rem;
            height: 0.7rem;
            margin-bottom: 0.12rem;
            padding: 0.05rem;
            background-color: rgba(255,255,255, 0.3);

            &.oneBorder {
                border-radius: 0.1rem;
            }

            &.allBorder {
                &:first-child {
                    border-top-left-radius: 0.1rem;
                    border-bottom-left-radius: 0.1rem;
                }
                &:last-child {
                    border-top-right-radius: 0.1rem;
                    border-bottom-right-radius: 0.1rem;
                }
            }
        }

        span {
            padding: 0.1rem 0.16rem;
            background-color: #fff;
            color: #243050;
            margin-bottom: 0.1rem;
            display: inline-block;
            border-radius: 0.1rem;
        }

        &.twoLines {
            margin-top: 0.44rem;
        }

        &.oneLines {
            margin-top: 1rem;
        }

        &.threeLines {
            margin-top: 0.2rem;

            img {
                margin-bottom: 0rem;
                width: 0.44rem;
                height: 0.44rem;
            }
        }

        &.active {
            span{
                background-color: #eb457e;
                color: #fff;
            }

            img {
                background-color: #eb457e;
            }
        }
    }

    &.lotteryContSd {
        padding: 0.32rem 0.12rem;
        height: 3.82rem;
        vertical-align: top;

        .lotterySdItem {
            width: 2.2rem;
            display: inline-block;
            vertical-align: top;

            .lotterySdItemItem {
                width: 100%;
                height: 1rem;
                margin-bottom: 0.08rem;
                padding-top: 0.18rem;
                text-align: center;

                &.active {
                    background-image: url('../../../assets/img/live/lottery/longBlue.png');
                }

                background-image: url('../../../assets/img/live/lottery/longGray.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;

                img {
                    width: 0.32rem;
                    height: 0.32rem;
                    margin-right: 0.2rem;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                div:last-child {
                    margin-top: 0.08rem;
                }

                &.lotterySdItemItem-4h4b {
                    padding-top: 0.08rem;

                    img {
                        width: 0.24rem;
                        height: 0.24rem;
                    }

                    div:last-child {
                        margin-top: 0.02rem;
                    }
                }
            }
        }

        .lotterySdItemMiddle {
            width: 2.6rem;
            padding: 0 0.12rem;
            display: inline-block;
            vertical-align: top;
            text-align: center;

            .lotterySdItemMiddleItem {
                width: 1.12rem;
                height: 1.52rem;
                display: inline-block;
                margin-bottom: 0.12rem;
                padding-top: 0.36rem;

                &.active {
                    background-image: url('../../../assets/img/live/lottery/highBlue.png');
                }

                background-image: url('../../../assets/img/live/lottery/highGray.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;

                &:nth-child(odd) {
                    margin-right: 0.12rem;
                }

                div {
                    &:nth-child(1) {
                        font-size: 0.28rem;
                        margin-bottom: 0.1rem;
                    }

                    &:nth-child(2) {
                        font-size: 0.2rem;
                    }
                }
            }
        }
    }
}

.dialogFotter {
    padding: 0 0.28rem;
    margin-bottom: 0.2rem;

    .myselfAmount {
        margin-right: 0.2rem;
        color: #ffd96b;
        font-size: 0.4rem;

        img {
            width: 0.28rem;
            height: 0.28rem;
            margin-right: 0.1rem;
        }
    }

    .rechageBtn {
        font-size: 0.24rem;
        color: #fff;
        border: 1px solid #ffd96b;
        padding: 0.1rem 0.3rem 0.12rem;
        border-radius: 0.4rem;
    }

    .sureBtn {
        background: -webkit-linear-gradient(left,#eb538e,#e14077);
        background: linear-gradient(90deg, #eb538e,#e14077);
        padding: 0.1rem 0.4rem;
        border-radius: 0.5rem;
    }

    .weight {
        width: 0.6rem;
        height: 0.6rem;
        line-height: 0.6rem;
        margin: 0 0.1rem;
        font-size: 0.2rem;
        background-image: url('../../../assets/img/lottery/weight/10.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.betNumList {
    margin: 0.2rem 0;
    padding: 0 0.28rem;

    div {
        width: calc(20% - 0.16rem);
        text-align: center;
        margin-right: 0.2rem;
        color: #788597;
        padding: 0.12rem 0;
        border: 1px solid #e5538b;
        border-radius: 0.1rem;

        &:last-child {
            margin-right: 0;
        }

        &.active {
            background-color: #e5538b;
            color: #fff;
        }
    }
}

.total {
    color: #fff;
    padding: 0 0.28rem;
    text-align: left;
    margin-bottom: 0.2rem;

    &>div:last-child {
        text-align: right;
    }


    .betBtn {
        background: linear-gradient(90deg, #e14077, #eb538e);
        background: -webkit-linear-gradient(left, #e14077, #eb538e);
        color: #fff;
        text-align: center;
        border-radius: 0.5rem;
        padding: 0rem 0.28rem;
        line-height: 0.6rem;
        height: 0.6rem;
        min-width: 1.6rem;
    }

    .totalItem {
        &>div:first-child {
            margin-bottom: 0.1rem;
        }

        .num {
            color: #eb457e;
            margin-left: 0.1rem;
        }
    }
}
</style>
<style lang="scss">
.liveRoomWeightModel {
    background-color: rgba(0,0,0,0.8) !important;
    color: #fff;
    .van-action-sheet__header {
        font-size: 0.32rem;
        font-weight: bold;
        color: #fff;
    }

    .van-action-sheet__close {
        font-size: 0.34rem;
    }

    .ruleFont {
        min-height: 5.4rem;
        text-align: left;
        padding: 0.28rem;
    }

    .weigthModelHead {
        line-height: 1.08rem;
        border-bottom: 1px solid #CECED0;
        padding: 0 0.28rem;
        color: #CECED0;

        div {
            border-bottom: 2px solid transparent;
            margin-bottom: -1px;

            &.active {
                color: #333;
                border-bottom: 2px solid #4581FD;
            }
        }
    }

    .lotteryHistoryHead {
        font-size: 0.24rem;
        color: #626872;
        line-height: 0.2rem;
        text-align: left;
        padding: 0.24rem 0.28rem 0.25rem 0.68rem;
        border-bottom: 1px solid #CECED0;

        div:nth-child(1) {
            width: 2.68rem;
        }
    }

    .lotteryHistory {
        height: 6.95rem;
        font-size: 0.24rem;
        color: #626872;
        line-height: 0.2rem;
        text-align: left;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        div {
            div:nth-child(1) {
                width: 2.68rem;
            } 
        }

        .lotteryItem {
            padding: 0.1rem 0.28rem 0.12rem 0.68rem;
            img {
                width: 0.32rem;
                margin-right: 0.4rem;
            }

            &:nth-child(even) {
                background-color: #F9F9F9;
            }
        }
    }

    .betList {
        color: #243050;
        min-height: 3.48rem;
        max-height: 6.02rem;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        .betHead {
            padding: 0.24rem 0.28rem;
            line-height: 0.34rem;
            color: #ffd96b;

            span {
                font-size: 0.2rem;
            }
        }

        .betItem {
            padding: 0.16rem 0.28rem;
            background-color: #000;
            border-radius: 0.1rem;
            color: #fff;
            margin: 0 0.24rem 0.16rem;

            div:nth-child(1) {
                font-size: 0.4rem;
                text-align: left;

                p:nth-child(1) {
                    margin-bottom: 0.1rem;
                    font-size: 0.32rem;
                }

                p:nth-child(2) {
                    font-size: 0.24rem;

                    span {
                        display: inline-block;
                        margin-left: 0.2rem;
                    }
                }
            }

            div:nth-child(2) {
                text-align: right;
                font-size: 0.28rem;

                i {
                    font-size: 0.36rem;
                    color: #fff;
                }

                div {
                    margin-top: 0.05rem;
                }
            }

            .red {
                color: #eb457e;
            }
        }
    }

}
</style>