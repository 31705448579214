<template>
  <div class="customerService next">
    <div class="title">
        <span> {{ $t('home.customerService') }} </span>
        <div class="fixed" @click="goBack">
        <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
    </div>

    <img class="customer" src="../../assets/img/customerService/customer.png" />


    <div class="customerList">
      <div v-for="(item, index) in onlineArr" :key="`${index}--onlineArr-customerService`" @click="goOnlineService(item)">
        <img v-real-img="item.icon"  />
        <p>{{ item.nickname }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { cserver } from "@/api/config";
export default {
  name: "customerService",
  data() {
    return {
      onlineArr: []
    }
  },
  computed: {
    supportLanguage() {
      return this.$store.state.supportLanguage
    },
  },
  created() {
    this.getCserver();
  },
  methods: {
    // 返回
    goBack() {
      this.$router.go(-1);
    },
    // 去往客服会话
    goOnlineService(item) {
      if (item && item.url) {
        // this.$store.commit("setState", { cserver: item });
        // this.$router.push("/customerService/online");
        window.location.href = item.url;
      } else {
        this.$toast(this.$t("common.noData"));
      }
      
    },
    // 获取客服列表
    getCserver() {
      cserver().then(res => {
        if (res.code == 0) {
          this.onlineArr = res.data
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(err => {
        console.log(err);
      });
    },
   }
}
</script>

<style lang="scss" scoped>
 .customerService {
  background-color: #fff;

  .customer {
    width: 2.54rem;
    margin: 0.2rem 0 0.4rem;
  }

  .customerList {
    padding: 0 0.44rem;

    div {
      width: calc(33.3% - 0.14rem);
      background-color: #f9f9f9;
      margin-right: 0.2rem;
      padding: 0.2rem;
      border-radius: 0.2rem;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 0.2rem;

      &:nth-child(3n) {
        margin-right: 0;
      }

      img {
        width: 0.98rem;
      }
    }
  }
 }
</style>