<template>
  <div>
    <van-popup v-model:show="isShowDownLoad" position="bottom" @close="close">
        <div class="downloadBoxBg" :style="{ height: height+'px'}">
            <img class="downloadBoxBgImg" :style="{ height: height+'px'}" @click="close" v-real-img="liveInfo.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)">
            <div class="downloadBox">
                <img src="../../assets/img/home/downLoadApp.png">
                <div>{{ $t('home.downLoadContinue') }}</div>
                <div class="flexBetween">
                    <div @click="goHome" class="confirmBtn hasValue">{{ $t('liveRoom.goHome') }}</div>
                    <div @click="downLoad" class="confirmBtn hasValue">{{ $t('home.downLoadApp') }}</div>
                </div>
            </div>
        </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "downloadApp",
    data() {
        return {
            isInitSuccess: false,
            height: '367'
        }
    },
    computed: {
        ...mapState(['isShowDownLoad', 'baseInfo', 'leftMenu', 'fromPath', 'liveInfo']),
    },
    // watch: {
    //     isShowDownLoad(val) {
    //         if (val) {
    //             let that = this, param = "492";
    //             if (sessionStorage.getItem('puid')) {
    //                 param = sessionStorage.getItem('puid');
    //             }
    //             ShareTrace.init({
    //                 appkey: "784b6957e877d45a", // 此值必填
    //                 param: param,
    //                 success: function() {
    //                     that.isInitSuccess = true;
    //                 }
    //             });
    //         }
    //     }
    // },
    mounted() {
        this.height = document.documentElement.clientHeight || window.innerHeight || document.body.clientHeight
    },
    methods: {
        close() {
            this.$store.commit("setState", { isShowDownLoad: false });
            this.$store.commit("setState", { liveInfo: {} });
            this.$store.dispatch('getGroupList', String(this.leftMenu[0].roomId));

            if (this.$route.path == '/liveRoom') {
                if (this.fromPath && this.fromPath != '/entryLiveBefore' && this.fromPath != '/liveRoom' && this.fromPath != '/offRoom') {
                    this.$router.push(this.fromPath);
                } else {
                    this.$router.push('/home');
                }
            }
        },
        downLoad() {
            // if (this.baseInfo && this.baseInfo.floorUrlWeb) {
            //     let url = this.baseInfo.floorUrlWeb
            //     if (sessionStorage.getItem('puid')) {
            //     url = url + '?puid=' + sessionStorage.getItem('puid')
            //     }
            //     window.location.href = url;
            // }
            if(ShareTrace.download){
                ShareTrace.download();
            }else{
                window.location.replace(window.location.href);
            }
        },
        goHome() {
            this.$router.push('/home');
            this.$store.commit("setState", { isShowDownLoad: false });
        }
    }
}
</script>

<style scoped lang="scss">
.downloadBoxBg {
    // height: 100vh;
    width: 100vw;
    position: relative;

    .downloadBoxBgImg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        // height: 100vh;
        opacity: 0.3;
    }
}

.downloadBox {
    font-size: 0.34rem;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
    width: 100vw;

    img {
        width: 2.68rem;
        padding: 0.2rem 0;
    }

    .confirmBtn {
        margin: 0.56rem 0.2rem 0.24rem;
    }
}
</style>