<template>
  <div class="next gameRepotNext">
    <div class="title">
      <span> {{ $t("lottery.betRecord") }} </span>
      <div class="fixed" @click="gaBack">
        <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="12456" width="0.3rem" height="0.3rem">
          <path
            d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
            fill="#666666" p-id="12457"></path>
        </svg>
      </div>

      <div class="searchItem">
        <div class="searchItem_item">
          <span v-for="(item, index) in status" :key="`${index}--searchItem_item`"
            :class="searchctive == index ? 'searchItem_item_active' : ''" @click="changeStatus(index)">
            {{ item.name }}
          </span>
        </div>
      </div>
    </div>

    <div class="recordList">
      <div v-if="betList.length > 0">
        <van-pull-refresh v-model="isLoading" :disabled="isRefreshDisable" @refresh="onRefresh">
          <template #pulling>
            <van-loading />
          </template>

          <!-- 释放提示 -->
          <template #loosing>
            <van-loading />
          </template>

          <!-- 加载提示 -->
          <template #loading>
            <van-loading />
          </template>
          <van-list class="vanList" v-model="loading" :finished="finished"
            style="height:calc(100vh - 2.2rem);overflow:scroll;" :offset="10" :immediate-check="check"
            :finish-text="`${$t('lottery.noMore')}`" @load="onLoad">
              <div v-for="(item, index) in betList" :key="`${index}--betList-gameReportNext`" @click="goDetail(item)" class="recordItem flexBetween">
                <div class="recordItemItem1">
                    <p>{{item.nickName}}</p>
                    <p><span class="gray">{{ $t('lottery.No') }}</span> <span class="red">{{item.expect}}</span>{{ $t('lottery.expect') }}</p>
                </div>
                <div class="recordItemItem2">
                    <p><span class="gray">{{ $t('lottery.detail.betAmount') }}</span></p>
                    <p><span class="red">{{item.betAmount}}</span></p>
                </div>

                <div class="flexCenterBL">
                    <div v-show="item.awardStatus == 0">
                        {{ $t('lottery.status.wait') }}
                    </div>
                    <img v-show="item.awardStatus == 1" src="../../assets/img/record/lose.png" />
                    <img v-show="item.awardStatus == 2" src="../../assets/img/record/win.png" />
                    <img v-show="item.awardStatus == 3" src="../../assets/img/record/tie.png" />
                </div>

                <img class="goIocn" src="../../assets/img/my/right.png">
              </div>
          </van-list>
        </van-pull-refresh>

      </div>
      <div v-else class="noData">
        <img src="../../assets/img/record/noData.png" />
        <p>{{ $t('common.noData') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getLotteryDetailsHistory } from "@/api/index";
import moment from "moment";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      searchctive: 0,
      status: [ // 状态条件列表  查询类型：0 ：全部 1:未开奖 2:未中奖 3：已中奖
        {
          name: this.$t('lottery.status.all'),
          type: 0
        },
        {
          name: this.$t('lottery.status.wait'),
          type: 1
        },
        {
          name: this.$t('lottery.status.lose'),
          type: 2
        },
        {
          name: this.$t('lottery.status.win'),
          type: 3
        },
        {
          name: this.$t('lottery.status.tie'),
          type: 4
        }
      ],
      isLoading: false,
      betList: [], //列表
      minDate: new Date(),
      maxDate: new Date(),
      currentStartDate: new Date(),
      currentEndDate: new Date(),
      showStartDate: false,
      showEndDate: false,
      finished: true, //是否已加载完
      page: 0, //页码
      loading: false, //下拉加载更多加载中动画
      check: false, //是否开始就检查
      // 滚动与下拉冲突
      scrollTop: 0,
      isRefreshDisable: false
    };
  },
  watch: {
    scrollTop(newval) {
      if (newval > 0) {
        this.isRefreshDisable = true
      } else {
        this.isRefreshDisable = false
      }
    }
  },
  computed: {
    ...mapState(['member', 'betPramas', 'baseInfo']),
  },
  created() {
    this.getLotteryDetailsHistory();

  },
  mounted() {
    // 解决滚动条与下拉的冲突
    // this.$nextTick(() => {
    //   const vantList = document.querySelector('.van-list')
    //   this.resolveConflict(vantList)
    // })
  },
  methods: {
    /**
     * @description: 下拉刷新和滚动事件冲突
     * @param {*}
     * @return {*}
     */
    resolveConflict(val) {
      const vantList = val
      vantList.addEventListener('touchmove', () => {
        this.scrollTop = vantList.scrollTop
      })
      vantList.addEventListener('touchend', () => {
        this.scrollTop = vantList.scrollTop
      })
    },
    formatDate(val) {
      if (val) {
        return moment(val).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return "";
      }
    },
    changeStatus(index) {
      this.searchctive = index;
      this.page = 0;
      this.getLotteryDetailsHistory();
    },
    // 去往详情界面 
    goDetail(item) {
      let obj = JSON.parse(JSON.stringify(item));
      obj.playNumReq.type_text = obj.playNumReq.num.split(',');
      this.$store.commit("setState", { betDetail: obj });
      // 下一层跳转的地址
      this.$router.push('/record/bet/detail');
    },
    // 加载更多
    onLoad() {
      if (!this.finished) {
        this.loading = true;
        this.page++;
        this.getLotteryDetailsHistory();
      }
    },
    // 获取列表
    getLotteryDetailsHistory(refresh = false) {
      let params = {
        uid: this.member.uid,
        type: this.betPramas.searchDayActive,
        queryType: this.searchctive,
        lotteryName: this.betPramas.lotteryName,
        page: this.page
      }

      this.finished = true;
      this.loading = true;
      this.isLoading = true;

      getLotteryDetailsHistory(params).then(res => {
        if (res.code == 0) {
          if (!params.page) {
            this.betList = res.data;
          } else {
            if (res.data.length > 0) {
              let arr = JSON.parse(JSON.stringify(this.betList));
              arr = [...res.data, ...arr];
              this.betList = arr;
            }
          }

          if (res.data.length == 10) {
            this.finished = false;
          }

          if (refresh) {
            this.$toast(this.$t('common.refreshSuccess'));
          }

          // 解决滚动条与下拉的冲突
          this.$nextTick(() => {
            const vantList = document.querySelector('.van-list')
            if (vantList) {
              this.resolveConflict(vantList)
            }
          })
        } else {
          this.betList = {};
          if (res.msg) this.$toast(res.msg);
        }

        this.loading = false;
        this.isLoading = false;
      }).catch(() => {
        this.betList = {};
        this.loading = false;
        this.isLoading = false;
        console.log("catch");
      })
    },
    gaBack() {
      this.$router.push('/record/gameReport');
    },
    getTime(val) {
        if (val) {
            return moment(val).format('HH:mm:ss DD-MM-YYYY');
        }
        return "";
    },
    onRefresh() {
      setTimeout(() => {
        this.page = 0;
        this.getLotteryDetailsHistory(true);
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
.gameRepotNext {
  text-align: left;
  background-color: #f8f8f8;
  padding-top: 1.88rem;

  .title {
    height: auto;
  }

  .searchItem {
    .searchItem_item {
      white-space: nowrap;
      overflow-x: scroll;
      background-color: #f4f4f4;
      padding: 0 0.28rem;

      /* 隐藏滚动条 */
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */

      &::-webkit-scrollbar {
        width: 0;      /* Safari,Chrome 隐藏滚动条 */
        height: 0;     /* Safari,Chrome 隐藏滚动条 */
        display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
      }

      span {
        display: inline-block;
        height: 0.56rem;
        min-width: 1.58rem;
        line-height: .56rem;
        border-radius: 0.4rem;
        text-align: center;
        margin-right: 0.2rem;
        color: #666;
        font-size: .28rem;
        box-sizing: border-box;
        -webkit-flex-grow: 0;
        flex-grow: 0;
        -webkit-flex-shrink: 0;
        flex-shrink: 0;
        -webkit-flex-basis: calc(25% - 0.2rem);
        flex-basis: calc(25% - 0.2rem);
        padding: 0rem 0.1rem;
        position: relative;

        &.searchItem_item_active {
          background: -webkit-linear-gradient(left, #f86fab, #fa8faf);
          background: linear-gradient(90deg, #f86fab, #fa8faf);
          color: #fff;
          border: none;

          &::after {
            border: 1px solid #fff;
          }
        }
      }
    }
  }

  .recordList {

    .recordItem {
      border-bottom: 1px solid #ebeff1;
      padding: 0.25rem 0 0.14rem 0.28rem;
      font-size: 0.28rem;
      background-color: #fff;
      position: relative;
      
      &>div {
          border-right: 1px solid #c5c5c5;
          flex: 1;

          &:last-child {
            border-right: none;
          }

          p:first-child {
            margin-bottom: 0.16rem;
          }

          P {
            .gray {
              color: #bbb;
            }

            .red {
              color: #fa0000;
            }
          }
      }

      .recordItemItem1 {
          p {
              &:last-child {
                  font-size: 0.2rem;
              }
          }
      }

      .recordItemItem2 {
          text-align: center;

          p:last-child {
              color: #000;
              font-weight: bold;
          }
      }

      img {
          width: 1rem;
      }

      .goIocn {
        position: absolute;
        top: 0.5rem;
        right: 0.2rem;
        width: 0.28rem;
        height: 0.28rem;
      }
    }
  }
}</style>
<style lang="scss"></style>
