import { configCaptcha } from "@/api/index";
const captcha = {
    data() {
        return {
            captchaObj: {},
            captchData: {},
            loading: false,
            captchaIns: null,
        }
    },
    methods: {
        initNeCaptchas(fun) {
            var that = this;
            initNECaptcha({
                captchaId: this.captchaObj.verificationNo,
                element: '#captcha',
                mode: 'popup',
                lang: "th",
                // appendTo: '#appendWrap',
                enableClose: false,
                onClose: function () {
                    console.log('onClose');
                    // 弹出关闭结束后将会触发该函数
                },
                onVerify: function (err, data) {
                    if (!err) {
                        that.captchData = data;
                        // 验证成功后，调用 close 方法关闭弹框
                        // TODO: 验证成功后继续进行业务逻辑
                        console.log(fun);
                        that[fun]();
                    }
                }
            }, function onload (instance) {
                // 初始化成功
                that.captchaIns = instance;
                if(that.loading){
                    that.captchaIns && captchaIns.popUp();
                }
            }, function onerror (err) {
                // 验证码初始化失败处理逻辑，例如：提示用户点击按钮重新初始化
            })

        },
        // 获取行为验证
        userCaptcha(fun) {
            configCaptcha().then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.captchaObj = res.data;
                    this.initNeCaptchas(fun);
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {})
        },
        // 网易弹框 fun 验证码执行成功之后执行的方法名称
        confirmGetCode(fun) {
            this.captchaIns && this.captchaIns.refresh()
            this.captchaIns && this.captchaIns.popUp();
            // 初始化失败
            if(!this.captchaIns){
                this.initNeCaptchas(fun)
            }
        },
    }
}
export default captcha;