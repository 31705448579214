<template>
    <div>
        <!-- 开奖结果弹框 -->
        <van-action-sheet class="weightModel" @close="colsePopup" v-model:show="isShowResult" :closeable="false" :title="$t('liveRoom.lotteryRecord')">
            <div class="resultDailog">
                <div v-for="(item, index) in list" :key="`${index}--liveRoom-lotteryResult`" @click="goDetail(item)"  class="resultItem flexBetween">
                    <div>
                        <div class="lotteryName">{{ item.nickName }} <p>{{ item.expect }}</p></div>

                        <div class="resultItemRight" v-if="item.lotteryName == 'sd' || item.lotteryName == 'yuxx' || item.lotteryName == 'jsks'">
                            <img v-for="(xitem, xindex) in item.lotteryResult" :key="`${xindex}-${index}-liveRoom-lotteryResult-item`" v-real-img="require(`../../assets/img/lottery/${item.lotteryName}/${xitem}.png`)" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                        </div>

                        <div class="resultItemRight" v-else-if="item.lotteryName == 'pk10' || item.lotteryName == 'txssc'">
                            <span v-for="(xitem, xindex) in item.lotteryResult" :key="`${xindex}-${index}-liveRoom-lotteryResult-item`">{{xitem}}</span>
                        </div>

                        <div class="resultItemRight" v-else-if="item.lotteryName == 'yflhc' && item.lotteryResult.length == 8">
                            <span class="gameItem" v-for="(xitem, xindex) in item.lotteryResult" :key="`${xindex}--item.lotteryResult-yflhc`" v-show="xindex < 6"> {{xitem}}</span>
                            <van-icon name="plus" />
                            <span class="gameItem" v-show="item.lotteryResult[7] == 1" style="background-color:#f90007;"> {{item.lotteryResult[6]}}</span> 
                            <span class="gameItem" v-show="item.lotteryResult[7] == 2" style="background-color:#04f906;"> {{item.lotteryResult[6]}}</span>
                            <span class="gameItem" v-show="item.lotteryResult[7] == 3" style="background-color:#2a00f2;"> {{item.lotteryResult[6]}}</span>
                        </div>
                    </div>

                    <div>
                        <van-icon name="arrow" />
                    </div>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
import moment from "moment";
import { getAllLotteryLatestResultNew } from "@/api/live";
export default {
    name: "liveRoomLotteryResult",
    data() {
        return {
            isShowResult: false, // 开奖结果弹框是否展示
            list: [],
            timer: null
        }
    },
    computed: {
        ...mapState(['member', 'lotteryRouter', 'issue']),
    },
    watch: {
        issue() {
            let that = this;
            setTimeout(() => {
                that.getAllLotteryLatestResultNew();
            }, 5000) 
        }
    },
    created() {
        let that = this;
        this.timer = setTimeout(() => {
            that.isShowResult = true;
        }, 10)

        this.getAllLotteryLatestResultNew();
    },
    methods: {
        getTime(val) {
            if (val) {
                return moment(val).format('HH:mm DD-MM-YYYY');
            }
            return "";
        },
        colsePopup() {
            this.isShowResult = false;
            let that = this;
            clearTimeout(this.timer);
            setTimeout(() => {
                that.$emit('colsePopup', 'showLotteryResult')
            }, 1000)
        },
        // 列表
        getAllLotteryLatestResultNew() {
            getAllLotteryLatestResultNew().then(res => {
                if (res.code === 0 || res.code === '0') {
                    let arr = JSON.parse(JSON.stringify(res.data)), that = this, sortArr = ['sd', 'yuxx', 'jsks', 'txssc', 'pk10', 'yflhc'], newArr = [];
                    sortArr.forEach(sItem => {
                        arr.forEach(item => {
                            if (sItem == item.lotteryName) {
                                newArr.push(item);
                            }
                        });
                    });
                    this.list = JSON.parse(JSON.stringify(newArr));
                } else {
                    if (res.msg) this.$toast(res.msg); 
                }

            }).catch(() => {})
        },
        // 单条数据点击
        goDetail(item) {
            this.$store.commit("setState", { suBetPramas: item });
            this.$emit('openPopup', 'showRecordLottery');
        }
    }
}
</script>
  
<style lang="scss" scoped>
.resultDailog {
    min-height: 50vh;

    .resultItem {
        border-bottom: 1px solid #e5e5e5;
        padding: 0.2rem 0.28rem;
        text-align: left;
        color: #333;
        font-size: 0.28rem;

        .lotteryName {
            margin-bottom: 0.05rem;
            font-size: 0.3rem;

            p {
                font-size: 0.28rem;
                display: inline-block;
                margin-left: 0.1rem;
            }
        }

        i {
            font-size: 0.36rem;
            color:#6666;
        }

        img {
            width: 0.44rem;
            margin-right: 0.1rem;
        }

        span {
            display: inline-block;
            width: 0.3rem;
            height: 0.35rem;
            background-color: #eb457e;
            line-height: 0.37rem;
            color: #fff;
            margin-right: 0.05rem;
            font-size: 0.24rem;
            text-align: center;
        }
    }
}
</style>