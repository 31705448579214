<template>
  <div class="load">
    <van-overlay :show="isLoad" z-index="9999">
        <div class="wrapper">
            <van-loading size="80" color="#ff54a8" />
        </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "loadCom",
    data() {
        return {
            isLoad: false
        }
    },
    computed: {
        ...mapState(['isLoadForLoad']),
    },
    watch: {
        isLoadForLoad(val) {
            this.isLoad = val;
        }
    },
    created() {
        this.isLoad = this.isLoadForLoad;
    }
}
</script>

<style lang="scss">
.load .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>