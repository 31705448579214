<template>
  <div class="discount">
    <div class="top">
      <div class="header">{{ $t("discount.header") }}</div>
    </div>

    <div class="content">
      <div class="recordList">
        <div v-if="list.length>0">
          <van-pull-refresh v-model="isLoading" :disabled="isRefreshDisable" @refresh="onRefresh">
            <template #pulling>
              <van-loading />
            </template>

            <!-- 释放提示 -->
            <template #loosing>
              <van-loading />
            </template>

            <!-- 加载提示 -->
            <template #loading>
              <van-loading />
            </template>
            <van-list
                class="vanList"
                v-model="loading"
                :finished="finished"
                style="height:calc(100vh - 2.5rem);overflow:scroll;"
                :offset="10" 
                :immediate-check="check" 
                :finish-text="`${$t('lottery.noMore')}`"
              >
              <div v-for="(item, index) in list" :key="`${item}--bar`" class="item" @click="itemClick(item, index)">
                <!-- distributeType;  1 自动派发  2 客服申请 -->
                <div v-show="item.distributeType == 1" class="topiconbox">{{$t('discount.automatic')}}</div>
                <div v-show="item.distributeType == 2" class="topiconbox">{{$t('discount.Application')}}</div>
                <!-- <img class="topiocn" src="../../assets/img/discount/icon.png" /> -->
                <van-image class="imgs" :src="item.activityHomePage">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>

              <div class="showAll" v-show="finished"> {{$t('lottery.showAll')}} </div>
            </van-list>
          </van-pull-refresh>
        </div>
        <div v-else class="noData">
          <img src="../../assets/img/record/noData.png"/>
          <p>{{$t('common.noData')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseUserActivity  } from "@/api/config";
import { mapState } from 'vuex';
export default {
  name: "discount",
  data() {
    return {
      list: [],
      // 滚动与下拉冲突
      scrollTop: 0,
      isRefreshDisable: false,
      finished: true, //是否已加载完
      loading: false, //下拉加载更多加载中动画
      check: false, //是否开始就检查
      isLoading: false,
    }
  },
  computed: {
    ...mapState(['member', 'discountType', 'scrollHeight']),
  },
  watch: {
    $route(to, from) {
      if (to.path === '/discount') {
        this.$store.commit("setState", { entry: '/discount' });
      }
    },
    scrollTop(newval) {
      if (newval > 0) {
        this.isRefreshDisable = true
      } else {
        this.isRefreshDisable = false
      }
    }
  },
  created() {
    this.$store.commit("setState", {entry: this.$route.path});
  },
  mounted() {
    this.safariHacks();
    this.baseUserActivity();
  },
  methods: {
    /**
     * @description: 下拉刷新和滚动事件冲突
     * @param {*}
     * @return {*}
     */
     resolveConflict(val) {
      const vantList = val
      vantList.addEventListener('touchmove', () => {
        this.scrollTop = vantList.scrollTop
      })
      vantList.addEventListener('touchend', () => {
        this.scrollTop = vantList.scrollTop
      })
    },
    //图点击跳转
    itemClick(item, index) {
      if (item.activityDetail) {
        let obj = JSON.parse(JSON.stringify(item));
        obj.jumpUrl = item.activityDetail;
        this.$store.commit("setState", { bannerDetail: obj });
        this.$router.push(`/home/bannerDetail/discount${index}`);
      }
    },
    safariHacks() {
      let windowsVH = window.innerHeight;
      document.querySelector('.discount').style.height = `calc(${windowsVH}px - 0.88rem)`;
    },
    // 列表
    baseUserActivity() {
      this.isLoading = true;
      this.list = [];
      baseUserActivity().then(res => {
        if (res.code == 0) {
          // distributeType;  1 自动派发  2 客服申请
          this.list = res.data;
          // 解决滚动条与下拉的冲突
          this.$nextTick(() => {
            const vantList = document.querySelector('.van-list')
            if (vantList) {
              this.resolveConflict(vantList)
            }
          })
        } else {
          if (res.msg) this.$toast(res.msg);
        }
        this.isLoading = false;
      }).catch(err => {
        console.log(err);
        this.isLoading = false;
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.baseUserActivity();
      }, 1000);
    }
  },
  beforeRouteEnter(to, from, next) {
    next((e) => {
      if (e.scrollHeight && e.scrollHeight.discount && document.getElementsByClassName('recordList')[0]) {
        document.getElementsByClassName('recordList')[0].scrollTop = e.scrollHeight.discount;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    let scrollHeight = JSON.parse(JSON.stringify(this.scrollHeight));
    scrollHeight.discount = document.getElementsByClassName('recordList')[0].scrollTop;
    this.$store.commit("setState", { scrollHeight });
    next();
  },
}
</script>

<style lang="scss" scoped>
.discount {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 0.88rem);
  
  .top {
    position: fixed;
    top: 0px;
    z-index: 999;
    background-color: #fff;
    width: 100%;
  }
  .header {
    font-size: .32rem;
    text-align: center;
    height: 0.88rem;
    line-height: .88rem;
    background: -webkit-linear-gradient(left,#f86fab,#fa8faf);
    background: linear-gradient(90deg,#f86fab,#fa8faf);
    color: #fff;
  }


  .content {
    padding: 1.2rem 0.28rem 0.16rem 0.28rem;
    /* 隐藏滚动条 */
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    box-sizing: border-box;
    -moz-box-sizing: border-box;  
    -webkit-box-sizing: border-box; 
    -o-box-sizing: border-box; 
    -ms-box-sizing: border-box;

    /* 隐藏滚动条 */
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
      width: 0;      /* Safari,Chrome 隐藏滚动条 */
      height: 0;     /* Safari,Chrome 隐藏滚动条 */
      display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
    }

    .item {
      width: 100%;
      height: 2.92rem;
      position: relative;
      margin-bottom: 0.2rem;
      border-radius: 0.4rem;
      overflow: hidden;

      .imgs {
        width: 100%;
        height: 2.92rem;
        border-radius: 0.1rem;
      }

      .topiconbox {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        min-width: 2.16rem;
        height: 0.4rem;
        line-height: 0.3rem;
        font-size: 0.2rem;
        color: #fff;
        background: url('../../assets/img/discount/icon.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 0.05rem 0.15rem 0 0.1rem;
      }

      // .topiocn {
      //   position: absolute;
      //   top: 0;
      //   left: -0.05rem;
      //   z-index: 2;
      //   width: 1.56rem;
      //   height: 0.54rem;
      // }
    }

    .loadAll {
      font-size: 0.24rem;
      color: rgb(165, 169, 179);
    }
  }

  .showAll {
    font-size: 0.24rem;
    color: #626872;
    text-align: center;
    height: 0.8rem;
    line-height: 0.8rem;
  }

}
</style>