<template>
    <div>
      <van-popup
          v-model:show="isShowDeposit"
          round
          class="gameListDialog"
          :safe-area-inset-bottom="true"
          position="bottom"
          @close="close"
          >
          <myDeposit @close="close"></myDeposit>
      </van-popup>
    </div>
  </template>
  <script>
  import myDeposit from '../my/finance/myDeposit.vue';
  export default {
      components: {
          myDeposit
      },
      data() {
        return {
          isShowDeposit: false
        }
      },
      created() {
        let that = this;
        setTimeout(()=> {
            that.isShowDeposit = true;
        }, 10);
    },
      methods: {
         // 关闭
        close() {
            this.isShowDeposit = false;
            let that = this;
            setTimeout(()=> {
                that.$emit('colsePopup', 'showDeposit')
            }, 1000);
        }, 
      }
  }
  </script>
  