<template>
    <div class="entryLiveBefore">
        <img class="bg" v-real-img="liveInfo.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>

        <van-dialog :className="'psdDialog'" v-model:show="show" :title="$t('liveRoom.passwordRoom')" :confirmButtonText="$t('common.sure')"
            :cancelButtonText="$t('common.cancel')" confirmButtonColor="#eb457e" show-cancel-button @cancel="cancel"
            @confirm="goLiveRoom" :before-close="onBeforeClose">
            <div class="dialogCont">
                <van-field class="dialogInput" autocomplete="off" v-model.trim="password" minlength="4"
                    maxlength="16" :placeholder="$t('liveRoom.entryPswTips')" @keyup.enter="goLiveRoom"/>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { interRoom } from "@/api/live";
import { refreshKey } from "@/api/index";
import { mapState } from 'vuex';
import Aes from "@/utils/aes.js";
export default {
    data() {
        return {
            show: true,
            password: "",
            fromPath: ""
        }
    },
    computed: {
        ...mapState(['member', 'liveInfo', 'baseInfo', 'liveAE']),
    },
    beforeRouteEnter(to, from, next) {
        next((e) => {
          e.fromPath = from.path;
        });
    },
    created() {
        this.$store.commit("setState", { isLoadForLoad: false });
    },
    methods: {
        onBeforeClose(action) {
            new Promise((resolve) => {
                resolve(false);
            });
        },
        interRoom() {
            if (!this.password) return;
            let params = {
                liveId: this.liveInfo.liveId,
                uid: this.member.uid,
                adJumpUrl: "",
                anchorId: this.liveInfo.anchorId,
                isRoomPreview: 0,
                password: this.password,
                type: this.liveInfo.type
            }
            this.$store.commit("setState", { isLoadForLoad: true });
            interRoom(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let pullStreamUrl = this.baseInfo.streamSwitch && this.baseInfo.streamSwitch == '1' ? Aes.decryptL(res.data.pullStreamUrl, this.liveAE.randomKey, this.liveAE.randomVector) : res.data.pullStreamUrl,
                        url = pullStreamUrl.replace(/rtmp/, "webrtc");
                    if (!url) {
                        this.decryptFail(params);
                    } else {
                        this.jionRoom(res.data, params);
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                    this.$store.commit("setState", { isLoadForLoad: false });
                }
            }).catch(err => {
                this.$store.commit("setState", { isLoadForLoad: false });
            })
        },
        // 解密失败处理
        decryptFail(params) {
            refreshKey({ uid: this.member.uid }).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let liveAE = {
                        randomKey: res.data.randomKey,
                        randomVector: res.data.randomVector
                    }
                    this.$store.commit("setState", { liveAE });
                    setTimeout(() => {
                        this.interRoom(params);
                    }, 500);

                } else {
                    if (res.msg) this.$toast(res.msg);
                    this.$store.commit("setState", { isLoadForLoad: false });
                }
            }).catch(err => {
                this.$store.commit("setState", { isLoadForLoad: false });
            })
        },
        // 进直播间操作
        jionRoom(resData, params) {
            let obj = JSON.parse(JSON.stringify(this.liveInfo));
            obj = { ...obj, ...resData };
            delete obj.uid;
            obj.isPreview = params.isPreview;
            this.$store.commit("setState", { liveInfo: obj, isLoadForLoad: false });
            setTimeout(() => {
                this.$router.push('/liveRoom');
                this.$store.dispatch("getGroupList", String(obj.liveId));
            }, 500)
        },
        cancel() {
            if (this.fromPath && this.fromPath != '/liveRoom') {
                this.$router.push(this.fromPath);
            } else {
                this.$router.push('/home');
            }
        },
        goLiveRoom() {
           this.interRoom();
        }
    }
}
</script>

<style lang="scss" scoped>
.entryLiveBefore {
    height: 100vh;
    overflow: hidden;

    .bg {
        width: 100%;
        height: 100vh;
    }
}
</style>
<style  lang="scss" >
.psdDialog {
    .van-dialog__header {
        font-size: 0.32rem;
        font-weight: bold;
        color: #333;
        padding: 0.4rem 0;
    }

    .dialogCont {
        padding: 0 0.4rem 0.36rem;

        .dialogInput {
            border: 1px solid #c8cbd7;
            border-radius: 0.1rem;
        }
    }
}

.entryLiveBefore .van-overlay {
    background-color: transparent;
}
</style>