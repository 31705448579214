<template>
    <div class="next editUserInfo">
        <div class="title">
            <span> {{ $t('my.editUserInfo') }} </span>
            <div class="fixed" @click="goBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.35rem" height="0.35rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="item userAvatarBox">
            <div class="editPsw flexBetween">
                <span>{{ $t('my.myAvatar') }}</span>

                <van-uploader :before-read="beforeRead" :after-read="afterRead" class="funItem">
                    <img v-if="member && member.avatar" :src="member.avatar" />
                    <img v-else src="../../assets/img/errorImg/2.png" />
                </van-uploader>
            </div>
        </div>

        <div @click="goOtherPage('/mine/setting/edit/next')" class="item">
            <div class="editPsw flexBetween">
                <span>{{ $t('my.nickName') }}</span>
                <div class="flexCenterBL">
                    <span class="noSet">{{ member.nickname }}</span>
                    <svg t="1658128888404" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="5184" width="0.26rem" height="0.26rem">
                        <path
                            d="M750.976 532.288c7.68-19.456 3.84-42.688-11.456-58.496l0 0 0 0L358.4 79.808c-20.416-21.12-53.44-21.12-73.856 0-20.416 21.12-20.416 55.232 0 76.352L628.672 512l-344.192 355.84c-20.416 21.12-20.416 55.296 0 76.352 20.416 21.056 53.44 21.056 73.856 0l381.056-393.984C744.576 544.896 748.416 538.816 750.976 532.288z"
                            p-id="5185" fill="#788597"></path>
                    </svg>
                </div>
            </div>
        </div>

        <div @click="showGender=true" class="item">
            <div class="editPsw flexBetween">
                <span>{{ $t('my.sex') }}</span>
                <div class="flexCenterBL">
                    <span class="noSet">{{ gender }}</span>
                    <svg t="1658128888404" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="5184" width="0.26rem" height="0.26rem">
                        <path
                            d="M750.976 532.288c7.68-19.456 3.84-42.688-11.456-58.496l0 0 0 0L358.4 79.808c-20.416-21.12-53.44-21.12-73.856 0-20.416 21.12-20.416 55.232 0 76.352L628.672 512l-344.192 355.84c-20.416 21.12-20.416 55.296 0 76.352 20.416 21.056 53.44 21.056 73.856 0l381.056-393.984C744.576 544.896 748.416 538.816 750.976 532.288z"
                            p-id="5185" fill="#788597"></path>
                    </svg>
                </div>
            </div>
        </div>

        <div class="item">
            <div @click="goOtherPage('/mine/setting/edit/next?type=sign')" class="editPsw flexBetween">
                <span>{{ $t('my.sign') }}</span>

                <div class="flexCenterBL">
                    <span class="noSet">{{ member.signature }}</span>
                    <svg t="1658128888404" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        p-id="5184" width="0.26rem" height="0.26rem">
                        <path
                            d="M750.976 532.288c7.68-19.456 3.84-42.688-11.456-58.496l0 0 0 0L358.4 79.808c-20.416-21.12-53.44-21.12-73.856 0-20.416 21.12-20.416 55.232 0 76.352L628.672 512l-344.192 355.84c-20.416 21.12-20.416 55.296 0 76.352 20.416 21.056 53.44 21.056 73.856 0l381.056-393.984C744.576 544.896 748.416 538.816 750.976 532.288z"
                            p-id="5185" fill="#788597"></path>
                    </svg>
                </div>
                
            </div>
        </div>

        <div class="item marginTop">
            <div @click="goOtherPage('/mine/setting/password')" class="editPsw flexBetween">
                <span>{{ $t('my.editPassword') }}</span>
                <svg t="1658128888404" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="5184" width="0.26rem" height="0.26rem">
                    <path
                        d="M750.976 532.288c7.68-19.456 3.84-42.688-11.456-58.496l0 0 0 0L358.4 79.808c-20.416-21.12-53.44-21.12-73.856 0-20.416 21.12-20.416 55.232 0 76.352L628.672 512l-344.192 355.84c-20.416 21.12-20.416 55.296 0 76.352 20.416 21.056 53.44 21.056 73.856 0l381.056-393.984C744.576 544.896 748.416 538.816 750.976 532.288z"
                        p-id="5185" fill="#788597"></path>
                </svg>
            </div>
        </div>

        <van-popup v-model:show="showGender" position="bottom">
            <van-picker
            show-toolbar
            v-model="gender"
            :columns="columns"
            @confirm="onConfirm"
            :confirm-button-text="$t('common.sure')"
            :cancel-button-text="$t('common.cancel')"
            @cancel="showGender = false"
            />
        </van-popup>
    </div>
</template>
  
<script>
import { imgPreview } from "@/utils/imgPreview";
import { uploadAvatar } from "@/api/uploadApi";
import { modifyInfo } from "@/api/index";
import { mapState } from 'vuex';
export default {
    data() {
        return {
            showGender: false,
            columns: [this.$t('my.man'), this.$t('my.woman')],
            gender: ""
        }
    },
    computed: {
        ...mapState(['member', 'entry']),
    },
    created() {
        if ( this.member && this.member.sex && this.member.sex == 2) {
            this.gender = this.$t('my.woman');
        } else {
            this.gender = this.$t('my.man')
        }
    },
    methods: {
        goBack() {
            if (this.entry) {
                this.$router.push(this.entry);
            }
        },
        onConfirm(value, index) {
            let sex = index + 1;
            this.gender = value;
            this.modifyInfo({ sex });
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        // 上传图标 格式校验
        beforeRead(file) {
            if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
                this.$toast(this.$t('my.imgLoadTips'));
                return false;
            }
            return true;
        },
        // 上传图片
        afterRead(file, detail) {
            let that = this;
            let index = detail.index;
            //上传的图片可能分为单张上传和多张上传
            if (file instanceof Array) {
                for (let i = 0; i < file.length; i++) {
                    (function (arg) {
                        let formData = new FormData();
                        imgPreview(file[arg].file, async (files) => {
                            formData.append("file", files);
                            formData.append("kind", "indexPic");
                        });
                    })(i);
                }
            } else {
                let formData = new FormData();
                imgPreview(file.file, (files) => {
                    formData.append("file", files);
                    formData.append("type", "app_avatar");
                    uploadAvatar(formData).then(res => {
                        if (res.code == 0) {
                            // 上传成功之后 需要进行保存处理，调用用户头像保存
                            this.showEidtAvatar = false;
                            // 提示文案待更改 
                            // this.$toast(this.$t('my.userInfo.avatarSetTips'));

                            let params = {
                                avatar: "https://" + res.data.filePath
                            }
                            this.modifyInfo(params);
                        } else {
                            if (res.msg) this.$toast(res.msg);
                        }
                    }).catch(() => {
                        console.log('catch');
                    })
                });
            }
        },
        // 修改个人资料
        modifyInfo(params) {
            modifyInfo(params).then(res => {
                console.log(res);
                if (res.code == 0) {
                    this.$toast(this.$t('common.eidtSuccess'));
                    this.$store.dispatch('refreshUserInfo');
                    this.showGender = false;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log("catch");
            })
        },
        quit() {
            this.$dialog.confirm({
                // title: this.$t('common.kindTips'),
                message: this.$t('entry.loginOutTips'),
                confirmButtonColor: "#eb457e",
                confirmButtonText: this.$t('common.sure'),
                cancelButtonText: this.$t('common.cancel'),
                overlay: true
            })
                .then(() => {
                    // 登出逻辑需要编辑
                    this.$store.commit("setState", { member: null });
                    sessionStorage.removeItem("token");
                    this.$router.push("/login");
                    this.$store.dispatch("timDestroy");
                })
                .catch(() => {
                    // on cancel
                });
        },
        goSetFundPsw() {
            if (!this.member) {
                this.goOtherPage('/login');
            }

            if (this.member && this.member.phone) {
                this.$store.commit("setState", { setPasswordEntry: "" });
            }

            this.goOtherPage('/mine/setting/fundPsw');
        }
    }
}
</script>
  
<style lang="scss" scoped>
.editUserInfo {
    background-color: #f9f9f9;
    font-size: 0.28rem;
    color: #333;

    .item {
        position: relative;
        padding-left: 0.3rem;
        height: 1rem;
        background-color: #fff;
        vertical-align: middle;
        overflow: hidden;
        border-bottom: 1px solid #f2f2f2;

        &.marginTop {
            margin-top: 0.2rem;
        }

        &.userAvatarBox {
            height: 1.28rem;

            img {
                width: 1.12rem;
                height: 1.12rem;
                border-radius: 50%;
            }
        }

        .editPsw {
            width: 100%;
            height: 100%;
            padding-right: 0.28rem;

            .noSet {
                color: #a5a9b3;
                font-size: .28rem;
                margin-right: 0.2rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: calc(100vw - 2.6rem);
                text-align: right;
            }
        }
    }
}</style>