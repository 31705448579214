<template>
    <div>
        <van-popup v-model:show="isShowTopAnchor" position="right" class="gameListDialog"
            :style="{ width: '2rem', height: '100%' }" @close="close">
            <div class="topAnchorBox">
                <div class="topAnchorTitle flexCenter">
                    <img src="../../assets/img/live/laud.png" />
                    <span>{{ $t('liveRoom.recommended') }}</span>
                </div>

                <div class="topAnchorCont">
                    <div v-for="(item, index) in recommendLiveLists" :key="`${index}`" @click="goLive(item)"
                        class="topAnchorItem">
                        <img class="topAnchorItemBg" v-real-img="item.avatar" />
                        <div class="topAnchorItemUser">
                            <img src="../../assets/img/live/user.png" />
                            <span>{{ item.rq ? item.rq : 0 }}</span>
                        </div>

                        <img v-show="item.webStatus" class="topAnchorItemLock" src="../../assets/img/home/app.png" />
                        <img v-show="!item.webStatus && item.pking" class="topAnchorItemLock" src="../../assets/img/home/pk.png" />
                        <img v-show="!item.webStatus && item.type == 3 && !item.pking" class="topAnchorItemLock" src="../../assets/img/home/liveLock.png" />
                        <img v-show="!item.webStatus && (item.type == 1 || item.type == 2) && !item.pking" class="topAnchorItemLock" src="../../assets/img/home/money.png" />
                        <div class="topAnchorItemUserName">{{ item.nickname }}</div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
  
<script>
import { recommendLiveList } from "@/api/index";
import { interRoom, roomPreview, anchorBase } from "@/api/live";
import { mapState } from 'vuex';
import interLive from '@/pages/mixin/interRoom';
export default {
    name: "liveRoomTopAnchor",
    mixins: [interLive],
    data() {
        return {
            recommendLiveLists: [], //推荐主播列表
            isShowTopAnchor: false
        }
    },
    computed: {
        ...mapState(['member', 'liveInfo']),
        isManage() {
            let flag = false;
            if (this.member && this.member.badgeList && this.member.badgeList.length > 0) {
                let index = this.member.badgeList.findIndex(item => {
                    return item == '4'
                });

                if (index != -1) {
                    flag = true;
                }
            }
            return flag;
        }
    },
    created() {
        let that = this;
        setTimeout(() => {
            that.isShowTopAnchor = true;
        }, 10);
        this.getRecommendLiveList();
    },
    methods: {
        close() {
            this.isShowTopAnchor = false;
            let that = this;
            setTimeout(() => {
                that.$emit('colsePopup', "showTopAnchor")
            }, 1000)
        },
        // 推荐主播
        getRecommendLiveList() {
            let params = {
                uid: this.member.uid,
                type: 1
            }
            recommendLiveList(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let arr = JSON.parse(JSON.stringify(res.data));
                    if (arr.length > 0) {
                        arr = arr.filter(item => {
                            return item.liveStatus == 1 && item.anchorId != this.liveInfo.anchorId
                        });
                    }
                    this.recommendLiveLists = JSON.parse(JSON.stringify(arr));
                    this.showTopAnchor = true;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => { })
        },
        // item 点击
        goLive(item) {
            if (!this.member) return this.$router.push('/login');
            if (!item.anchorId || !item.liveId) return;

            this.getAnchorBase(item);
        }
    }
}
</script>
  
<style lang="scss" scoped>
.topAnchorBox {
    padding: 0.4rem 0.14rem 0.2rem;

    .topAnchorTitle {
        font-size: 0.24rem;

        img {
            width: 0.32rem;
            height: 0.32rem;
            margin-right: 0.1rem;
        }
    }

    .topAnchorCont {
        .topAnchorItem {
            width: 1.8rem;
            height: 1.8rem;
            color: #fff;
            position: relative;
            margin-top: 0.16rem;

            .topAnchorItemBg {
                width: 100%;
                height: 100%;
            }

            .topAnchorItemUser {
                position: absolute;
                top: 0;
                left: 0;
                margin: 0.1rem 0 0 0.1rem;
                font-size: 0.2rem;

                img {
                    width: 0.24rem;
                    margin-right: 0.05rem;
                }
            }

            .topAnchorItemLock {
                height: 0.24rem;
                position: absolute;
                top: 0;
                right: 0;
                margin: 0.1rem 0.1rem 0 0;
            }

            .topAnchorItemUserName {
                position: absolute;
                bottom: 0;
                padding: 0 0.1rem 0.1rem 0.1rem;
                font-size: 0.2rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
            }
        }
    }
}
</style>