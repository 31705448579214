<template>
  <div>
    <van-popup
        v-model:show="isShowAddCard"
        round
        class="gameListDialog"
        :safe-area-inset-bottom="true"
        position="bottom"
        @close="close"
        >
            <addCards @close="close"></addCards>
    </van-popup>
  </div>
</template>

<script>
import addCards from '../my/addCards.vue';
export default {
    components: {
        addCards
    },
    data() {
        return {
            isShowAddCard: false, // 弹框是否展示
        }
    },
    created() {
        let that = this;
        setTimeout(()=> {
            that.isShowAddCard = true;
        }, 10);
    },
    methods: {
        close() {
            this.isShowAddCard = false;
            let that = this;
            setTimeout(()=> {
                that.$emit('colsePopup', 'showAddCard')
            }, 1000);
        },
    }
}
</script>

<style>

</style>