module.exports = {
    footer: {
        index: "首页",
        discount: "活动",
        my: "我的",
        game: "游戏",
        live: "直播"
    },
    register: {
        title: "注册",
        phoneRegister: "手机注册",
        suPhoneTitle: "请输入您的手机号码注册新账号",
        entryPhoneTip: "请输入您的手机号码",
        emailRegister: "邮箱注册",
        suEmailTitle: "请输入您的邮箱地址以创建新账户",
        entryEmailTip: "请输入您的邮箱地址",
        confirmPhoneTips: "确认这是您正确的电话号码",
        confirmEmailTips: "确认这是您正确的邮箱地址",
        checkPhoneError: "请输入正确的电话号码",
        checkEmailError: "请输入正确的邮箱地址",
        hasPhoneRegister: "该手机号已注册过",
        hasEmailRegister: "该邮箱已注册过",
        entryCodeTips: "请输入验证码",
        confirmNewPassword: "确认新密码",
        verificationCodeSent: "验证码已发送",
        perfectPersonalInfo: "完善个人信息",
        jumpOver: "跳过",
        inconsistentTwice: "两次输入的密码不一致",
        uploadedSucs: "上传成功",
    },
    login: {
        title: "登录",
        forgetPsw: "忘记密码",
        userAccountTips: "请输入您的电话号码或邮箱地址",
        userPasswordTips: "请输入密码",
        loginAccountError: "手机或邮箱格式错误",
        noHasAccount: "该账号未注册",
        loginOut: "帐号已在另一台设备上登录",
        findPassword: {
            moreThan6Tips: "请输入至少6字符的密码",
            codeSend: "验证码发送",
            userNewPasswordTips: "请输入新密码",
            setNewPassword: "设置登录密码",
            limitPwdLength: " 密码长度为6-12字符",
            twiceSame: "两次输入的密码必须一致",
        }
    },
    home: {
        activityDetails: "活动",
        announcement: "公告",
        recommendGames: "推荐游戏",
        anchorRocket: "主播火箭榜",
        searchTips: "请输入昵称或ID",
        search: "搜索",
        nowApply: "立即申请",
        nowRechange: "立即充值",
        customerService: "客户服务",
        downLoadContinue: "下载APP继续观看",
        downLoadApp: "下载APP",
        downLoadNow: "立即下载APP",
        downLoad: "下载",
        noLogin: "未登录"
    },
    gameCenter: {
        title: "游戏中心",
        record: "记录",
        addGameCollectionTips: "收藏成功",
        cancelGameCollectionTips: "已取消收藏",
        gameAbnormality: "游戏异常",
        GoToGame: "前往游戏界面",
    },
    my: {
        follows: "关注",
        fans: "粉丝",
        privateLetter: "私信",
        accountBalance: "账户余额",
        recycle: "一键回收",
        userInfo: "个人信息",
        editUserInfo: "修改个人资料",
        myAvatar: "我的头像",
        city: "城市",
        sign: "签名",
        signature: "个性签名",
        signatureTips: "填写您的个人资料容易引起别人的关注",
        nickName: "昵称",
        nickNameTips: "最多输入10个字",
        sex: "性别",
        man: "男",
        woman: "女",
        unknown: "未知",
        editPassword: "修改密码",
        save: "保存",
        imgLoadTips: "请上传 png、jpg、jpeg 格式图片",
        eidtLoginPassword: "修改登录密码",
        enterOldPwdTips: "请输入原密码",
        setNewPwd: "设置新密码",
        enterNewPwdTips: "请输入新密码",
        confirmNewPwdTips: "确认新密码",
        enterLeastSix: "请输入不少于6个字符的密码",
        transferAmount: "转账金额",
        bindPhone: "绑定手机号",
        detail: "详情",
        income: "收入",
        expenditure: "支出",
        editUserNameTips: "您确定消费",
        editUserNameTips2: "币更换昵称吗？第一次免费",
        aboutTilte: "使用条款 THlive",
        menuList: {
            recharge: "充值",
            withdraw: "提款",
            mall: "购买",
            noble: "贵族"
        },
        listView: {
            jionProxy: "代理加盟",
            myProxy: "我的代理",
            transaciton: "交易记录",
            gameReport: "游戏报告",
            myNoble: "我的贵族",
            myProps: "我的道具",
            about: "关于",
            set: "系统设置"
        },
        systemSet: {
            title: "系统设置",
            blacklist: "黑名单",
            userAgreement: "用户协议",
            loginOut: "退出",
            loginOutTips: "您确定要退出吗？",
            pullOut: "拉出",
        },
        mail: {
            title: "通知",
            systemNotice: "系统通知",
            privateLetterTips: "在这输入私信内容",
            send: "发送",
        },
        follow: {
            follow: "我的关注",
            fans: "我的粉丝",
            followeSuccess: "关注成功",
            Unfollowed: "已取消关注",
            followed: "已关注"
        },
        finance: {
            myGold: "我的金币",
            amountRange: "充值金额范围",
            // amountRangeTips: "请输入充值金额 ,000 结尾",
            rechargeReward: "充值奖励",
            willReceive: "您将获赠",
            rechargeSteps: "充值步骤",
            rechargeStepsDetail: "直接转账到上面显示的银行卡号，备注：您的账号ID。转账成功后， 请输入充值金额， 点击【立即充值】5分钟后自动到账",
            bankRechargeTips: "提示： 银行卡不定期更换，每次充值请重新核对银行卡信息，请勿按照转账记录直接转账，感谢您的理解和支持！",
            bankRechargeSuccess: "财务正在核对，3～5分钟内未到账，请联系客服",
            selectPaymentChannel: "请选择支付通道",
            USDTAdress: "USDT收款地址",
            USDTCode: "USDT收款码",
            USDTTips: "请向以上的USDT地址转账再提交订单",
            bill: "提单",
            exchangeRate: "请以实际支付的汇率为准",
            USDTDetail: "1.请使用T",
            USDTDetail01: "协议进行，任何非",
            USDTDetail02: "协议的转账可能无法到账",
            USDTDetail1: "2.请确保收款地址收到",
            USDTDetail2: "USDT（不含手续费）否则转账金额与实际收到金额会有差异",
            USDTDetail3: "3.您支付至上面的地址后，需要网络节点确认，可能会有延迟，请耐心等待！",
            submitAfterTransfer: "先转账再提交订单",
            thirdOrderTips: "确认使用",
            thirdOrderTips1: "充值",
            notRecharged: "未充值",
            recharged: "已充值",
            tutorial: "教程",
            payWay: "存款方式",
            hotType: {
                hot: "Hot",
                recommend: "推荐",
                fad: "热",
                rapidly: "极速"
            },
            depositAmount: "存款金额",
            depositAmountTips: "请输入充值金额",
            accountNumber: "汇款姓名",
            accountNumberTips: "请输入汇款姓名",
            accountBankName: "汇款银行名称",
            accountBankNameTips: "请输入或选择银行名称",
            orderName: "汇款银行卡号",
            orderNameTips: "请输入或选择银行卡号",
            aboutReceiptAmount: "预计到账:",
            receiptAmount: "到账金额",
            currency: "金币",
            currencyExchangeRate: "参考汇率",
            betAmountThan: "投注金额大于",
            betAmountThan1: "才可提现",
            promotionBonus: "优惠活动彩金",
            promotionalWinnings: "优惠彩金流水",
            withdrawalRules: "提现规则",
            singleDepositAmount: "单笔存款金额",
            receiptRuleTips: "实际到账金额将以金币的兑换比例计算",
            receiptRuleTips2: "USDT转账手续费由平台承担",
            nowDeposit: "立即存款",
            serialNo: "serial 号",
            serialNoTips: "请输入serial号",
            serialNoTips1: "serial号码位数为11或13或14位",
            serialNoTips2: "serial号码位数为14位",
            rechangeNo: "充值卡号码",
            rechangeNoTips: "请输入充值卡号码",
            rechangeNoTips1: "充值卡号码位数为13或15位",
            rechangeNoTips2: "充值卡号码位数为12或14位",
            rechangeType: "充值卡类型",
            rechangeInfo: "存款信息",
            ifPayReady: "如已完成支付，请",
            name: "姓名：",
            account: "账号：",
            amount: "金额：",
            amountTips: "（转账金额务必与订单金额一致）",
            adress: "地址：",
            bindBankCard: "已绑定银行卡",
            rechangeName: "存款姓名",
            rechangeNameTips: "请输入持卡人姓名",
            rechangeNameWarn: "为了及时到账，请务必输入正确的存款人姓名",
            rechangeBankTips: "公司银行卡不定期更换，每次充值请根据提 交订单生成的银行卡转账，切勿直接转帐至 之前转入的银行卡，否则无法到账，概不负责！",
            bankName: "银行名称",
            bankNameTips: "请选择一个银行",
            bankNo: "银行卡号",
            bankNoTips: "请输入银行卡号",
            importantHint: "重要提示",
            usdtAmountTips: "（请按以上金额进行转账，否则影响到账速度）",
            saveCode: "截图保存二维码",
            receivingAddress: "收款地址：",
            bindAddress: "已绑定地址",
            paymentAddress: "付款地址",
            paymentAddressTips: "请输入付款地址",
            transferOrder: "转账订单号",
            transferOrderTips: "请输入您的转账订单号",
            QRCode: "二维码",
            orderDetail: "订单详情",
            transactionType: "交易类型",
            transactionItem: "交易项目",
            status: "状态",
            transactionHour: "交易时间",
            OrderNumber: "订单号码",
            walletAmount: "钱包金额",
            putAway: "收起",
            interchangeTips: "场馆内钱包不支持互转",
            enterTransferAmountTips: "请输入转账金额",
            insufficientBalance: "余额不足",
            nowTransfer: "立即转账",
            chooseWallet: "选择钱包",
            queryFlow: "查询流水",
            recycle: "回收",
            goldWithdrawal: "金币提现",
            withdrawalRecords: "提款记录",
            withdrawal: "提现",
            withdrawableAmount: "可提现额度",
            currentTotalTurnover: "当前总流水",
            totalHistory: "历史总流水",
            exchangeRate: "当前兑换比例",
            withdrawWay: "提款方式",
            selectWithdrawalCurrency: "选择取款货币",
            withdrawCardNum: "取款银行卡号",
            selectWithdrawCardNum: "请选择取款银行卡号",
            amountCanWithdrawn: "可取款金额：",
            withdrawChAdre: "取款通道地址",
            withdrawChAdreTips: "请选择取款通道地址",
            withdrawalAmount: "取款数量",
            withdrawalAmountTips: "请输入取款数量",
            withdrawalPsw: "提款密码",
            setWithdrawalPsw: "设置提款密码",
            withdrawalPswTips: "请输入6-16位数字符",
            nowWithdraw: "立即取款",
            noHaveAccontTips: "请先绑定一个虚拟币账户，用于取款",
            billingDetails: {
                billingDetails: "当前流水详情",
                totalTurnover: "需要的总流水：",
                finishTurnover: "已完成流水：",
                activitiesRequired: "需要的活动流水：",
                finishActivitiesRequired: "已完成活动流水："
            },
            withdrawalMoney: "提现金额",
            withdrawalMoneyTips: "请输入提现金额",
            pleaseSelectBank: "请选择银行",
            transferSuccessTips: "转账成功！",
            selectBank: "选择银行",
            moneyEndTips: "金额必须000结尾",
            moneyNoEnough: "可用余额不足",
            bindBnkFirstTips: "请先绑定银行卡",
            canWithdrawn: "可提现余额",
            addBank: "添加银行卡",
            immediateReturn: "立返",
            payeeName: "收款姓名",
            bank: "银行",
            remitterMessage: "转账留言",
            branch: "支行",
            confirmWithdraw: "确认提现",
            setWithdrawPwd: "设置提现密码",
        },
        proxy: {
            phoneNumber: "手机号码",
            phoneNumberTips: "请输入您的手机号码",
            phoneCode: "手机验证码",
            agentJoin: "代理加盟",
            tagentJoin: "THlive 代理加盟",
            agentJoinTips1: "人间一趟，我们绝不平凡",
            agentJoinTips2: "超高返佣比 加入THlive <br/> 百万佣金不是梦",
            joinConsultation: "加盟咨询",
            clickEnterOnline: "点击进入在线咨询",
            consult: "咨询",
            ApplicationsMembership: "申请加入",
            dataAnalysisDetail: "周详的数据分析报表",
            accountAdjustment: "账户调整",
            instructionManual: "备注说明",
            commissionReport: "佣金报表",
            subordinateCommissionShare: "下级佣金分成",
            commission: "佣金",
            commissionRate: "佣金比例",
            commissionRateExplain: "佣金比例会跟随活跃人数和公司总输赢的涨幅而进行变化",
            activeMember: "活跃会员",
            addMember: "新增会员",
            addMemberExplain: "本月新注册的会员",
            netWinLoss: "冲正后净输赢",
            lastMonthBalance: "上月结余",
            netWin: "净输赢",
            companyProfit: "公司总输赢",
            platformFee: "平台费用",
            totalRecharge: "会员总存款",
            totalWithdraw: "会员总取款",
            totalBalance: "会员总余额",
            liveProfit: "直播收益",
            firstRechargeNum: "首存人数",
            firstRechargeMoney: "首存金额",
            transferNextMonth: "结转下月",
            actualCommission: "实际派发佣金",
            status: {
                undistributed: "未派发",
                dispatched: "已派发",
                unsettlement: "未结算"
            },
            commissionReportWarn1: "1.系统每日凌晨更新一次报表",
            commissionReportWarn2: "2.报表中数据，总输赢、净输赢中正数代表代理方盈利，负数代表代理方亏损；",
            commissionRules: "佣金规则",
            descriptionCommissionRatio: "佣金比例说明",
            descriptionCommissionRatioCont: "代理的佣金比例由本月总输赢和活跃人数两项条件决定，同时满足时获得对应等级的佣金比例。",
            activeNumber: "活跃人数",
            activeNumberExplain: "本月存款达到100金币，视为活跃用户",
            monthlyDeposit: "当月存款",
            level: "级别",
            noteCont: "注：佣金=冲正后净输赢*佣金比例+账户调整+下级代理佣金分成",
            commissionCalculationMethod: "佣金计算方式",
            commissionCalculationMethodDetail1: "佣金=冲正后净输赢*佣金比例+账户调整+下级代理佣金分成",
            commissionCalculationMethodDetail11: "（代理分成由THlive承担）",
            commissionCalculationMethodDetail2: "冲正后净输赢=当月净输赢+上月结余（指上月净输赢）",
            commissionCalculationMethodDetail31: "净输赢=总输赢-平台费用（基础费用",
            commissionCalculationMethodDetail32: "会员红利",
            commissionCalculationMethodDetail33: "场馆费",
            commissionCalculationMethodDetail34: "总输赢为负数，平台不收取费用）",
            commissionCalculationMethodDetail4: "总输赢=当月所有下级会员在平台上总存款-总取款-用户余额+上一次结算时的余额-直播收益扣费",
            commissionCalculationMethodDetail5: "(如当月总输赢未达到最低佣金级别，将自动累计到下个月，）",
            commissionCalculationMethodDetail6: "账户调整=当会员出现掉单 、误存等情况，平台将对会员进行账户资金调整；",
            SubAgentCommission: "下级代理佣金",
            SubAgentCommissionDetail1: "只要您的下级代理在招下线，您将可享有下级代理下线的佣金额外分成。总而言之，越多活跃会员，您得到的佣金就越高；越多下级代理，您在下级代理那得到的佣金额外分成会使您的总佣金更高",
            SubAgentCommissionDetail2: "THlive将支付您的下级1级代理佣金的额外分成",
            SubAgentCommissionDetail3: "及2级代理佣金的额外分成",
            SubAgentCommissionDetail4: "给您",
            SubAgentCommissionSuHead: "比如：",
            SubAgentCommissionSuDetail1: "您的1级代理佣金=",
            SubAgentCommissionSuDetail2: "您的佣金额外分成=",
            SubAgentCommissionSuDetail3: "您的2级代理的下线代理佣金=",
            SubAgentCommissionSuDetail4: "您的佣金额外分成=",
            SubAgentCommissionSuDetail5: "您在下级代理那得到的总分成=",
            commissionPayment: "佣金支付",
            paymentTime: "支付时间",
            paymentTimeExplain: "每月5日支付上月佣金。列如：代理一月份产生的佣金，在二月5日期间进行结算。",
            paymentMethod: "支付方式",
            paymentMethodExplain: "我们会将佣金派发至您的代理佣金钱包中，您可以通过佣金钱包直接取款（无需流水）也可以将佣金兑换到中心钱包，如兑换到中心钱包需达成一倍流水才可取款。",
            ruleNetloss: "净输赢负值",
            ruleNetlossExplain: "如果当月净输赢为负，则负值金额转为本月结余累计到下个月的净输赢中，直至净输赢为正数，才会产生佣金。",
            commissionPaymentWarn: "任何使用无诚信的方法以骗取佣金将会被永久冻结账号，并终止合作关系，所有佣金一概不予发放",
            agentExchange: "代理兑换",
            currentCommission: "当前佣金",
            footer: {
                memberStatistics: "会员统计",
                memberStatisticsExplain: "注册会员与有效随时查看，对你的会员了如指",
                commissionReportExplain: "报表可视化直观可见，佣金月月拿。",
                memberWinsLoses: "会员输赢",
                memberWinsLosesExplain: "会员输赢状况及总平台费用清晰直观",
                commissionRulesExplain: "佣金规则详细透明，解除您的所有疑惑",
            },
            noBindPhoneTips: "您未绑定手机号，请前往绑定",
            submitApplicationTips: "您已提交申请加入，请耐心等待客服处理，有任何疑问请联系24小时在线客服",
            memberID: "会员ID",
            memberIDTips: "请输入会员ID",
            agentIDTips: "请输入代理ID",
            subAgentIDTips: "请输入上级代理ID",
            memberDepositRecord: "会员存款记录",
            memberWithdrawalRecord: "会员取款记录",
            memberReport: "会员报表",
            SubAgent: "下级代理",
            SubAgentReport: "下级代理报表",
            totalDeposit: "存款总额",
            totalWithdrawal: "取款总额",
            balance: "余额",
            companyWinsLoses: "公司输赢",
            selectTime: "请选择时间",
            filter: "筛选",
            selectMonth: "选择月份",
            myMember: "我的会员",
            time: "时间",
            startDate: "开始日期",
            endDate: "结束日期",
            selectDateTips: "选择年月日",
            userLevel: "用户等级",
            memberInfo: "会员信息",
            grade: "等级",
            clevel: "层级",
            numberDeposits: "存款笔数",
            totalDepositAmount: "存款总金额",
            numberWithdrawals: "取款笔数",
            withdrawAmount: "取款总金额",
            lastLoginTime: "最后登录时间",
            proxyUrl: "注册来源",
            myAgent: "我的代理",
            myCommission: "我的佣金",
            exchange: "兑换",
            withdrawMoney: "提款",
            dataMonth: "本月数据",
            dataMonthExplain: "每天凌晨进行数据更新",
            numberFirstDepositors: "首存人数",
            numberFirstDepositorsExplain: "注册后，第一次存款的会员",
            numberNewLowerLevel: "下级代理新增人数",
            dataReport: "数据报表",
            promotionTool: "推广工具",
            dividend: "红利",
            serviceCharge: "服务费",
            baseFee: "基础费用",
            myPromotionalQRCode: "我的推广二维码",
            agentWithdrawal: "代理取款",
            downloadBgFontProxy: "边看美女直播边玩游戏就可以赢钱",
            subAgentReportTable: {
                agentID: "代理ID",
                superiorAgent: "上级代理",
                commissionShare: "佣金分成",
                totalBelowFee: "总佣金分成：",
                belowFeePercent: "佣金分成比例：",
                proxyLevel: "代理级别：",
                activeNum: "活跃人数：",
                justifyNetWin: "冲正后总输赢：",
                commissionPercent: "佣金比例：",
                accountAdjustment: "账户调整："
            },
            level1Agent: "1级代理",
            level2Agent: "2级代理",
            level1AgentIDTips: "请输入1级代理ID",
            level2AgentIDTips: "请输入2级代理ID",
            level1AgentNum: "1级代理人数",
            level2AgentNum: "2级代理人数",
            level1AgentID: "1级代理ID",
            downlineMember: "下线会员",
            searchTips: "只展示下级已结算数据，未结算的不展示",
            AgentWithdrawal: "代理提现",
            applyProxyTips: "平台收到您的提交申请后，我们将给您致电以确认您的提交申请，请您保持手机畅通以便尽快通过审核。",
        },
        invite: {
            title: "分享",
            revenue: "收益",
            totalRevenue: "总收益",
            invitationBonus: "邀请奖励",
            firstDepositBonus: "首存奖励",
            sharePeople: "分享人数",
            shareRedemption: "分享兑换",
            shareWithdrawal: "分享取款",
            invitationRecord: "邀请记录",
            accountName: "账号名字",
            registrationTime: "注册时间",
            nowInvita: "立即邀请",
            inviteRedemption: "邀请兑换",
            exchangeRecord: "兑换记录",
            exchangeAount: "兑换金额",
            enterExchangeAount: "请输入兑换金额",
            currentBalance: "当前余额：",
            withdrawalRecords: "提现记录",
            time: "时间",
            status: {
                all: "全部",
                underReview: "审核中",
                success: "成功",
                pass: "通过",
                turnDown: "驳回"
            },
            reasonRejection: "驳回原因：",
            inviteLink: "邀请连接",
            myExclusiveLink: "我的专属链接",
            myShareLink: "我的分享链接",
            download: "下载",
            inviteWithdrawal: "邀请取款",
            confirmAmount: "确认取款数额",
            recycledSuccessfully: "回收成功",
            noHaveUsdtAccontTips: "您未绑定虚拟币帐号，请前往绑定",
            noHaveBankAccontTips: "您还未绑定银行卡，请先绑定银行卡",
            activityConditions: "活动条件",
            downLoadBgFont: "邀请好友  即刻赚钱",
            copyLink: "复制链接",
            cont1: "1.邀请好友下载注册成功，获得",
            cont11: "，与他首存的",
            cont12:"",
            cont2: "2.收益可以兑换金币 ，最低兑换",
            cont21: "金币，每天限一次",
            cont3: "3.收益提现会扣除",
            cont31: "手续费，最低提现",
            cont32: "金币",
            cont4: "4.收益兑换金币或提现，客服进行审核，24小时内会发放",
            cont5: "5.平台发现有恶意注册套利行为，将会取消相应奖励并封号。"
        },
        wallet: {
            arrowSwitchTips: "温馨提示：点击箭头切换转账方向",
            transferTips: "温馨提示：转入转出单位均为金币",
            enterTransferNum: "请输入转账金额",
            transfer: "转账",
            selectTransferPlatform: "请选择转账平台",
            centerWallet: "中心钱包",
            transferIn: "转入",
            transferOut: "转出",
            transferNum0: "请输入正确的金额",
        },
        noble: {
            title: "贵族中心",
            wzzm: "万众瞩目",
            wzzmDetail1: "酷炫入场,特殊 弹幕,",
            wzzmDetail2: "地位",
            pk: "PK加成",
            pkDetail: "倍",
            zslw: "专属礼物",
            zslwDetail: "",
            zslwDetail1: "贵族礼物",
            gzlh: "贵族靓号",
            gzlhDetail: "位靓号就是这么酷炫",
            gzlhNo: "无",
            cjft: "超级防踢",
            cjftDetail: "房管不可以把你禁言或把你踢出房间",
            cjftDetail1: "房管和主播不可以把你禁言或把你踢出房间 ",
            smcl: "私密畅聊",
            smclDetail: "和主播私聊",
            cjys: "超级隐身",
            cjysDetail: "进房，聊天，榜单隐身",
            cjysDetail1: "进房隐身",
            buyTips: "用",
            buyTips1: "购买",
            renew: "续费",
            renewSuccess: "续费成功",
            award: "奖励",
            opened: "开通",
            expireDate: "到期时间",
            upgrade: "升级",
            nobleName: "贵族的靓号",
            prettyNum: "靓号",
            stealthFun: "隐身功能",
            roomStealth: "进房隐身",
            chatStealth: "聊天隐身",
            topStealth: "榜单隐身",
            upgradeConfirmationTips: "您确定要升级贵族吗？",
            upgradeSuccess: "升级成功",
            upgradeTips: "即将花费金币进行续费，并且您将得到相应的续费金币奖励",
            noPermissions: "您目前没有这个权限，请升级至",
            expiredTips: "您的贵族身份已过期，请重新购买",
            turnedOn: "已开启",
            turnedOff: "已关闭"
        },
        addCard: {
            title: "绑定银行卡",
            realName: "真名",
            realNameTips: "请输入您的姓名",
            realNameTips2: "(姓名注意有没有空格)",
            branch: "分行",
            branchTips: "请输入银行卡的城市名称",
            code: "验证码",
            withdrawPassword: "提现密码",
            withdrawPasswordTips: "请输入6位密码",
            withdrawPasswordTips1: "请输入提现密码",
            chooseBank: "选择开户银行",
            bindSuccessTips: "绑定成功",
            beyondNumTips: "绑定的银行卡数量已达到限制",
        },
        mall: {
            title: "商城",
            day: "天",
            toPay: "支付",
            confirmBuy: "确认购买",
            buySuccess: "购买成功",
            changeActiveMallTips: "你想使用炫酷的",
            end: "到期",
            changeSuccess: "已激活成功"
        }
    },
    discount: {
        header: "优惠活动",
        automatic: "自动派发",
        Application: "客服申请"
    },
    record: {
        dayList: {
            today: "今日",
            yesterday: "昨日",
            last3Days: "近3日",
            last7Days: "近7日",
            last30Days: "近30日",
            customize: "自定义"
        },
        transactionSuccess: "交易成功",
        orderNum: "订单号",
        projectName: "项目名称",
        orderQuestion: "对该订单号有什么疑问",
    },
    lottery: {
        inBet: "投注中",
        closure: "封盘中",
        issue: "期号",
        lotteryNumber: "开奖号",
        howToPlay: "玩法说明",
        betRecord: "投注记录",
        betNow: "投注",
        setBetAmount: "设置投注金额",
        backHall: "返回大厅",
        customize: "自定义",
        betAmount: "总投注",
        showAll: "已显示全部",
        betConfirm: "投注确认",
        confirmBet: "确认投注",
        betSuccess: "投注成功！",
        No: "",
        expect: "期",
        chooseLottery: "选择彩票",
        noMore: "没有更多了",
        newBetRecrod: "还没有新的投注记录",
        totalAmount: "总金额",
        enterCustomAmount: "修改单注筹码",
        sureClearBet: "确认清空投注吗？",
        entryNumberTips: "请输入10-20000的数字！",
        fish: {
            title: "鱼虾蟹",
            inBet: "投注中",
            issue: "期号",
            lu: "虎",
            hulu: "葫芦",
            ji: "鸡",
            yu: "鱼",
            xia: "虾",
            xie: "蟹"
        },
        big: {
            title: "快三",
        },
        color: {
            title: "色蝶",
        },
        status: {
            all: "全部",
            wait: "待开奖",
            lose: "未中奖",
            win: "已中奖",
            tie: "和局"
        },
        detail: {
            betDetail: "投注详情",
            gameName: "游戏名称：",
            issue: "期号：",
            betTotal: "总投注：",
            betMultiple: "投注倍数：",
            singleBetAmount: "单注金额：",
            betTime: "投注时间：",
            betType: "投注类型：",
            betCont: "投注内容：",
            lotteryResults: "开奖结果：",
            status: "状态:",
            bonusAmount: "派奖金额",
            bonusTime: "派奖时间:",
            orderNo: "交易订单号:",
            betNum: "投注数量",
            betAmount: "投注金额",
            winningAmount: "中奖金额",
            totalBetNum: "总投注金额",
            totalWinningAmount: "总中奖金额",
        },
        note: "注",
        placeABet: "投注",
    },
    about: {
        tips: "Đặc biệt lưu ý:",
        cont: "Trước khi sử dụng các dịch vụ do THlive cung cấp, bạn cần đọc kỹ, hiểu rõ và đồng ý với nội dung của điều khoản sửa dụng này, bao gồm những điều khoản miễn trừ hoặc hạn chế trách nhiệm. Trường hợp bạn không đồng ý với bất kỳ điều khoản, điều kiện nào trong điều khoản sử dụng này, bạn vui lòng không sử dụng THlive. Khi bạn tham gia, sử dụng THlive được hiểu là bạn đồng ý với toàn bộ nội dung trong điều khoản sử dụng này, cùng với các bản sửa đổi bổ sung tùy từng thời điểm.",
        title1: "I.Điều khoản chung",
        cont1: "1.1 Khi bạn đã đồng ý với nội dung của điều khoản sử dụng này, bạn dựa vào các gợi ý trên trang để đăng ký tài khoản. Sau khi đăng ký thành công tài khoản bạn đã đạt được thỏa thuận với THlive, hoàn toàn chấp nhận các điều kiện trong điều khoản sử dụng này.",
        cont2: "1.2 Sau khi đăng ký thành công, người dùng tự bảo quản tài khoản và mật khẩu của mình.",
        cont3: "1.3 Sau khi đăng ký tài khoản trên THlive, ngoài những kênh yêu cầu quyền hạn mở, thì người dùng có thể sử dụng tất cả các dịch vụ, chương trình trên THlive. Khi bạn sử dụng các dịch vụ của THlive được hiểu bạn đồng ý với điều khoản và thông báo trên THlive.",
        cont4: "1.4 Điều khoản sử dụng và thông báo của THlive được cập nhật thường xuyên, và công bố rông rãi. Khi bạn sử dụng các dịch vụ nào của THlive, bạn nên chú ý và tuân thủ các điều khoản liên quan áp dụng cho dịch vụ đó.",
        title2: "II. Thông tin đăng ký và bảo vệ quyền riêng tư",
        cont21: "2.1 Mọi tài khoản trên THlive thuộc quyền sở hữu của THlive,  bạn điền thông tin theo hướng dẫn của trang đăng ký, đọc và đồng ý với điều khoản sử dụng này, theo trình tự hoàn thành các thủ tục đăng ký, sở hữu tài khoản và trở thành người dùng của THlive. Bạn cần cung cấp chính xác, kịp thời thông tin cá nhân, liên tục cập nhật thông tin để đáp ứng yêu cầu kịp thời, chi tiết, chính xác. THlive không chịu trách nhiệm về các vấn đề liên quan do thông tin đăng ký không trung thực hoặc không cập nhật kịp thời. Bạn có thể sửa đổi thông tin cá nhân thông qua tài khoản trên THlive . Sử dụng thông tin cá nhân để xác thực tài khoản của mình, nếu ảnh đại diện, biệt hiệu hoặc các thông tin bất hợp pháp không được dùng để đăng ký. ",
        cont22: "Đồng thời sau khi đăng ký nếu bị phát hiện đăng ký tài khoản với thông tin sai lệch, biệt hiệu hoặc ảnh đại diện vi phạm pháp luật, THlive có quyền ngừng sử dụng, hủy đăng ký tài khoản mà không cần thông báo.",
        cont23: "2.2 Tài khoản THlive bao gồm tên đăng nhập và mật khẩu đăng nhập, bạn có thể cài đặt tên đăng nhập và mật khẩu đăng nhập; để bảo mật tài khoản của bạn chúng tôi có thể thực hiện các biện pháp xác minh khác nhau. Như khi tài khoản của bạn đăng nhập lần đầu tiên trên một thiết bị mới, chúng tôi có thể thông qua mật khẩu và mã xác minh để xác nhận danh tính của bạn, phương thức xác minh không giới hạn ở mã xác minh SMS.",
        cont24: "2.3 Bạn không được chuyển nhượng, hoặc cho người khác sử dụng tài khoản và mật khẩu của mình, nếu bạn chuyển nhượng cho người khác sử dụng thì phải chịu trách nhiệm về mọi hành động của người được ủy quyền sử dụng. Vì tài khoản được liên kết với thông tin cá nhân của người sử dụng, nên tài khoản chỉ có thể được chuyển nhượng nếu phù hợp pháp luật, hoặc được THlive chấp thuận, tuân thủ quy trình chuyển nhượng tài khoản do THlive quy định.",
        cont25: "2.4 Nếu thông tin tài khoản của bạn bị đánh mất vì lý do cá nhân, bạn có nhu cầu lấy lại thông tin tài khoản, vui lòng cung cấp thông tin tương ứng theo yêu cầu của THlive, đảm bảo thông tin cung cấp là hợp pháp, đúng sự thật. Nếu thông tin cung cấp không đáp ứng nhu cầu, bạn không thể thông qua xác minh của THlive, THlive có quyền từ chối cung cấp thông tin tài khoản.",
        cont26: "2.5 Nếu liên tục 6 tháng bạn không sử dụng tài khoản THlive, THlive có quyền hủy tài khoản của bạn, bạn không thể sử dụng tài khoản để đăng nhập hoặc sử dụng các dịch vụ của THlive.",
        cont27: "2.6 Tuyên bố về việc bảo hộ quyền riêng tư của THlive đã chỉ rõ cách THlive thu nhập và sử dụng thông tin người dùng. Bạn cần đảm bảo rằng trước khi sử dụng các sản phẩm và dịch vụ của THlive, đã hiểu đầy đủ và đồng ý rằng THlive có thể theo đó mà xử lý thông tin của người dùng.",
        cont28: "2.7 Khi bạn sử dụng sản phẩm THlive để đăng nhập cũng như các thao tác khác, máy chủ sẽ tự động nhận, xác minh và ghi lại một số thông tin cần thiết, chẳng hạn như số điện thoại di động, địa chỉ IP, thông tin ngoại lệ truy cập dịch vụ và một số thông tin thiết bị để đảm bảo độ an toàn đăng nhập tài khoản trong quá trình bạn sử dụng dịch vụ THlive để bảo vệ an ninh Internet của bạn. Việc sử dụng các dịch vụ của THlive, cũng cho thấy rằng bạn đồng ý THlive có thể xử lý thông tin cá nhân của bạn theo chính sách bảo mật quyền riêng tư có liên quan. THlive có thể làm việc với các đối tác để cung cấp cho bạn những dịch vụ mà bạn yêu cầu hoặc hiển thị cho bạn những nội dung mà bạn có thể quan tâm. Trong trường hợp thông tin cần thiết cho sản phẩm / dịch vụ, bạn đồng ý rằng THlive có thể chia sẻ thông tin đó. Ngoài ra, THlive sẽ yêu cầu nó đảm bảo an ninh dữ liệu và nghiêm cấm việc sử dụng nó cho bất kỳ mục đích nào khác. THlive sẽ không cung cấp hoặc chia sẻ thông tin cho bất kỳ bên thứ ba không liên quan nào.",
        cont29: "2.8 Bạn hiểu và cho phép THlive sử dụng hoặc đồng bộ hóa thông tin của bạn với các công ty liên kết để cung cấp cho bạn những dịch vụ liên quan đến tín dụng.",
        cont220: "2.9 Nhằm cung cấp dịch vụ tốt nhất cho bạn, THlive sẽ thông qua hình thức SMS gửi đến bạn những thông tin dịch vụ thương mại có liên quan.",
        title3: "III. Quy tắc sử dụng",
        cont31: "3.1 Khi sử dụng các dịch vụ của THlive, bạn phải tuân thủ các quy định của nhà nước CHXHCN Việt Nam về an ninh mạng, quy định quản lý dịch vụ thông tin internet. Bạn không được sử dụng dịch vụ này cho bất kỳ các hoạt động bất hợp pháp nào, như các hành vi sau:",
        cont32: "3.1.1 Tải lên, đăng, tuyên truyền thông tin có chứa một trong những nội dung sau:",
        cont33: "-  Phản đối các nguyên tắc cơ bản do hiến pháp quy định.",
        cont34: "-  Gây nguy hiểm đến an ninh quốc gia, lộ bí mật nhà nước, chống phá nhà nước và đoàn kết dân tộc.",
        cont35: "-  Ảnh hưởng đến danh dự và lợi ích của quốc gia",
        cont36: "-  Kích động hận thù dân tộc, kỳ thị sắc tộc, phá hoại đoàn kết dân tộc.",
        cont37: "-  Phá hoại chính sách tôn giáo của chính phủ, tuyên truyền cổ súy cho tà giáo và hủ tục mê tín lạc hậu.",
        cont38: "-  Phát tán tin đồn, gây dối trật tự xã hội, phá hoại sự ổn định của xã hội.",
        cont39: "-  Truyền bá nội dung tục tĩu, khiêu dâm, cờ bạc, bạo lực, giết người, khủng bố, xúi giục tội phạm.",
        cont320: "-  Xúc phạm hoặc vu khống người khác, xâm phạm các quyền hợp pháp của người khác.",
        cont321: "-  Chứa nội dung sai lệch, đe dọa, xâm phạm quyền riêng tư của người khác, hoặc các nội dung vi phạm đạo đức khác.",
        cont322: "-  Chứa các nội dung bị hạn chế hoặc bị cấm bởi luật, quy định, quy tắc của nhà nước Việt Nam.",
        cont323: "3.1.2 Không được sử dụng hệ thống dịch vụ mạng cho bất kỳ mục đích bất hợp pháp nào.",
        cont324: "3.1.3 Không lợi dụng THlive để tham gia các hoạt động sau:",
        cont325: "-  Vào mạng thông tin máy tính hoặc sử dụng tài nguyên thông tin máy tính mà không được cho phép.",
        cont326: "-  Xóa, sửa đổi hoặc thêm các chức năng mạng thông tin máy tính khi chưa được cho phép.",
        cont327: "-  Xóa, sửa đổi, hoặc thêm các dữ liệu , ứng dụng vào mạng thông tin máy tính khi chưa được cho phép.",
        cont328: "-  Cố ý tạo ra, phát tán vi rút máy tính, phá hoại các chương trình khác",
        cont329: "-  Các hành vi khác gây nguy hiểm cho an ninh mạng máy tính.",
        cont330: "3.2 Nếu bạn vi phạm điều khoản sử dụng này hoặc các quy định điều khoản dịch vụ có liên quan, gây ra tổn thất, khiếu nại từ bên thứ 3 nào, bạn phải bồi thường phí tổn thất cho THlive, các công ty đối tác và công ty liên kết của THlive. Về vấn đề này, THlive có quyền tùy theo bản chất hành vi của bạn, thực hiện các biện pháp bao gồm nhưng không giới hạn ở việc xóa thông tin đã đăng của bạn, tạm ngừng sử dụng , chấm dứt dịch vụ, hạn chế sử dụng, thu hồi tài khoản của THlive và điều tra các trách nhiệm pháp lý. Đối với việc cố ý đăng ký tài khoản THlive, lợi dụng tài khoản THlive để thực hiện các hoạt động bất hợp pháp, gây rối, lừa bip người dùng khác và các hành vi vi phạm điều khoản sử dụng này, THlive có quyền thu hồi tài khoản . Đồng thời, THlive sẽ hỗ trợ điều tra theo yêu cầu của bộ tư pháp.",
        cont331: "3.3. Bạn không được sao chép, bán, chuyển nhượng bất kỳ bộ phận nào của dịch vụ này cho bất kỳ mục đích thương mại nào khác.",
        cont332: "3.4 Bạn phải chịu trách nhiệm pháp lý về các hành động của mình trong quá trình sử dụng THlive. Các hình thức để bạn chịu trách nhiệm pháp lý bao gồm nhưng không giới hạn ở việc bồi thường cho người bị xâm hại, sau khi THlive chịu phạt hành chính do hành vi của bạn gây ra, bạn phải bồi thường cho THlive số tiền tương ứng.",
        cont333: "3.5 Bạn cần tuân thủ các điểm mẫu chốt về internet dưới đây khi sử dụng dịch vụ của THlive:",
        cont334: "3.5.1 Luật và quy định",
        cont335: "3.5.2 Hệ thống xã hội chủ nghĩa",
        cont336: "3.5.3 Lợi ích quốc gia",
        cont337: "3.5.4 Quyền và lợi ích hợp pháp của công dân",
        cont338: "3.5.5 Trật tự công cộng xã hội",
        cont339: "3.5.6 Nếp sống đạo đức",
        cont340: "3.5.7 Tính xác thực thông tin",
        title4: "IV. Nội dung dịch vụ",
        cont41: "4.1 Các nội dung dịch vụ của THlive, do THlive cung cấp dựa vào tình hình thực tế.",
        cont42: "4.2 Trừ khi có quy định rõ ràng khác trong điều khoản sử dụng dịch vụ này, các sản phẩm mới, chức năng mới và dịch vụ mới do THlive đưa ra đều phải tuân theo các chuẩn mực trong điều khoản sử dụng dịch vụ này.",
        cont43: "4.3 Để sử dụng dịch vụ này, bạn phải tự mình truy cập Internet thông qua một bên thứ ba đủ điều kiện pháp lý để cung cấp cho bạn các dịch vụ truy cập Internet và tự thanh toán các phí dịch vụ liên quan. Ngoài ra, bạn phải tự chuẩn bị và chịu trách nhiệm về tất cả các thiết bị cần thiết để kết nối mạng quốc tế, bao gồm máy tính, modem hoặc các thiết bị truy cập khác.",
        cont44: "4.4 Xét theo đặc thù của các dịch vụ mạng, bạn đồng ý rằng THlive có quyền thay đổi, gián đoạn hoặc chấm dứt một số hoặc tất cả các dịch vụ mạng (bao gồm cả các dịch vụ mạng trả phí) bất kỳ lúc nào mà không cần thông báo trước. THlive không đảm bảo rằng các dịch vụ mạng sẽ không bị gián đoạn, cũng như không đảm bảo tính kịp thời, an toàn và chính xác của các dịch vụ mạng.",
        cont45: "4.5 THlive không chịu trách nhiệm nào về việc dịch vụ mạng bị gián đoạn trong các trường hợp sau:",
        cont46: "4.5.1 THlive kiểm tra, sửa chữa, bảo trì các thiết bị, dịch vụ mạng theo định kỳ hoặc không theo định kỳ. THlive có quyền tạm dừng bất kỳ bộ phận nào của dịch vụ để bảo trì, nâng cấp, hoặc mục đích khác mà không cần thông báo trước.",
        cont47: "4.5.2 Các trường hợp bất khả kháng như: bão, động đất, lũ lụt...4.5.2 Các trường hợp bất khả kháng như: bão, động đất, lũ lụt...",
        cont48: "4.5.3 Phần mềm, phần cứng máy tính của người dùng, đường truyền tín hiệu xảy ra lỗi.",
        cont49: "4.5.4 Sự tấn công của virut và các chương trình độc hại, tắc nghẽn mạng, hệ thống không ổn định, hệ thống hoặc thiết bị bị lỗi, lỗi từ phía ngân hàng hoặc bên thứ ba. THlive sẽ thực hiện các hành động hợp lý để thúc đẩy hoạt động dịch vụ trở lại bình thường.",
        cont420: "4.5.5 Dịch vụ THlive hoặc bên thứ ba có thể cung cấp liên kết đến các trang web hoặc tài nguyên khác trên Internet quốc tế. Vì THlive không có quyền kiểm soát các trang web và tài nguyên này, bạn hiểu và đồng ý rằng THlive không chịu trách nhiệm về việc các trang web hoặc tài nguyên đó có sẵn để sử dụng hay không; THlive cũng không đảm bảo hoặc chịu trách nhiệm về bất kỳ nội dung, quảng cáo, sản phẩm hoặc thông tin nào khác tồn tại hoặc bắt nguồn từ các trang web hoặc tài nguyên đó. THlive không chịu bất cứ trách nhiệm nào về bất kỳ thiệt hại, tổn thất nào phát sinh từ việc sử dụng nội dung, hàng hóa hoặc dịch vụ nào xuất hiện hoặc có được thông qua các trang web hoặc tài nguyên đó.",
        cont421: "4.6 Bạn đồng ý rằng những rủi ro tồn tại trong việc sử dụng dịch vụ mạng THlive do bạn hoàn toàn tự chịu trách nhiệm. Bạn hiểu và chấp nhận rằng bất kỳ thông tin nào được tải xuống hoặc lấy thông qua dịch vụ THlive đều phụ thuộc vào bạn và bạn có khả năng sẽ chịu thiệt hại về hệ thống, mất mát dữ liệu hoặc bất kỳ rủi ro nào khác. THlive không đảm bảo bất kỳ dịch vụ mua sắm , tiến độ giao dịch và thông tin tuyển dụng nào có được trên mạng dịch vụ THlive.",
        cont422: "4.7 Lưu ý : Trong số các dịch vụ thúc đẩy khai thác do THlive cung cấp, các liên kết đến các trang web hoặc tài nguyên mà người dùng đã truy cập sẽ tự động được khởi chạy dựa trên các thuật toán của máy tính. THlive không đưa ra bất cứ đảm bảo nào về sự hiệu quả, an toàn và hợp lệ về mặt nội dung.",
        cont423: "4.8 THlive có quyền chấm dứt tạm thời hoặc vĩnh viễn dịch vụ này ( hoặc bất kỳ bộ phận nào), bất kể có thông báo hay không, THlive không chịu trách nhiệm với người dùng hoặc bất kỳ bên thứ ba nào.",
        cont424: "4.9 Chấm dứt dịch vụ",
        cont425: "Bạn đồng ý rằng THlive Live sẽ thu hồi mật khẩu và tài khoản của bạn hoặc tạm dừng việc sử dụng dịch vụ (hoặc bất cứ bộ phận nào của dịch vụ) dựa trên những cân nhắc riêng của mình vì bất kỳ lý do gì, bao gồm nhưng không giới hạn ở việc bạn không sử dụng tài khoản lâu dài (hơn một năm), hoặc THlive cho rằng bạn đã vi phạm tinh thần của điều khoản sử dụng dịch vụ này . Bạn cũng đồng ý rằng dịch vụ được cung cấp theo bất kỳ điều khoản nào của điều khoản sử dụng dịch vụ này có thể bị gián đoạn hoặc chấm dứt mà không cần thông báo trước. Bạn xác nhận cũng như đồng ý rằng THlive có thể ngay lập tức đóng hoặc xóa tài khoản của bạn cũng như tất cả thông tin và tập tin liên quan trong tài khoản của bạn, hoặc cấm tiếp tục sử dụng các tệp tin nói trên trên dịch vụ này. Ngoài ra, bạn cũng đồng ý rằng nếu việc sử dụng dịch vụ này bị gián đoạn, chấm dứt hoặc tài khoản và các thông tin và tập tin liên quan bị đóng hoặc bị xóa, THlive Live sẽ không chịu trách nhiệm với bạn hoặc bất kỳ bên thứ ba nào.",
        title5: "V. Quyền sở hữu trí tuệ và các quyền hợp pháp khác",
        cont51: "5.1 Quyền độc quyền của người dùng",
        cont52: "THlive tôn trọng quyền sở hữu trí tuệ và các quyền hợp pháp của người khác, đồng thời kêu gọi bạn cũng tôn trọng quyền sở hữu trí tuệ và quyền hợp pháp của người khác. Nếu bạn cho rằng quyền sở hữu trí tuệ và quyền hợp pháp của bạn bị vi phạm , vui lòng làm theo hướng dẫn bên dưới để cung cấp thông tin cho THlive",
        cont53: "Xin lưu ý: Nếu điều bạn trình bày là không đúng sự thật, bạn phải chịu mọi trách nhiệm pháp lý ( bao gồm nhưng không giới hạn ở việc đền bù các chi phí và phí luật sư). Nếu bạn không chắc chắn các thông tin trên internet có vi phạm quyền sở hữu trí tuệ và các quyền hợp pháp khác của mình hay không, THlive khuyến nghị bạn trước tiên nên tham khảo ý kiến chuyên gia.",
        cont54: "Để THlive xử lý hiệu quả thông tin bảo vệ quyền lợi nêu trên của bạn, vui lòng sử dụng quy cách sau:",
        cont55: "5.1.1 Cung cấp bằng chứng về nội dung vi phạm quyền sở hữu trí tuệ và các quyền hợp pháp khác theo quy định của pháp luật.",
        cont56: "5.1.2 Vui lòng mô tả đầy đủ, rõ ràng hành vi xâm phạm quyền sở hữu trí tuệ hoặc các quyền và lợi ích hợp pháp khác, đồng thời vui lòng cung cấp trang web (nếu có) bị nghi ngờ vi phạm.",
        cont57: "5.1.3 Vui lòng cho biết nội dung nào của trang web bị cáo buộc vi phạm các quyền được liệt kê trong mục 2.",
        cont58: "5.1.4 Vui lòng cung cấp vị trí của nội dung bị cáo buộc vi phạm trên mạng thông tin (ví dụ: ghi rõ nguồn nội dung vi phạm mà bạn đã báo cáo, đó là: địa chỉ trang web hoặc vị trí trong trang web) để chúng tôi có thể liên hệ với chủ sở hữu /quản trị viên của trang web mà bạn đã báo cáo chứa nội dung vi phạm.",
        cont59: "-  Đối với bất kỳ nội dung nào mà bạn tải lên trên trang web phát trực tiếp THlive thông qua dịch vụ phát trực tiếp THlive, bạn đồng ý rằng các chương trình phát trực tiếp THlive là miễn phí, vĩnh viễn, không thể thu hồi, không độc quyền và được sao chép hoàn toàn trên toàn thế giới. Cho phép sử dụng, sao chép, sửa đổi, phóng tác, xuất bản, dịch, tạo các tác phẩm truyền tải, biểu diễn và hiển thị nội dung đó (toàn bộ hoặc một phần), hoặc biên dịch nội dung đó thành nội dung hiện có được biết đến hoặc phát triển sau này trong bất kỳ hình thức công việc, phương tiện hoặc công nghệ nào khác.",
        cont520: "-  THlive sở hữu bản quyền của tất cả các tài liệu trên trang web này.  Mọi hoạt động duyệt, sao chép, in ấn và phổ biến tài liệu thuộc trang web này phải được phép và đáp ứng các điều kiện sau:",
        cont521: "Tất cả các tài liệu và hình ảnh nhằm mục đích thu thập thông tin; ",
        cont522: "Tất cả các tài liệu và hình ảnh sẽ không được sử dụng cho mục đích thương mại;",
        cont523: "Tất cả các tài liệu, hình ảnh và bất kỳ bộ phận nào phải bao gồm thông báo bản quyền này;",
        cont524: "Tất cả các sản phẩm, công nghệ và chương trình trên trang web này thuộc quyền sở hữu trí tuệ của THlive. 'THlive Live' và các đồ họa liên quan là thương hiệu đã đăng ký của THlive Live.",
        title6: "VI. Thanh thiếu niên đặc biệt lưu ý",
        cont61: "Thanh thiếu niên phải tuân thủ các công ước văn minh mạng quốc gia, hoàn thành tốt việc học tập trên mạng, không xem các thông tin xấu, giao tiếp với bạn bè tốt, không xúc phạm lừa dối người khác, có ý thức tự bảo về bản thân, không tùy ý hẹn hò với bạn bè trên mạng, bảo vệ an ninh mạng, giữ gìn sức khỏe, không mê đắm vào thời gian và không gian ảo.",
        title7: "VII. Những điều khoản khác",
        cont71: "7.1 Luật pháp của CHXHCN Việt Nam sẽ được áp dụng để ký kết, thực hiện và giải thích điều khoản sử dụng này và giải quyết tranh chấp.",
        cont72: "7.2 Nếu có bất kỳ tranh chấp nào giữa hai bên về nội dung của điều khoản sử dụng này hoặc việc thực hiện điều khoản sử dụng này, hai bên sẽ cố gắng hết sức để giải quyết thông qua thương lượng hữu nghị.",
        cont73: "7.3 Việc THlive không thực hiện hoặc thực hiện các quyền lợi và quy định trong điều khoản sử dụng này không cấu thành sự từ bỏ những quyền lợi đã nêu ở trên.",
        cont74: "7.4 Nếu bất kỳ điều khoản nào trong thỏa thuận này không có giá trị hoàn toàn hoặc một phần hoặc không thể thi hành vì bất kỳ lý do gì, thì các điều khoản còn lại trong thỏa thuận này vẫn có giá trị và ràng buộc.",
        cont75: "7.5 Hoan nghênh bạn sử dụng dịch vụ phát trực tiếp THlive (dưới đây gọi là 'dịch vụ đơn lẻ') trên cơ sở điều khoản sử dụng này, đồng thời tuân theo các quy định cụ thể của thỏa thuận dịch vụ cá nhân.",
        cont76: "Vui lòng thông báo cho THlive khi bạn phát hiện bất kỳ vi phạm nào đối với điều khoản sử dụng này và các điều khoản dịch vụ của bất kỳ dịch vụ cá nhân nào khác cũng như các thông báo khác của THlive.  Bạn có thể liên hệ với chương trình phát trực tiếp THlive thông qua phương thức liên hệ sau: Phản hồi ý kiến về chương trình phát trực tiếp THlive.",
    },
    liveRoom: {
        blocked: "已拉黑",
        blockedTips: "拉出",
        pullBlack: "拉黑",
        pullBlackTips: "拉黑成功",
        audience: "观众",
        contribute: "贡献",
        previewCountdown: "预览倒计时",
        wellcome: "欢迎来到",
        liveRoom: "的直播间",
        anchorLeave: "主播暂时离开了",
        anchorComeBack: "主播回来了",
        mysteryMan: "神秘人",
        focusOnTips: "对主播一见钟情，已关注主播",
        honorable: "尊贵的大驾光临, 欢迎",
        wellcomeN: "来了,欢迎",
        sendOut: "送出",
        noTalking: "您被禁言了",
        talking: "已解禁，您可以发言了",
        kickOutLiveRoom: "已被踢出本直播间",
        system: "系统",
        winLottery: "中奖",
        isHousingManagement: "恭喜",
        isHousingManagement2: "被任命为房管",
        noHousingManagement: "很遗憾，",
        noHousingManagement2: "您被取消了房管",
        betMsgIn: "在",
        betMsgBet: "投注了",
        followUp: "跟投",
        winLotteryMsg: "中奖了",
        anchorUpgradeTips: "恭喜主播升级到",
        userUpgradeTips: "恭喜您升级到",
        shortBalanceTips: "余额不足，请充值",
        inProgress: "进行中",
        userEnterLiveTips: "已进入直播间",
        setChips: "设置筹码",
        result: "结果",
        passwordRoom: "房间密码",
        entryPswTips: "输入密码",
        lotteryRecord: "开奖记录",
        endLive: "直播已结束",
        kickOutLiveRoomTips: "您已被踢出直播间",
        goHome: "回到首页",
        recommended: "推荐榜单",
        payTips: "确认支付",
        payTips1: "支付",
        payTips11: "金币继续看直播",
        payTips2: "支付",
        payTips21: "金币/分钟继续看直播",
        jumpOver: "跳过",
        toPay: "支付",
        giftList: "贡献榜",
        today: "今日",
        month: "月",
        hasSend: "已送",
        received: "已收",
        homePage: "主页",
        qHer: "她",
        saySomeThing: "说点什么",
        newMsg: "条新消息",
        silence: "禁言",
        silenceSecc: "禁言成功",
        cancelSilence: "取消禁言",
        cancelSilenceSecc: "解除禁言成功",
        kickOutLive: "踢人",
        kickOutLiveSecc: "踢人成功",
        changeRoom: "切换房间",
        manage: "管理",
        disabledNum: "封号",
        disabledNumSecc: "封号成功",
        disabledAir: "封机",
        disabledAirSecc: "封机成功",
    },
    common: {
        finish: "完成",
        cancel: "取消",
        confirm: "确认",
        more: "更多",
        getCode: "获取验证码",
        getCodeSuccess: "获取验证码成功",
        nextStep: "继续",
        refreshSuccess: "刷新成功",
        refreshFailed: "刷新失败！",
        eidt: "修改",
        submit: "提交",
        submitSuccess: "提交成功",
        hint: "提示",
        copy: "复制",
        copySuccess: "复制成功！",
        copyLost: "复制失败！",
        eidtSuccess: "修改成功！",
        fullAmount: "全部金额", 
        noData: "暂无数据",
        inDevelop: "开发中,敬请期待~",
        kindTips: "温馨提示",
        completeInformation: "完善信息",
        sure: "确定",
        reset: "重置",
        all: "全部"
    },
}