import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import { getBetHistorByUid, lotteryBet } from "@/api/index";
import rechangeEntry from "@/pages/mixin/rechangeEntry";
import md5 from 'js-md5';
import utils from '@/utils/index';
const lotteryData = {
    mixins: [rechangeEntry],
    data() {
        return {
            bar: [],
            active: 0,
            showHall: false,
            betType: 0,
            betNum: [1, 2, 5, 10, 20],
            isShowWeight: false,
            showLotteryDropMenu: false,
            history: [],
            activeBet: [],
            customize: false,
            weightListActive: 0,//砝码随机选择
            customizeValue: "",
            // 投注确认
            isBetList: false,
            recordList: [], //投注记录
            isShowRule: false, //玩法说明弹框是否展示
            isLotteryBetting: false, //下注回调中

            page: 0, //页码
            loading: false, //下拉加载更多加载中动画
            check: false, //是否开始就检查
            finished: true, //是否已加载完
            isShowRecord: false,

            second: 0, // 倒计时时间
            timer: null, //倒计时容器
        }
    },
    computed: {
        ...mapState(['member', 'isLoginTm', 'issue', 'entry', 'weightList', 'weightListConfirm', 'customizeValueConfirm', 'countTime']),
        ...mapGetters(['isLogin']),
        leftMenu() {
            return this.$store.state.tmModule.leftMenu;
        },
        betNumActive() {
            return this.$store.state.multiple;
        },
        configMen() {
            let index = this.leftMenu.findIndex(item => {
                return item.name.toLowerCase() == this.lotteryCode.toLowerCase()
            });

            if (index != -1) {
                return this.leftMenu[index];
            } else {
                return {};
            }
        },
        rule() {
            let index = this.leftMenu.findIndex(item => {
                return item.name.toLowerCase() == this.lotteryCode.toLowerCase()
            });

            if (index != -1) {
                return this.leftMenu[index].playMethod.replace(/\n/g,'<br/>').replace(/font-size: 24pt;/g,'font-size: 0.4rem;').replace(/font-size: 36pt;/g,'font-size: 0.5rem;');
            } else {
                return "";
            }
        },
        lastResult() {
            let lastResult = {};
            if (this[`${this.lotteryCode}History`].length > 0) {
                lastResult = this[`${this.lotteryCode}History`][0];
            }
            return lastResult;
        }
    },
    watch: {
        countTime(val) {
            if (val > 0) {
                this.goDown(val-1);
            }
        }
    },
    created() {
        this.goDown(this.countTime);
        this.getLotteryPower();
        this.$store.dispatch("initHistory", this.lotteryCode);
        this.$store.dispatch("getissue", this.lotteryCode);
        this.$store.dispatch("getLeftMenu");
        if (this.configMen.roomId) {
            if (this.$store.state.tim.getGroupList) {
                this.$store.dispatch("getGroupList", String(this.configMen.roomId));
            } else {
                setTimeout(() => {
                    this.$store.dispatch("getGroupList", String(this.configMen.roomId));
                }, 2000);
            }
        }
    },
    mounted() {
        // if (sessionStorage.getItem('showRecord') && sessionStorage.getItem('showRecord') == 'true') {
        //     let betPramas = JSON.parse(JSON.stringify(this.betPramas));
        //     this.getLotteryResultHistory();
        // }
    },
    methods: {
        goDeposit(val) {
            this.goRechangeComfirm(`/mine/finance/myDeposit?lottery=${val}`);
        },
        // 倒计时
        goDown(time) {
            clearInterval(this.timer);
            this.second = time;
            this.$store.commit("setState", { countTime: 0 });
            if (time > 0) {
                this.timer = setInterval(() => {
                    if (time > 0) {
                      time --;
                    }
                    this.second = time;
                }, 1000);
            }
        },
        // 刷新钱包
        async refreshWallet() {
            this.$store.dispatch('refreshUserInfo', true);
        },
        goBack() {
            this.$router.push(this.entry);
        },
         // 关闭投注记录面板
        colseRecord() {
            sessionStorage.setItem('showRecord', false);
            this.isShowRecord = false;
        },
        // 时间格式转换
        getTime(val) {
            if (val) {
                return moment(val).format('HH:mm DD-MM-YYYY');
            }
            return "";
        },
        // 跳转订单详情
        goDetail(item) {
            sessionStorage.setItem('orderDetail', JSON.stringify(item));
            this.goOtherPage(`/lottery/orderDetail/${item.lotteryName}`)
        },
        // 是否选中
        getActive(val) {
            let index = this.activeBet.findIndex(item => {
                return item.playName == val;
            });
            if (index != -1) {
                return true;
            }
            return false;
        },
        // 点击投注记录按钮
        getLotteryResult() {
            this.page = 0;
            this.getLotteryResultHistory();
        },
        // 加载更多
        onLoad() {
            if (!this.finished) {
                this.loading = true;
                this.page ++;
                this.getLotteryResultHistory();
            }
        },
        onBeforeClose(action) {
            // new Promise((resolve) => {
            //     resolve(false);
            // });
            new Promise((resolve) => {
                if (action === 'confirm') {
                    resolve(false);
                } else {
                    // 拦截取消操作
                    // resolve(false);
                    this.customize = false; 
                }
            });
        },
        // 实例弹窗确认
        handleConfirm() {
            if (!this.customizeValue) return this.$toast(this.$t('lottery.entryNumberTips'));
            if (String(this.customizeValue) && (parseFloat(this.customizeValue) < 10 || parseFloat(this.customizeValue) > 20000)) return this.$toast(this.$t('lottery.entryNumberTips')) ;
            if (String(this.customizeValue) && (parseFloat(this.customizeValue) >= 10 || parseFloat(this.customizeValue) <= 20000)) {
                this.weightListActive = this.weightList.length -1;
            }
            this.customize = false;
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        changeTab(item) {
            console.log(item);
        },
        menuItem(item) {
            if (item.link == this.$route.path) {
                return ;
            }
            this.goOtherPage(item.link);
        },
        // 投注按钮逻辑
        showWeight() {
            this.isShowWeight = true;
            this.weightListActive = this.weightListConfirm;
            if (this.weightListConfirm == (this.weightList.length - 1)) {
                this.customizeValue = this.customizeValueConfirm;
            }
        },
        // 筹码弹框确认逻辑
        confirmWeight() {
            let customizeValueConfirm = '';
            if (this.weightListActive == (this.weightList.length - 1)) {
                customizeValueConfirm = this.customizeValue;
            }
            this.$store.commit("setState", { weightListConfirm: this.weightListActive, customizeValueConfirm });
            this.isShowWeight = false;
        },
        betInfo(item) {
            let xIndex = this.activeBet.findIndex(el => {
                return el.playName == item.playName
            });

            if (xIndex == -1) {
                this.activeBet.push(item);
            } else {
                this.activeBet.splice(xIndex, 1);
            }
        },
        betNumClick(item) {
            this.$store.commit("setState", { multiple: item });
        },
        // 删除投注项
        delChoose(index) {
            if (this.activeBet.length == 1) {
                this.$dialog.confirm({
                    message: this.$t('lottery.sureClearBet'),
                    confirmButtonText: this.$t('common.confirm'),
                    cancelButtonText: this.$t('common.cancel'),
                    className: "confirmDialog",
                    confirmButtonColor: "#eb457e"
                }).then(() => {
                    this.activeBet.splice(index, 1);
                    if (this.activeBet.length == 0) {
                        this.isBetList = false;
                    }
                }).catch(() => {
                    // console.log("取消");
                });
            } else {
                this.activeBet.splice(index, 1);
            }
        },
        // 筹码选中逻辑
        weightListClick(item, index) {
            if (item == 'customize') {
                this.customize = true;
            } else {
                this.customizeValue = "";
                this.weightListActive = index;
            }
        },
        goBetInfo() {
            if (this.activeBet.length == 0) {
                return;
            }

            this.isBetList = true;
            this.isLotteryBetting = false;
        },
        confirmBet() {
            this.lotteryBet();
            this.isBetList = false;
        },
        // 下注
        lotteryBet() {
            if (this.isLotteryBetting) return;
            let uid = localStorage.getItem('uid') || utils.guid(),
            params = {
                isHemai: 0,  // 写死
                isStop: 0, // 写死
                liveId: 211,  // 直播房间id 临时写死
                expect: [
                    {
                        expect: this.issue.expect, //期号
                        multiple: 1 // 写死
                    }
                ],
                lotteryName: this.lotteryCode,
                times: this.betNumActive, //倍数
                uid: this.member.uid,
                betFrom: "game",
                // language: 'THAI',
                os: 0,
                sign: md5(`${uid}jgyh,kasd${new Date().getTime()}`),
                timestamp: new Date().getTime(),
                udid: localStorage.getItem('uid') || utils.guid(),
            }
            let arr = [];
            this.activeBet.forEach(item => {
                arr.push({
                    money: this.weightListConfirm == (this.weightList.length -1) && this.customizeValueConfirm ? this.customizeValueConfirm : this.weightList[this.weightListConfirm], //金额
                    notes: 1, //写死
                    rebate: 0,  //写死
                    type: item.type,
                    type_text: item.lotteryType,
                    num: item.method
                });
            });
            params.playNum = JSON.parse(JSON.stringify(arr));
            params.betAmount = arr[0].money * this.betNumActive;
            params.betCount = params.playNum.length;
            this.isLotteryBetting = true;
            lotteryBet(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.$toast(this.$t('lottery.betSuccess'));
                    this.$store.dispatch('refreshUserInfo');
                    this.activeBet = [];
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.isLotteryBetting = false;
            }).catch(err => {
                console.log(err);
                this.isLotteryBetting = false;
            });
        },
        // 获取投注记录
        getLotteryResultHistory() {
            let params = {
                page: this.page,
                pageSize: 10,
                lotteryName: this.lotteryCode,
                uid: this.member.uid
            }

            if (!this.page) {
                this.recordList = [];
            }

            this.finished = true;
            getBetHistorByUid(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (!params.page) {
                        this.recordList = res.data;
                    } else {
                        if (res.data.length > 0) {
                            let arr = JSON.parse(JSON.stringify(this.recordList));
                            arr = [...arr, ...res.data];
                            this.recordList = arr;
                        }
                    }

                    if (res.data.length == 10) {
                        this.finished = false;
                    }

                    this.isShowRecord = true;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                console.log(err);
            });
        },
        
    }
}
export default lotteryData;