<template>
  <div>
    <van-popup v-model:show="isShowDownLoadApp" position="bottom" @close="close">
        <div class="downloadBoxBg">
            <!-- <img class="downloadBoxBgImg" @click="close" v-real-img="liveInfo.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)"> -->
            <div class="downloadBox">
                <img src="../../assets/img/home/downLoadApp.png">
                <div>{{ $t('home.downLoadContinue') }}</div>
                <div @click="downLoad" class="confirmBtn hasValue">{{ $t('home.downLoadApp') }}</div>
            </div>
        </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "downloadAppF",
    data() {
        return {
            isInitSuccess: false
        }
    },
    computed: {
        ...mapState(['isShowDownLoadApp', 'baseInfo', 'leftMenu', 'fromPath']),
    },
    methods: {
        close() {
            this.$store.commit("setState", { isShowDownLoadApp: false });
        },
        downLoad() {
            if(ShareTrace.download){
                ShareTrace.download();
            }else{
                window.location.replace(window.location.href);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.downloadBox {
    font-size: 0.34rem;
    // position: absolute;
    // left: 0;
    // bottom: 0;
    background: #fff;
    width: 100vw;

    img {
        width: 2.68rem;
        padding: 0.2rem 0;
    }

    .confirmBtn {
        margin: 0.56rem auto 0.24rem;
        width: 5rem;
    }
}
</style>