<template>
    <div class="next memberDepositRecord">
        <div class="title">
            <span v-show="type=='deposit'"> {{$t('my.proxy.memberDepositRecord')}} </span>
            <span v-show="type!='deposit'"> {{$t('my.proxy.memberWithdrawalRecord')}} </span>
            <div class="fixed" @click="gaBack">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="exchangeData">
            <div class="tableItem tableHead flexBetween">
                <div>{{$t('my.proxy.memberID')}}</div>
                <div>{{type =='deposit' ? $t('my.finance.depositAmount') : $t('my.finance.withdrawalAmount')}}</div>
                <div>{{$t('my.invite.time')}}</div>
            </div>
            <div v-if="tableList.length > 0">
                <div v-for="(item, index) in tableList" :key="`${index}--tableList`" >
                    <div class="tableItem flexBetween">
                        <!-- class="blue" @click="toDetail(item)" -->
                        <div>{{item.uid}}</div>
                        <div>
                            <p>{{item.goldCoin}}</p>
                        </div>
                        <div>
                            <span>{{getTime(item.gmtCreate)}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="noData">
                <img src="../../../assets/img/record/noData.png"/>
                <p>{{$t('common.noData')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { forProxy } from "@/api/proxy";
import moment from "moment";
import { mapState } from 'vuex';
export default {
    data() {
        return {
            tableList: [],
            type: ""
        }
    },
    created() {
        this.type = this.$route.params.type;
        this.forProxy();
    },
    computed: {
        ...mapState(['betPramas']),
    },
    methods: {
        getTime(val) {
            if (val) {
                return moment(val).format('YYYY-MM-DD');
            }

            return "";
        },
        gaBack() {
            let url = `/mine/myProxy/myMember/info?id=${this.$route.query.id}`;
            if (this.betPramas.type) {
                url = url + `&type=${this.betPramas.type}`
            }
            this.$router.push(url);
        },
        // 点击会员id 跳转详情
        toDetail(item) {
            let url = `/mine/myProxy/myMember/info?id=${item.uid}`;
            if (this.betPramas.type) {
                url = url + `&type=${this.betPramas.type}`
            }
            this.$router.push(url);
        },
        // 列表数据
        forProxy() {
            let params = {
                belowUid: this.$route.query.id,
                page: 0
            }

            if (this.$route.params.type == 'deposit') {
                params.type = 1;
            } else {
                params.type = 2;
            }
            forProxy(params).then(res => {
                if (res.code == 0) {
                    this.tableList = res.data.centerUserAssetsPlusVOS;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.memberDepositRecord {
    padding-top: 0.88rem;

    .title {
        box-shadow: 0 0 0 0 rgb(0, 0, 0, 0);
    }

    .title {
        height: auto;
    }

    .exchangeData {
        .tableItem {
            background-color: #fff;
            border-bottom: 1px solid #EBEFF1;
            padding: 0 0.28rem;
            height: 0.88rem;
            color: #626872;

            &.tableHead {
                color: #333;
            }

            &:nth-child(even) {
                background-color: #F9F9F9;
            }

            &:first-child {
                border-bottom: none;
            }

            div {
                flex: 1;
                text-align: left;
                font-size: 0.24rem;

                &:nth-child(1) {
                    font-size: 0.28rem;
                }
            }
        }

        .blue {
            color: #eb457e;
        }
    }
}
</style>