<template>
    <div>
        <van-popup
            v-model:show="isShowTopList"
            position="left"
            :style="{ width: '100%', height: '100%' }"
            >
            <div class="next topListBox">
                <div class="title">
                    <span> {{ $t('liveRoom.giftList') }} </span>
                    <div class="fixed" @click="close">
                        <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem">
                            <path
                                d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                                fill="#666666" p-id="12457"></path>
                        </svg>
                    </div>
                </div>

                <div class="timeOptions flexCenterBL">
                    <div @click="topActive=0" :class="{'active': topActive==0}">{{ $t('liveRoom.today') }}</div>
                    <div  @click="topActive=1"  :class="{'active': topActive==1}">{{ $t('liveRoom.month') }}</div>
                </div>

                <div class="topBox">
                    <div class="topSecond topSortItem">
                        <div class="topBg"></div>
                        <div @click="itemClick(second)" class="topSortItemCont">
                            <img v-if="second.uid && !second.rankHidden" v-real-img="second.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                            <img v-if="second.uid && second.rankHidden" src="../../assets/img/live/mysteryMan.png"/>
                            <img v-if="!second.uid" src="../../assets/img/errorImg/2.png"/>
                            <div>{{ getName(second) }}</div>
                            <div class="topSortItemContNum">{{ second.uid ? second.rankValue : 0 }}</div>
                            <div>{{ $t('liveRoom.contribute') }}</div>
                        </div>
                    </div>
                    <div class="topFirst topSortItem">
                        <div class="topBg"></div>
                        <div @click="itemClick(first)" class="topSortItemCont">
                            <img v-if="first.uid && !first.rankHidden" v-real-img="first.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                            <img v-if="first.uid && first.rankHidden" src="../../assets/img/live/mysteryMan.png"/>
                            <img v-if="!first.uid" src="../../assets/img/errorImg/2.png"/>
                            <div>{{ getName(first) }}</div>
                            <div class="topSortItemContNum">{{ first.uid ? first.rankValue : 0 }}</div>
                            <div>{{ $t('liveRoom.contribute') }}</div>
                        </div>
                    </div>
                    <div class="topThird topSortItem">
                        <div class="topBg"></div>
                        <div @click="itemClick(third)" class="topSortItemCont">
                            <img v-if="third.uid && !third.rankHidden" v-real-img="third.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                            <img v-if="third.uid && third.rankHidden" src="../../assets/img/live/mysteryMan.png"/>
                            <img v-if="!third.uid" src="../../assets/img/errorImg/2.png"/>
                            <div>{{ getName(third) }}</div>
                            <div class="topSortItemContNum">{{ third.uid ? third.rankValue : 0 }}</div>
                            <div>{{ $t('liveRoom.contribute') }}</div>
                        </div>
                    </div>
                </div>

                <div class="topList">
                    <div class="topListBg"></div>
                    <div class="topListTitle"></div>
                    <div v-if="showList.length > 0">
                        <div v-for="(item, index) in showList" :key="`${index}--topList--liveRoom`" @click="itemClick(item)" class="topListItem flexBetween">
                            <div class="flexCenter topListItemLeft">
                                <div>{{ index + 4 }}</div>
                                <div>{{ item.sort }}</div>
                                <img v-if="!item.rankHidden" class="topListAvatar" v-real-img="item.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                                <img v-else src="../../assets/img/live/mysteryMan.png"/>
                                <div>{{ getName(item) }}</div>
                            </div>
                            
                            <div class="topListItemRigth">{{ item.rankValue }}</div>
                        </div>
                    </div>

                    <div v-else class="noData">
                        <img src="../../assets/img/record/noData.png"/>
                        <p>{{$t('common.noData')}}</p>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { anchorList } from "@/api/live";
import { mapState } from 'vuex';
export default {
    name: "liveRoomTopList",
    data() {
        return {
            topList: [],
            topActive: 0, //礼物榜单选中单项
            isShowTopList: false,
            allList: [], //月榜
            dayList: [], //日榜
        }
    },
    computed: {
        ...mapState(['liveInfo']),
        first() {
            let obj = {}, arr = [];
            if (this.topActive == 0) {
                arr = JSON.parse(JSON.stringify(this.dayList));
            } else {
                arr = JSON.parse(JSON.stringify(this.allList));
            }

            if (arr.length > 0) {
                obj = arr[0];
            }

            return obj;
        },
        second() {
            let obj = {}, arr = [];
            if (this.topActive == 0) {
                arr = JSON.parse(JSON.stringify(this.dayList));
            } else {
                arr = JSON.parse(JSON.stringify(this.allList));
            }

            if (arr.length > 1) {
                obj = arr[1];
            }

            return obj;
        },
        third() {
            let obj = {}, arr = [];
            if (this.topActive == 0) {
                arr = JSON.parse(JSON.stringify(this.dayList));
            } else {
                arr = JSON.parse(JSON.stringify(this.allList));
            }

            if (arr.length > 2) {
                obj = arr[2];
            }

            return obj;
        },
        showList() {
            let arr = [], newArr = [];
            if (this.topActive == 0) {
                arr = JSON.parse(JSON.stringify(this.dayList));
            } else {
                arr = JSON.parse(JSON.stringify(this.allList));
            }

            if (arr.length > 3) {
                newArr = arr.splice(3);
            }

            return newArr;
        }
    },
    created() {
        let that = this;
        setTimeout(()=> {
            that.isShowTopList = true;
        }, 10)

        this.getAnchorList();
    },
    methods: {
        // 展示名字
        getName(item) {
            let str = ''
            if (item && item.uid) {
               if (item.rankHidden) {
                 str = this.$t('liveRoom.mysteryMan');
               } else {
                str = item.nickname
               }
            }

            return str;
        },
        // 点击
        itemClick(item) {
            if (!item.uid) return;
            if (!item.rankHidden) {
                this.$store.commit("setState", { betPramas: item });
                this.$emit('openPopup', 'showUserInfo')
            }
        },
        close() {
            this.isShowTopList = false;
            let that = this;
            setTimeout(()=> {
                that.$emit('closeTopList')
            }, 1000)
        },
        getAnchorList() {
            let params = {
                anchorId: this.liveInfo.anchorId
            }
            anchorList(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.allList = res.data.allList;
                    this.dayList = res.data.dayList;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.topListBox {
    color: #333;
    padding-top: 0.88rem;
    background-color: #fff;

    .title {
        box-shadow: 0 0 0 0 rgba($color: #000000, $alpha: 0);
    }

    .timeOptions {
        background: -webkit-linear-gradient(left, #ff6e73, #feb491);
        background: linear-gradient(90deg, #ff6e73, #feb491);
        color: #fff;

        div {
            font-size: 0.28rem;
            padding: 0.12rem 0;
            border-bottom: 2px solid transparent;
            margin: 0 auto;

            &.active {
                border-color: #fff;
            }
        }
    }

    .topBox {
        display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
        display: -moz-box; /* Firefox 17- */
        display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
        display: -moz-flex; /* Firefox 18+ */
        display: -ms-flexbox; /* IE 10 */
        display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
    
        align-items:flex-end;
        -webkit-align-items:flex-end;
        box-align:flex-end;
        -moz-box-align:flex-end;
        -webkit-box-align:flex-end;
    
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -moz-box-pack: space-between;
        -webkit-moz-box-pack: space-between;
        box-pack: space-between;
        padding: 0 0.54rem;

        .topSortItem {
            width: calc(33.3% - 0.14rem);
            margin-top: 0.8rem;
            font-size: 0.2rem;
            position: relative;
            border-left: 1px solid #f9f9f9;
            border-bottom: 1px solid #f9f9f9;
            border-right: 1px solid #f9f9f9;
            border-bottom-left-radius: 0.2rem;
            border-bottom-right-radius: 0.2rem;

            .topBg {
                height: 0.5rem;
                width: 100%;
                position: absolute;
                top: -0.46rem;
                left: 0;
            }

            &.topSecond {
                height: 2.24rem;

                .topBg {
                    background-image: url('../../assets/img/live/top2.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }

            &.topFirst {
                height: 2.44rem;
                border-color: #e7b372;

                .topBg {
                    background-image: url('../../assets/img/live/top1.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    height: 0.6rem;
                    top: -0.56rem;
                }
            }

            &.topThird {
                height: 2.24rem;

                .topBg {
                    background-image: url('../../assets/img/live/top3.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            .topSortItemCont {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                padding-bottom: 0.2rem;
                font-size: 0.24rem;

                img {
                    width: 0.44rem;
                    height: 0.44rem;
                    border-radius: 50%;
                    margin-bottom: 0.16rem;
                }

                .topSortItemContNum {
                    font-size: 0.34rem;
                    margin: 0.06rem 0;
                }
            }
        }
    }

    .topList {
        padding: 0 0.28rem;
        margin-top: 0.2rem;
        min-height: 6rem;
        position: relative;

        .topListBg {
            background: -webkit-linear-gradient(left, #df9566, #e7ad97);
            background: linear-gradient(90deg, #df9566, #e7ad97);
            height: 0.24rem;
            width: 100%;
            border-top-left-radius: 0.2rem;
            border-top-right-radius: 0.2rem;
        }

        .topListItem {
            border-bottom: 1px solid #f2f2f2;
            padding: 0.2rem 0;
            margin: 0 0.28rem;

            &:last-child {
                border-bottom: none;
            }

            .topListAvatar {
                width: 0.84rem;
                height: 0.84rem;
                border-radius: 50%;
                margin: 0 0.2rem;
            }

            .topListItemRigth {
                flex: 1;
            }

            .topListItemLeft {
                flex: 3;
                text-align: left;
            }
        }
    }
}
</style>