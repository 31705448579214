<template>
  <div class="next transfer">
    <div class="title">
        <span>{{$t('home.transfer')}} </span>
        <div class="fixed" @click="goBack">
            <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
        </div>
    </div>

    <div class="withdrawItem">
        <div class="flexBetween details">
            <div class="flexCenter">
                {{ $t('my.finance.walletAmount') }}
                <img @click="refreshWallet" src="../../../assets/img/record/refresh.png" />
            </div>
        </div>

        <div class="flexBetweenTop walletCenter">
            <div class="walletCenter_left">
                <p>{{ $t('my.centerWallet') }} <span class="recycle" @click="backAllCoin">{{ $t('my.finance.recycle') }}</span></p>
                <div class="blue">
                    <img src="../../../assets/img/gold.png" />
                    {{member&&member.goldCoin ? numFormat(member.goldCoin) : '0.00' }}
                </div>
            </div>
        </div>

        <div class="walletDetail walletDetail_item_cont borderTop">
            <div v-for="(item, index) in walletContList" :key="`${index}--walletCont`" class="walletDetail_item">
                <div class="flexCenterBL">{{item.remark}}</div>
                <div class="flexCenterBL">
                    <span v-show="item.resultStatus">{{numFormat(digitalNumber(item.balance))}} {{ item.name }}</span>
                    <img v-show="String(item.resultStatus)=='false'" src="../../../assets/img/maintain.png" />
                </div>
            </div>

            <div v-show="showWallet==2" @click="showMoreWallet(1)" class="walletDetail_item">
                <div class="flexCenterBL">{{$t('common.more')}}</div>
                <div class="flexCenterBL">
                    <img class="more" src="../../../assets/img/down.png" />
                </div>
            </div>

            <div v-show="showWallet !=2" @click="showMoreWallet(2)" class="walletDetail_item">
                <div class="flexCenterBL">{{$t('my.finance.putAway')}}</div>
                <div class="flexCenterBL">
                    <img class="more all" src="../../../assets/img/down.png" />
                </div>
            </div>
        </div>
    </div>

    <div class="walletTranfer_transferInfo" >
        <div class="walletTranfer_transferInfo__title"> 
            <span @click="changeWallet(1)">{{ leftType }}<van-icon name="arrow" /> </span>
            <img src="../../../assets/img/record/arrow.png" />
            <span @click="changeWallet(2)">{{ rightType }}<van-icon name="arrow" /> </span>
        </div>
        <p class="walletTranfer_notice">{{$t('my.finance.interchangeTips')}}</p>

        <div class="moneyPicker_container">
            <ul>
                <li v-for="(item, index) in moneyList" :key="`${index}--moneyList`" @click="moneyItem(item)" :class="{'active': text==numFormat(item) }">
                    <img src="../../../assets/img/gold.png" />
                    <span>{{numFormat(item)}}</span>
                </li>
            </ul>
        </div>
        <div class="flexBetween">
            <img class="goldIcon" src="../../../assets/img/gold.png" />
            <van-field id="moneyBox" v-model.trim="text" @input="changeMoney" clearable autocomplete="off" :placeholder="`${$t('my.finance.enterTransferAmountTips')}`"/>
            <div @click="allMoeny" style="width: 2rem;text-align: right;padding-right: 0.28rem;">{{$t('common.fullAmount')}}</div>
        </div>
        <div v-show="showTips" class="moneyTips">{{ leftType }}{{$t('my.finance.insufficientBalance')}}</div>
    </div>

    <div class="footer_btn">
        <span v-show="!isCanTransfer">{{$t('my.finance.nowTransfer')}}</span>
        <span v-show="isCanTransfer" class="active" @click="transfer">{{$t('my.finance.nowTransfer')}}</span>
    </div>

    <div class="helpTip">
        {{$t('my.cards.needHelp')}}
        <router-link to="/customerService">{{$t('entry.contactCustomer')}}</router-link>
    </div>

    <!-- 投注记录 -->
    <van-action-sheet class="weightModel" v-model:show="isShowWallet" @close="colseRecord" :title="`${$t('my.finance.chooseWallet')}`">
        <div class="wallentBox">
            <div class="flexBetween wallentItem" @click="wallentItem(centerWallet)">
                <div>{{centerWallet.remark}}</div>
                <div v-show="(centerWallet.remark == rightType && transferType==2) || (centerWallet.remark == leftType && transferType==1)">
                    <van-icon name="success" />
                </div>
            </div>
            <div class="flexBetween wallentItem" v-for="(item, index) in walletCont" :key="`${index}--walletCont-weightModel`" @click="wallentItem(item)">
                <div>{{item.remark}}</div>
                <div v-show="(item.remark == rightType && transferType==2) || (item.remark == leftType && transferType==1)">
                    <van-icon name="success" />
                </div>
            </div>
        </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { getBalance, gamePlatforms, transfer, backAllCoin } from "@/api/index";
export default {
    data() {
        return {
            walletCont: [],
            text: "",
            showWallet: 2,
            moneyList: [100, 500, 1000, 2000, 3000],
            isShowWallet: false,
            transferType: 1,
            leftType: this.$t('my.centerWallet'),
            rightType: "",
            centerWallet: {
                remark: this.$t('my.centerWallet')
            },
            isfresh: false
        }
    },
    created() {

    },
    computed: {
        entry() {
            return this.$store.state.entry;
        },
        walletContList() {
            let arr = JSON.parse(JSON.stringify(this.walletCont));
            if (this.showWallet == 2) {
              arr = arr.slice(0, 2);
            }
            return arr;
        },
        member() {
            return this.$store.state.member;
        },
        maxMoney() {
            if (this.leftType == this.$t('my.centerWallet')) {
                return this.member.goldCoin;
            } else {
                let index = this.walletCont.findIndex(item => {
                    return item.remark == this.leftType;
                });
                
                if (index != -1) {
                    return (this.walletCont[index].balance / this.walletCont[index].rate);
                } else {
                    return 0;
                }
            }
        },
        isCanTransfer() {
            let flag = false;
            if (this.text) {
                let str = this.text.toString().replace(/[^\d]/g,'');
                if (parseFloat(str) <= this.maxMoney) {
                    flag = true;
                }
            }
            return flag;
        },
        showTips() {
            let flag = false;
            if (this.text) {
               let str = this.text.toString().replace(/[^\d]/g,'');
                if (parseFloat(str) > this.maxMoney) {
                    flag = true;
                }
            }
            return flag;
        }
    },
    created() {
        this.gamePlatforms();
        this.$store.dispatch('refreshUserInfo');
    },
    methods: {
        // 输入金额添加千位符
        changeMoney() {
            let box = document.getElementById('moneyBox');
            if (this.text) {
                let start = box.selectionStart;
                let str = this.text.toString().replace(/[^\d]/g,'');
                let old = this.text;
                this.text = this.numFormat(str);
                if (this.text.length > 20) {
                    let str1 = this.text.substring(0, 21).toString().replace(/[`,`]/g,'');
                    this.text = this.numFormat(str1);
                } 
                
                if (this.numFormat(str).length > old.length && this.numFormat(str).length - old.length == 1) {
                    setTimeout(()=> {
                        box.setSelectionRange(start+1, start+1);
                    }, 0);
                }

                if (this.numFormat(str).length == old.length) {
                    setTimeout(()=> {
                        box.setSelectionRange(start, start);
                    }, 0); 
                }
            }
        },
        // 刷新钱包
        async refreshWallet() {
            this.isfresh = true;
            this.gamePlatforms();
            this.$store.dispatch('refreshUserInfo');
        },
        // 转账钱包
        wallentItem(item) {
            if (this.transferType == 1) {
                if (item.remark == this.centerWallet.remark) {
                    this.rightType = this.leftType;
                } else {
                    this.rightType = this.centerWallet.remark;
                }

                this.leftType = item.remark;
            }

            if (this.transferType == 2) {
                if (item.remark == this.centerWallet.remark) {
                    this.leftType = this.rightType;
                } else {
                    this.leftType = this.centerWallet.remark;
                }

                this.rightType = item.remark;
            }

            this.isShowWallet = false;
        },
        // 更改钱包
        changeWallet(type) {
            this.transferType = type;
            this.isShowWallet = true;
        },
        // 关闭钱包面板
        colseRecord() {

        },
        // 全部金额
        allMoeny() {
            if (this.leftType == this.centerWallet.remark) {
                this.text = this.member.goldCoin ? this.numFormat(Math.floor(this.member.goldCoin)) : 0;
            } else {
                let index = this.walletCont.findIndex(item => {
                    return item.remark == this.leftType;
                });

                if (index != -1) {
                    this.text = this.walletCont[index].balance ? this.numFormat(Math.floor(this.walletCont[index].balance/this.walletCont[index].rate)) : 0;
                }
            }
        },
        // 点击金额
        moneyItem(item) {
            this.text = this.numFormat(item);
        },
        // 一键回收
        backAllCoin() {
            backAllCoin().then(res => {
                if (res.code == 0) {
                    this.$toast(this.$t('my.invite.recycledSuccessfully'));
                    this.getBalance();
                    this.$store.dispatch('refreshUserInfo');
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        },
        // 钱包分类
        gamePlatforms() {
            gamePlatforms(this.member.uid, 1).then(res => {
                if (res.code == 0) {
                    this.walletCont = res.data;
                    this.getBalance();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                console.log('catch');
            });
        },
        // 转账
        transfer() {
            let amount = this.text.toString().replace(/[^\d]/g,'');
            let pramas = {
                gamePlatform: this.leftType == this.centerWallet.remark ? this.rightType : this.leftType,
                gamePlatformUsername: this.member.nickname,
                gameTransferType: this.leftType == this.centerWallet.remark ? 1 : 2,  // 1：从中心转入三方  2：从三方转出
                transferAmount: amount,
                uid: this.member.uid
            }
            transfer(pramas).then(res => {
                if (res.code == 0) {
                    this.$toast(this.$t('my.finance.transferSuccessTips'));
                    this.text = "";
                    this.getBalance();
                    this.$store.dispatch('refreshUserInfo');
                } else {
                    if (res.msg) this.$toast(res.msg);
                    if (this.isfresh) {
                        this.$toast(this.$t('common.refreshFailed'));
                    }
                    this.isfresh = false;
                }
            }).catch(() => {
                console.log('catch');
                if (this.isfresh) {
                    this.$toast(this.$t('common.refreshFailed'));
                }
                this.isfresh = false;
            });
        },
        // 获取三方钱包余额
        getBalance() {
            getBalance({uid: this.member.uid}).then(res => {
                if (res.code == 0) {
                    let walletCont = JSON.parse(JSON.stringify(this.walletCont));
                    walletCont.forEach(item => {
                        res.data.forEach(xitem => {
                            if (item.name == xitem.gamePlatform) {
                                item.balance = xitem.balance;
                                item.resultStatus = xitem.resultStatus;
                            }
                        });
                    });
                    this.walletCont = walletCont;
                    if (!this.rightType) {
                        this.rightType = walletCont[0].remark;
                    }

                    if (this.isfresh) {
                        this.$toast(this.$t('common.refreshSuccess'));
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }

                this.isfresh = false;
            }).catch(() => {
                if (this.isfresh) {
                    this.$toast(this.$t('common.refreshFailed'));
                }
                this.isfresh = false;
                console.log('catch');
            });
        },
        goBack() {
            this.$router.push(this.entry);
        },
        showMoreWallet(val) {
            this.showWallet = val;
        },
        // onInput(checked) {
        //     this.$dialog.confirm({
        //         title: this.$t('common.kindTips'),
        //         message: '是否要关闭自动免转功能？',
        //         className: "confirmDialog",
        //         confirmButtonColor: "#eb457e"
        //     }).then(() => {
        //         // this.checked = checked;
        //         this.$store.commit("setState", { isTransfer: checked });
        //     }).catch(() => {
        //         console.log("取消");
        //     });
        // },
        inDevelopment() {
            this.$toast(this.$t('common.inDevelop'));
        }
    }
}
</script>

<style lang="scss" scoped>
.next {
    .withdrawItem {
        background-color: #fff;
        overflow: hidden;
        padding: 0.2rem 0.28rem;
        position: relative;
        z-index: 2;
        text-align: center;

        .details {
            height: 0.8rem;
            border-bottom: 1px solid #eee;

            div:nth-child(1) {
                font-weight: 700;

                img {
                    width: 0.28rem;
                    height: 0.28rem;
                    margin-left: 0.1rem;
                }
            }
            i{
                border: 1px solid #cbced8;
                margin-left: 0.1rem;
                color: #cbced8;
            }
        }

        .blue {
            color: #eb457e;
        }

        .walletCenter {
            text-align: left;
            padding: 0.2rem 0;

            .walletCenter_left {
                img {
                    width: 0.28rem;
                }

                .recycle {
                    display: inline-block;
                    min-width: 0.88rem;
                    padding: 0 0.1rem;
                    height: 0.32rem;
                    background: #eb457e;
                    color: #fff;
                    text-align: center;
                    font-size: 0.2rem;
                    border-radius: 0.5rem;
                }

                .blue {
                    margin-top: 0.3rem;
                    font-size: 0.34rem;
                }
            }
        }

        .walletDetail {
            width: 100%;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            padding: 0rem 0.28rem 0.3rem;

            &.borderTop {
                border-top: 1px solid #eee;
            }
            .walletDetail_item {
                padding: 0.22rem 0;
                position: relative;

                img {
                    width: 0.4rem;
                }

                i {
                    display: inline-block;
                    width: 0.28rem;
                    height: 0.28rem;
                    -webkit-transform: translateY(0.03rem);
                    transform: translateY(0.03rem);
                    margin-right: 0.06rem;
                    background-image: url("../../../assets/img/my/next/bankIcon.png");
                    background-size: 100% 100%;
                }

                div:nth-child(2) {
                    font-size: 0.2rem;
                    font-weight: 500;
                    height: 0.38rem;
                    line-height: .38rem;
                    text-align: center;
                    margin-top: 0.1rem;
                    color: #788597;
                    

                    &.balance {
                        color: #4176fa;
                        font-size: 0.28rem;
                    }

                    .more {
                        width: 0.26rem;

                        &.all {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            &.walletDetail_item_top .walletDetail_item {
                width: calc(100% / 3);

                &:not(:nth-child(3n))::after {
                    content: "";
                    width: 1px;
                    height: 0.38rem;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    background: #f8f8f8;
                    height: 0.4rem;
                    box-shadow: inset 0.5px 0 0 #eee;
                }
            }

            &.walletDetail_item_cont .walletDetail_item{
                width: calc(100% / 3);
                
                &:not(:nth-child(3n))::after {
                    content: "";
                    width: 1px;
                    height: 0.38rem;
                    position: absolute;
                    right: 0;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    background: #f8f8f8;
                    height: 0.4rem;
                    top: 0.4rem;
                    box-shadow: inset 0.5px 0 0 #eee;
                }
            }
        }
    }

    .transferItem {
        padding: 0.28rem;
        font-size: 0.28rem;
        font-weight: bold;
        background-color: #fff;
        margin: 0.2rem 0;
    }

    .walletTranfer_transferInfo {
        width: 100%;
        background: #fff;
        padding: 0 0.28rem;
        margin-top: 0.2rem;

        .walletTranfer_transferInfo__title {
            width: 100%;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            padding: 0.3rem;
            border-bottom: 0.02rem solid #eee;
            color: #414655;

            i {
                font-size: 0.2rem;
                margin: 0 0 0.05rem 0.1rem;
            }

            img {
                margin: 0 0.88rem;
                width: 0.4rem;
            }
        }

        .walletTranfer_notice {
            width: 100%;
            padding: 0.2rem 0 0.1rem;
            font-size: .24rem;
            color: #cbced8;
            text-align: left;
        }

        .moneyPicker_container {
            margin-top: 0.16rem;

            ul {
                display: -webkit-flex;
                display: flex;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                -webkit-justify-content: space-between;
                justify-content: space-between;

                li {
                    border-radius: 0.08rem;
                    border: 1px solid #eee;
                    height: 0.7rem;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-align-items: center;
                    align-items: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    margin: 0.24rem 0;
                    -webkit-flex: 0 0 23.2%;
                    flex: 0 0 23.2%;
                    color: #414655;
                    -webkit-transition: border .5s;
                    transition: border .5s;

                    span:nth-child(1) {
                        font-size: .16rem;
                    }

                    span:nth-child(2) {
                        font-size: .28rem;
                    }

                    img {
                        width: 0.24rem;
                        margin-right: 0.05rem;
                    }

                    &.active {
                        border: 1px solid #6aaaf5;
                        background: url('../../../assets/img/record/activeBtn.png') no-repeat;
                        background-size: 0.32rem 0.32rem;
                        background-position: right -0.02rem bottom -0.02rem;
                        color: #4176fa;
                    }
                }
            }
        }

        .flexBetween span {
            width: 2rem;
            color: #414655;
            font-size: 0.28rem;
        }

        .goldIcon {
            width: 0.28rem;
        }
    }

    .footer_btn {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;

        span {
            background: -webkit-linear-gradient(left,#3d72fa,#3d72fa)!important;
            background: linear-gradient(90deg,#3d72fa,#3d72fa)!important;
            opacity: .5;
            font-size: .32rem!important;
            font-weight: 700;
            display: block;
            line-height: .88rem;
            height: 0.88rem!important;
            font-size: .32rem;
            color: #fff;
            border-radius: 0.16rem;
            text-align: center;
            margin: 0px 0.28rem;

            &.active {
                opacity: 1;
            }
        }
    }

    .helpTip {
        margin-top: 0.6rem;
        font-size: 0.28rem;
        color: #626872;
        padding: 0 0.28rem 0.5rem;
        text-align: center;

        a {
            color: #6aaaf5;
        }
    }

    .moneyTips {
        text-align: left;
        font-size: 0.2rem;
        margin-left: 0.28rem;
        color: #db6372;
    }
}
</style>
<style lang="scss">
.walletTranfer_transferInfo {
    .van-field__label {
        width: 0.28rem;
    }
}

.transferItem .van-switch:not(.van-switch--on) {
    background-color: rgb(234, 236, 244);
}

.transfer  .weightModel {
    .van-action-sheet__header {
        font-size: 0.32rem;
        background-color: #F9F9F9;
        font-weight: bold;
    }

    .van-action-sheet__close {
        font-size: 0.34rem;
    }

    .wallentBox {
        text-align: left;
        padding: 0 0.28rem;
        margin-bottom: 0.3rem;

        .wallentItem {
            font-size: 0.28rem;
            padding: 0.35rem 0;
            border-bottom: 1px solid #EBEFF1;

            i {
                font-size: 0.4rem;
                color: #4176fa;
            }
        }
    }
}
</style>