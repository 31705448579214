<template>
    <!-- :class="{'pkRoom': liveInfo.pking}" -->
    <div class="liveRoom" >
        <!-- <img v-show="liveInfo.webStatus" class="liveRoomBg" v-real-img="this.liveInfo.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)"/> -->
        <img v-for="(item, index) in watermark" :key="`liveRoom-watermark-${index}`" v-show="showAcitve==(index+1)" :class="`watermark${index+1}`" v-real-img="item.watermarkIcon"/>
        <div class="liveBox" id="liveBox">
           <video id="live" autoplay preload="auto" playsinline webkit-playsinline></video>
        </div>

        <div class="liveSwipeBox">
            <van-swipe :loop="false" :show-indicators="false" :initial-swipe="1" :touchable="touchable"  class="liveSwipe">
                <van-swipe-item @click="swipeClick">
                    <div class="liveCont"></div>
                </van-swipe-item>
                <van-swipe-item @click="swipeClick">
                    <!-- 头部 -->
                    <div @touchstart="onTouchStart" @touchmove="onTouchMove" class="liveHead flexBetween">
                        <!-- 主播头像 -->
                        <div class="liveHeadLeft flexCenter">
                            <img @click.stop="goUserCard(liveInfo)"  v-real-img="liveInfo.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)" />
                            <span>{{ liveInfo.nickname }}</span>

                            <img @click.stop="follow(liveInfo)" v-show="!anchorDetail.follow" class="focusOn" src="../../assets/img/live/focusOn.png"/>
                        </div>

                        <!-- 头像列表 -->
                        <div class="liveHeadRight flexCenter">
                            <div class="userIcon">
                                <div v-for="(item, index) in showAvatarList" :key="`${index}-liveRoom-avatarList`" class="userIconItem" :class="getIsNoble(item)">
                                    <span>
                                        <img v-if="!item.roomHide" v-real-img="item.avatar" @click="goUserCard(item, 'roomHide')" :errorimg="require(`../../assets/img/errorImg/2.png`)" />
                                        <img v-else src="../../assets/img/live/mysteryMan.png"/>
                                    </span>
                                </div>
                            </div>

                            <div v-show="avatarList && avatarList.length > 5" @click.stop="showAudienceList=true" class="userMore">
                                <!-- <van-icon name="arrow" size="0.4rem"/> -->
                                <img src="../../assets/img/live/more.png" />
                            </div>
                        </div>
                    </div>

                    <!-- 推荐主播入口 -->
                    <img @click.stop="showTopAnchor=true" class="hotIcon" src="../../assets/img/live/hot.png" />

                    <!-- 预览倒计时 -->
                    <div v-if="showCountdown" class="previewBox">
                        {{ $t('liveRoom.previewCountdown') }} <span>{{ roomPreviewNum }}s</span>
                    </div>

                    <!-- pk 对方主播名称 -->
                    <!-- <div v-if="liveInfo.pking">
                        <div class="pkAnchorNickname">
                            {{ pkInfo.nickname }}
                            <img v-show="!pkInfo.isFollow" @click="followOtherSide" src="../../assets/img/live/focusOn.png"/>
                        </div>

                        <div class="pkStatus">
                            <img src="../../assets/img/live/pk/connecting.png"/>
                            连接中
                        </div>

                        <div class="pkNum">
                            <div class="red">
                                8888
                            </div>
                            <div class="blue">
                                5555
                            </div>
                        </div>

                        <div class="pkSvgBox">
                            <div id="pkSvg"></div>
                        </div>

                        <div class="pkRuleIcon" @click="getPkConfig">
                            PK 规则 
                            <van-icon name="question" />
                        </div>
                    </div> -->

                    <!-- 礼物榜单 -->
                    <div class="flexCenter userGiftBox">
                        <div class="giftList flexCenter" @click.stop="showTopList=true">
                            <img src="../../assets/img/live/giftList.png" />
                            <span>{{ numFormat(liveInfo.zb) }}</span>
                            <van-icon name="arrow" />
                        </div>

                        <!-- 人气 -->
                        <div class="userNum flexCenter" @click.stop="showAudienceList=true">
                            <img src="../../assets/img/live/user.png" />
                            <span>{{ numFormat(liveInfo.rq) }}</span>
                            <van-icon name="arrow" />
                        </div>
                    </div>

                    <!-- 中奖飘屏 -->
                    <div v-if="showwinLottery" class="flexCenterBL floatingScreenBox winFloatingScreenBox animation">
                        <div class="winFloatingScreen">
                            <span> {{ winLottery.content }} </span>
                            <img src="../../assets/img/live/winLottery.png" />
                        </div>
                    </div>

                    <!-- 豪气礼物  -->
                    <div v-if="showLuxuryGift" class="flexCenterBL floatingScreenBox giftFloatingScreenBox animation">
                        <div class="winFloatingScreen">
                            {{ Luxury.chatHide ? $t('liveRoom.mysteryMan') : Luxury.nickname }} อยู่ในห้องไลฟ์สด {{ Luxury.anchorNickname }} ส่งออก {{ Luxury.gname }}!
                            <img src="../../assets/img/live/giftFloatingScreen.png" />
                        </div>
                    </div>


                    <!-- 游戏开奖 1 -->
                    <div v-if="showfirstLottery" class="flexCenter gameInfoBox gameInfoBox1 animation">
                        <lotteryDraw @closeLotteryScreen="closeLotteryScreen" :lottery="firstLottery"></lotteryDraw>
                    </div>

                    <!-- 游戏开奖 2-->
                    <div v-if="showsecondLottery" class="flexCenter gameInfoBox gameInfoBox2 animation">
                        <lotteryDraw @closeLotteryScreen="closeLotteryScreen" :lottery="secondLottery"></lotteryDraw>
                    </div>

                    <!-- 礼物连送-->
                    <div class="continuousDeliveryBox">
                        <div v-for="(item, index) in comboGiftData" :key="`${index}-liveRoom-comboGiftData`" class="flexCenter continuousDeliveryItem">
                            <div class="continuousDelivery flexCenter">
                                <img v-show="!item.chatHide" class="profilePicture" v-real-img="item.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)" />
                                <img v-show="item.chatHide" class="profilePicture" src="../../assets/img/live/mysteryMan.png" />
                                <div class="continuousDeliveryCont">
                                    <div class="userName">{{ item.chatHide ? $t('liveRoom.mysteryMan') : item.nickname }}</div>
                                    <div class="giftName">{{ item.gname }}</div>
                                </div>

                                <img class="giftIcon" v-real-img="item.cover" :errorimg="require(`../../assets/img/errorImg/2.png`)"  />
                            </div>

                            x 
                            <div class="continuousNum">{{item.combo}}</div>
                        </div>
                    </div>

                    <!-- 特效进房 -->
                    <div v-if="showEnterAnimation" id="enteringSpecialEffects" class="enteringSpecialEffects flexCenter enter" :class="getEnterClass(enterUserInfo.userLevel)" :style="{bottom: enterBoxHeight+'px'}">
                        <div v-if="enterUserInfo.userLevel" class="nobleLevel">
                            <img v-real-img="getImg(enterUserInfo.userLevel)" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                            <span :class="{'small': enterUserInfo.userLevel<10}">{{ enterUserInfo.userLevel }}</span>
                        </div>
                        <span class="enterName"> {{enterUserInfo.roomHide ? $t('liveRoom.mysteryMan') : enterUserInfo.nickname }}</span>
                        เข้าห้องไลฟ์
                    </div>

                    <!-- 消息体展示 滚动区域 -->
                    <chatDom @openPopup="openPopup" @openFollowConfirm="openFollowConfirm" @aditMsgNum="aditMsgNum" @aditScrollGap="aditScrollGap" class="scrollBox" ref="scrollBox"></chatDom>

                    <div v-show="scrollGap > 50 && hasNewMsg > 0" @click.stop="readMsg" class="hasMsgTips">
                       <span>{{ hasNewMsg }}</span> {{ $t('liveRoom.newMsg') }}
                    </div>

                    <!-- 底部 信息图标 开奖结果入口 游戏列表入口 礼物列表入口 退出ICON-->
                    <div class="flexBetween liveBottom">
                        <div class="liveBottomLeft flexCenter">
                            <van-field
                                class="sendInput"
                                v-model.trim="massage"
                                :left-icon="require('../../assets/img/live/sendMsg.png')"
                                :placeholder="$t('liveRoom.saySomeThing')"
                                @keyup.enter="sendMsg"
                            />

                            <img @click.stop="showLotteryBet=true" src="../../assets/img/live/recordIcon.png" />
                        </div>
                        <div class="liveBottomRight">
                            <img @click.stop="showGameList=true" src="../../assets/img/live/gameIcon.png" />
                            <img @click.stop="showGiftList=true" src="../../assets/img/live/giftIcon.png" />
                            <img @click.stop="closeLive" src="../../assets/img/live/closeIcon.png" />
                        </div>
                    </div>

                    <!-- 红包 -->
                    <div v-if="redPacketIcon" class="game red">
                        <div class="redBox" @click="clickRedIcon">
                            <img v-real-img="redPacket.iconUrl" src="../../assets/img/live/red.png" />
                            <p>{{ redPacketTips }}</p>
                        </div>
                        <van-icon @click.stop="redPacketIcon=false" class="closeIcon" color="#fff" name="close" />
                    </div>

                    <!-- 游戏icon -->
                    <div v-if="firstGame.name" @click.stop="goLottery(firstGame)" class="game game1">
                        <img v-real-img="firstGame.lorretyIcon" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                        <p v-show="parseInt(second)>54">{{$t('lottery.closure')}}</p>
                        <p v-show="parseInt(second)<=54">00:{{second > 9 ? second : '0'+ second}}</p>
                    </div>

                    <div v-if="secondGame.name" @click.stop="goLottery(secondGame)" class="game game2">
                        <img v-real-img="secondGame.lorretyIcon" :errorimg="require(`../../assets/img/errorImg/2.png`)"/>
                        <p v-show="parseInt(second)>54">{{$t('lottery.closure')}}</p>
                        <p v-show="parseInt(second)<=54">00:{{second > 9 ? second : '0'+ second}}</p>
                    </div>

                    <div v-show="showSvg" id="svgGiftBox"></div>

                    <!-- 红包雨特效 -->
                    <!-- <redPacketCom  v-if="(redPacket && redPacket.isShowAnimation) || (redPacket && redPacket.redPort && redPacket.redPort.indexOf('1') == -1)"
                    @updateRedket="updateRedket" @updateRedketCatch="updateRedketCatch" @openPopup="openPopup"></redPacketCom> -->
                </van-swipe-item>
            </van-swipe>
        </div>

        <!-- 用户卡片 -->
        <userCard v-if="showUserCard" @closeUserCard="closeUserCard" @refresh="refresh"
         @openPopup="openPopup" @qUser="qUser"></userCard>

        <!-- 用户详情 -->
        <userInfo v-if="showUserInfo" @colsePopup="colsePopup"></userInfo>

        <!-- 充值 -->
        <deposit v-if="showDeposit" @colsePopup="colsePopup"></deposit>

        <!-- 添加银行卡 -->
        <addCard v-if="showAddCard" @colsePopup="colsePopup"></addCard>

        <!-- 送礼榜单 -->
        <topList v-if="showTopList" @closeTopList="closeTopList"  @openPopup="openPopup"></topList>

        <!-- 观众列表 -->
        <audienceList v-if="showAudienceList" @closeAudienceList="closeAudienceList" @openPopup="openPopup"></audienceList>

        <!-- 推荐榜单 -->
        <topAnchor v-if="showTopAnchor" @refreshLiveRoom="refreshLiveRoom" @colsePopup="colsePopup"></topAnchor>

        <!-- 礼物列表 -->
        <giftList v-if="showGiftList" @closeGiftList="closeGiftList" @openPopup="openPopup"></giftList>

        <!-- 奖杯icon 弹框 -->
        <lotteryResultIcon v-if="showLotteryBet" @colsePopup="colsePopup" @openPopup="openPopup"></lotteryResultIcon>

        <!-- 每一种彩种最后一期的开奖结果 -->
        <lotteryResult v-if="showLotteryResult" @colsePopup="colsePopup" @openPopup="openPopup"></lotteryResult>

        <!-- 投注记录 -->
        <lotteryRecord v-if="showLotteryRecord" @colsePopup="colsePopup" @openPopup="openPopup"></lotteryRecord>

        <!-- 投注详情 -->
        <lotteryBetDetail v-if="showLotteryBetDetail" @colsePopup="colsePopup"></lotteryBetDetail>

        <!-- 单个游戏开奖记录 120-->
        <recordLottery v-if="showRecordLottery" @colsePopup="colsePopup" @openPopup="openPopup"></recordLottery>

        <!-- 游戏列表 -->
        <gameList v-if="showGameList" @openSingleLottery="openSingleLottery" @colsePopup="colsePopup" @openPopup="openPopup"></gameList>

        <!-- 单个彩票 -->
        <singleLottery v-if="showSingleLottery" 
            :gameData="gameData" 
            :sdMiddle="sdMiddle" 
            :second="second"
            :lotteryActvieBet="lotteryActvieBet"
            @openLotteryBetSure="openLotteryBetSure"
            @gosingleLotteryResult="gosingleLotteryResult" 
            @lotteryItem="lotteryItem"
            @colsePopup="colsePopup"
            @openPopup="openPopup"></singleLottery>

        <!-- 彩票投注确认 -->
        <lotteryBetSure 
        v-if="showLotteryBetSure" 
        :lotteryActvieBet="lotteryActvieBet" 
        :second="second" 
        @colsePopup="colsePopup" 
        @spliceLotteryItem="spliceLotteryItem"
        @initLotteryBet="initLotteryBet"></lotteryBetSure>

        <!-- 主播升级弹框-->
        <van-popup v-model:show="isShowAnchorUpgrade" round>
            <div v-if="anchorUpgrade && anchorUpgrade.levelType" class="wrapper" @click.stop>
                <div v-if="anchorUpgrade.levelType == 1" class="upgradeBox">
                    <img src="../../assets/img/live/anchor.png"/>
                    <p>{{ $t('liveRoom.anchorUpgradeTips') }} <span>LV{{ anchorUpgrade.level }}</span></p>
                    <div @click.stop="isShowAnchorUpgrade=false" class="btn">{{ $t('common.confirm') }}</div>
                </div>
            </div>
        </van-popup>

        <!-- 支付弹框 -->
        <toPay v-if="showToPay" @refreshLiveRoom="refreshLiveRoom" @colseToPay="colseToPay" @colsePopup="colsePopup" @openPopup="openPopup"></toPay>

        <!-- 余额不足弹框 -->
        <van-dialog class="confirmDialog" v-model:show="showShortBalance" show-cancel-button 
            confirm-button-Color="#eb457e"
            cancel-button-Color="#515151"
            :confirm-button-text="$t('common.confirm')"
            :cancel-button-text="$t('common.cancel')"
            @confirm="goRechangeComfirm"
            >
            <div class="shortBalanceBox">
                {{ $t('liveRoom.shortBalanceTips') }} 
            </div>
        </van-dialog>

        <!-- 红包 未开始 / 已抢完-->
        <van-popup class="redPacketDialog" v-model:show="isShowRedPacket" round>
            <div class="redPacketDialogBox" @click.stop="isShowRedPacket=false">
                <div class="redPaccketDialogTips">
                    {{ redPacketTipsIn }}
                </div>
                <img src="../../assets/img/live/red/redEnd.png" />
            </div>
        </van-popup>

        <!-- 红包 正在进行 抢红包 -->
        <van-popup class="redPacketDialog" v-model:show="isShowRedPacketIn" round>
            <div class="redPacketDialogBox" @click.stop="isShowRedPacketIn=false">
                <div v-if="redPacketData.redNumber">
                    <div v-if="redPacketData.type" class="redPacketDialogOpen">
                        ยินดีด้วยที่คว้าอั่งเปาได้
                        <p>{{ redPacketData.money }}</p>
                    </div>
                    <div  v-else class="redPacketDialogOpen">
                        ขอบคุณสำหรับการเข้าร่วม
                    </div>
                </div>
                <div v-else class="redPacketDialogOpen">
                    <div v-if="redPacketData.msg"> {{ redPacketData.msg }} </div>
                    <div v-else>อั่งเปารอบนี้โดนคว้าไปหมดแล้ว</div>
                </div>
                <img src="../../assets/img/live/red/openRed.png" />
            </div>
        </van-popup>

        <!-- pk 规则弹框 -->
        <van-popup v-model:show="isShowPkRule" round class="gameListDialog" :safe-area-inset-bottom="true"
            position="bottom">
            <div class="pkRuleBox">
                <div class="pkRuleTitle">PK规则</div>
                <div class="pkRuleCont" v-html="pkRule"></div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import userCard from "@/pages/liveRoom/userCard.vue";
import giftList from "@/pages/liveRoom/giftList.vue";
import audienceList from "@/pages/liveRoom/audienceList.vue";
import topList from "@/pages/liveRoom/topList.vue";
import topAnchor from "@/pages/liveRoom/topAnchor.vue";
import lotteryResultIcon from "@/pages/liveRoom/lotteryResultDialog.vue";
import lotteryResult from "@/pages/liveRoom/lotteryResult.vue";
import lotteryRecord from "@/pages/liveRoom/lotteryRecord.vue";
import lotteryBetDetail from "@/pages/liveRoom/lotteryBetDetail.vue";
import recordLottery from "@/pages/liveRoom/recordLottery.vue";
import gameList from "@/pages/liveRoom/lottery/index.vue";
import singleLottery from "@/pages/liveRoom/lottery/lottery.vue";
import lotteryBetSure from "@/pages/liveRoom/lottery/betSure.vue";
import userInfo from "@/pages/components/userInfo.vue";
import deposit from "@/pages/liveRoom/deposit.vue";
import toPay from "@/pages/liveRoom/toPay.vue";
import chatDom from "@/pages/liveRoom/chat.vue"; 
// import redPacketCom from "@/pages/liveRoom/redPacket.vue";
import lotteryDraw from "@/pages/liveRoom/lotteryDraw.vue"
import { roomInfo, anchorBase, liveChat, grabRedPacket, pkConfig, pkStatus, chargeRoom } from "@/api/live";
import { liveFollow } from "@/api/index";
import TCPlayer from 'tcplayer.js';
import 'tcplayer.js/dist/tcplayer.min.css';
import SVGA from "svgaplayerweb";
import lotteryData from '@/pages/liveRoom/mixin/lottery';
import Aes from "@/utils/aes.js";
import addCard from './addCard.vue';
import rechangeEntry from "@/pages/mixin/rechangeEntry";
export default {
    name: "liveRoom",
    mixins: [lotteryData, rechangeEntry],
    components: {
        userCard,
        giftList,
        audienceList,
        topList,
        topAnchor,
        lotteryResultIcon,
        recordLottery,
        gameList,
        userInfo,
        lotteryResult,
        lotteryRecord,
        lotteryBetDetail,
        deposit,
        singleLottery,
        lotteryBetSure,
        // redPacketCom,
        toPay,
        chatDom,
        lotteryDraw,
        addCard
    },
    data() {
        return {
            touchable: true, //是否开启界面手势滑动切换
            massage: "", //发言输入内容
            showAudienceList: false, //观众列表是否展示
            grandPrizeFlag: false, //飘奖开关
            showTopList: false, //送礼榜单是否展示
            showGiftList: false, //礼物列表弹框是否展示
            showUserCard: false, //用户弹框详情
            clientHeight: 0,
            clientWidth: 0,
            showTopAnchor: false, //推荐榜单弹框是否展示
            showUserInfo: false, // 用户详情
            // showAvatarList: [], //头像列表
            anchorDetail: {}, //主播详情

            showDeposit: false, //充值弹框
            showAddCard: false, //绑卡弹框
            bgTimer: null, //背景logo 展示 时间容器
            showAcitve: 0, // logo 展示
            showEnterAnimation: false, //进房特效是否显示
            enterUserInfo: {}, //进房特效信息
            enterBoxHeight: 0, // 进房特效定位的高度
            enterTimer: null, // 进房特效时间容器
            player: null, // 进房特效播放器
            parser: null,

            splayer: null, // 礼物座驾特效播放器
            sparser: null,

            isShowAnchorUpgrade: false, //主播升级弹框是否展示

            showSvg: false, //svg 播放是否展示

            redPacketTimer: null, //红包雨倒计时
            redPacketTips: this.$t('liveRoom.inProgress'), // 红包雨icon 展示的文字
            isShowRedPacket: false, //红包弹框
            redPacketTipsIn: "", // 红包提示语
            redPacketIcon: false, // 红包雨icon 是否展示
            isShowRedPacketIn: false, // 抢红包弹框
            redPacketData: {}, // 抢红包数据

            // TCPlayer: null, //直播流播放

            showToPay: false, // 是否展示付费弹框
            roomPreviewTimer: null, //预览倒计时
            roomPreviewNum: 10, // 倒计时时长 s

            showShortBalance: false, // 余额不足弹框是否展示

            showCountdown: false, //是否展示倒计时

            pkRule: "", // pk规则
            isShowPkRule: false, //pk 规则弹框是否展示
            pkTimer: null, //pk 时间容器
            pkData: {}, //PK中的相关数据

            hasNewMsg: 0, //几条未读消息
            scrollGap: 0, // 滚动条距离底部距离
            msgTimer: null,
            timingTime: null, // 计时付费时间容器
            fromPath: "",
        }
    },

    computed: {
        ...mapState([
            'member', 
            'liveInfo', 
            // 'chat', 
            // 'interUserInfo', 
            'levelEffects',
            'liveRoomLotteryScreen', 
            'anchorUpgrade', 
            'luxuryGift', 
            'svgGift', 
            'comboGift', 
            'redPacket',
            // 'pkInfo'
            'avatarList',
            'interUserArr',
            'allGift',
            'baseInfo',
            'liveAE',
            'isLoginOutIm',
            'TCPlayer'
        ]),
        comboGiftData() {
            let arr = [], comboGift = this.comboGift && this.comboGift.length > 0 ? JSON.parse(JSON.stringify(this.comboGift)) : [];
            if (comboGift.length <= 2) {
                arr = comboGift;
            }

            if (comboGift.length > 2) {
                arr = comboGift.splice(0, 2);
            }

            return arr;
        },
        watermark() {
            let arr = [];
            if (this.baseInfo.configBaseWatermarks && this.baseInfo.configBaseWatermarks.length > 0) {
                arr = this.baseInfo.configBaseWatermarks;
            }
            return arr;
        },
        roomType() {
            return this.liveInfo.type;
        },
        roomWebStatus() {
            return this.liveInfo.webStatus;
        },
        isManage() {
            let flag = false;
            if (this.member && this.member.badgeList && this.member.badgeList.length > 0) {
                let index = this.member.badgeList.findIndex(item => {
                    return item == '4'
                });

                if (index != -1) {
                    flag = true;
                }
            }
            return flag;
        },
        showAvatarList() {
            let arr = [], avatarList = this.avatarList && this.avatarList.length > 0 ? JSON.parse(JSON.stringify(this.avatarList)) : [];
            if (this.avatarList && this.avatarList.length > 5) {
                arr = avatarList.splice(0, 5)
            } else {
                arr = avatarList;
            }
            return arr;
        }
    },
    watch: {
        // 进房特效
        interUserArr(val) {
            if (val.length > 0 && !this.showEnterAnimation) {
                this.initMachineSVGA(val[0]);
                // this.isLevel(val[0]);
                // 本人进房座驾 ，进房时拿不到cardId
                // if (val[0].uid == this.member.uid && this.liveInfo.carId) {
                //     let obj = JSON.parse(JSON.stringify(val[0])), svgGift = this.svgGift.length > 0 ? JSON.parse(JSON.stringify(this.svgGift)): [];;
                //     obj.carId = this.liveInfo.carId;
                //     let index = this.allGift.findIndex(item => {
                //         return item.gid == obj.carId
                //     });

                //     if (index != -1) {
                //         svgGift.push({ ...this.allGift[index], ...obj });
                //         this.$store.commit("setState", { svgGift })
                //     }
                // }
            }
        },
        luxuryGift(val) {
            // 只播放了一次
            this.openLuxuryGift(val);
        },
        anchorUpgrade() {
            this.isShowAnchorUpgrade = true;
        },
        svgGift(val) {
            if (val.length > 0) {
                this.initSvgGift(val[0]);
            }
        },
        redPacket(val) {
            if (val && val.activityStatus == 1) {
                let that = this;
                clearInterval(this.redPacketTimer);
                this.redPacketTimer = setInterval(() => {
                    that.redPacketTips = that.getPart(val.latelyStartTime);
                    if (that.redPacketTips == '00:00:00') {
                        this.redPacketTips = '00:00:00';
                        clearInterval(that.redPacketTimer);
                    }
                }, 1000)
                
            } else {
                this.redPacketTips = this.$t('liveRoom.inProgress');
            }

            if (val && val.isShow) {
                this.redPacketIcon = true;
            } else {
                this.redPacketIcon = false;
            }
        },
        // pkInfo(val) {
        //     if (val.code == 2) {
        //         this.initPkSvg();
        //     }

        //     if (val.code == 5) {
        //             if (this.player) {
        //                 this.player.stopAnimation();
        //             }
        //     }
        // },
        // 'liveInfo.pking'(){
        //     if (this.liveInfo.pking) {
        //         this.getPkStatus();
        //     }
        // }
        // comboGiftData(val, old) {
        //     old.forEach(item => {
        //         clearInterval(item.timer);
        //     });
            
        //     val.forEach((item, index) => {
        //         item.timeNum = 3000;
        //         let findIndex = old.findIndex(fItem => {
        //             return fItem.gid == item.gid && fItem.uid == item.uid;
        //         }),
        //         that = this;

        //         if (findIndex == 2 && index == 1 && val.length == 2) {
        //             item.timeNum = old[findIndex].timeNum;
        //         }

        //         if (findIndex != -1 && index != -1 && findIndex == index && item.combo == old[findIndex].combo) {
        //             item.timeNum = old[findIndex].timeNum;
        //         }
        //         item.timer = setInterval(() => {
        //             if (item.timeNum <= 100) {
        //                 clearInterval(item.timer);
        //                 let comboGiftIndex = that.comboGift.findIndex(sitem => {
        //                     return sitem.gid == item.gid && sitem.uid == item.uid;
        //                 });

        //                 if (comboGiftIndex != -1) {
        //                     let comboGift = JSON.parse(JSON.stringify(that.comboGift));
        //                     comboGift.splice(comboGiftIndex, 1);
        //                     this.$store.commit("setState", { comboGift });
        //                 }
        //             } else {
        //                 item.timeNum = item.timeNum - 100;
        //             }
        //         }, 100)
        //     });
        // },
        comboGiftData(val, old) {
            old.forEach(item => {
                clearInterval(item.timer);
            });

            val.forEach((item, index) => {
                item.timeNum = 3000;
                const findIndex = old.findIndex(fItem => fItem.gid === item.gid && fItem.uid === item.uid);

                if (findIndex === 2 && index === 1 && val.length === 2) {
                    item.timeNum = old[findIndex].timeNum;
                }

                if (findIndex !== -1 && index !== -1 && findIndex === index && item.combo === old[findIndex].combo) {
                    item.timeNum = old[findIndex].timeNum;
                }

                item.timer = setInterval(() => {
                if (item.timeNum <= 100) {
                    clearInterval(item.timer);
                    const comboGiftIndex = this.comboGift.findIndex(sitem => sitem.gid === item.gid && sitem.uid === item.uid);

                    if (comboGiftIndex !== -1) {
                    const comboGift = [...this.comboGift];
                    comboGift.splice(comboGiftIndex, 1);
                    this.$store.commit("setState", { comboGift });
                    }
                } else {
                    item.timeNum -= 100;
                }
                }, 100);
            });
        },
        roomType(val) {
            clearInterval(this.timingTime);
            if (val == 1 || val == 2) {
                if (!this.showToPay) this.showToPay = true;
            }
            
            if (val == 3 && !this.isManage) {
                this.$router.push('/entryLiveBefore');
            }
        },
        roomWebStatus(val) {
            if (!val) {
                this.$store.commit("setState", { isShowDownLoad: false });
            } else {
                this.$store.commit("setState", { isShowDownLoad: true });
                if (this.TCPlayer && this.TCPlayer.dispose) this.TCPlayer.dispose(), this.$store.commit("setState", { TCPlayer: null });
            }
        },
        isLoginOutIm(val) {
            if (val) {
                this.destroyCurrent();
            }
        }
    },
    created() {
        this.initData();
        let that = this;
        document.addEventListener("visibilitychange", function() {
            if (document.visibilityState === "hidden") {
                if (that.TCPlayer) that.TCPlayer.pause();
            } else {
                if (that.TCPlayer) that.TCPlayer.play();
            }
        });
    },
    mounted() {
        this.initDom();
        // if (this.liveInfo.pking) {
        //     this.getPkStatus();
        // }
    },
    methods: {
        // 跟投确认
        openFollowConfirm(arr) {
            this.lotteryActvieBet = arr;
            this.showLotteryBetSure = true;
        },
        // 未读
        aditMsgNum(val) {
            if (val == 0) {
                this.hasNewMsg = 0;
            } else {
                this.hasNewMsg ++;
            }
        },
        // 消息盒子 滚动高度
        aditScrollGap(val) {
            this.scrollGap = val;
        },
        // 点击未读
        readMsg() {
            this.$refs.scrollBox.goBottom()
        },
        // 获取进房特效对应的特效className
        getEnterClass(val) {
            let num = 10;
            if (val <= 100 && val > 1) {
                num = Math.ceil(val / 10) * 10;
            }

            if (num > 100) {
                num = 100; 
            }
            return `level${num}`;
        },

        // 获取进房特效对应的特效className
        // getEnterClass(val) {
        //     let num = 10;
        //     if (10 < val && val <= 20) {
        //         num = 20;
        //     }

        //     if (20 < val && val <= 30) {
        //         num = 30;
        //     }

        //     if (30 < val && val <= 40) {
        //         num = 40;
        //     }

        //     if (40 < val && val <= 50) {
        //         num = 50;
        //     }

        //     if (50 < val && val <= 60) {
        //         num = 60;
        //     }

        //     if (60 < val && val <= 70) {
        //         num = 70;
        //     }

        //     if (70 < val && val <= 80) {
        //         num = 80;
        //     }

        //     if (80 < val && val <= 90) {
        //         num = 90;
        //     }

        //     if (90 < val) {
        //         num = 100;
        //     }
        //     return `level${num}`
        // },
        // 头像列表 贵族的class
        getIsNoble(item) {
            let className = '';
            if (item.badgeList && item.badgeList.length > 0) {
                item.badgeList.forEach(bitem => {
                    if(bitem > 5 && bitem <= 10) {
                        className = `isNoble${bitem}`
                    }
                })
            }
            return className;
        },
        swipeClick() {
           if(this.TCPlayer && this.TCPlayer.play) this.TCPlayer.play();
        },
        // 水印logo
        changeLogo() {
            if (this.watermark.length > 0 && this.watermark[this.showAcitve - 1].id) {
                clearInterval(this.bgTimer);
                let m = this.watermark[this.showAcitve - 1].watermarkDisplayInterval * 1000, that = this;
                this.bgTimer = setInterval(() => {
                    that.showAcitve = that.getShowNum();
                    that.changeLogo();
                }, m);
            }
        },
        // 等级特效处理
        // isLevel(val) {
        //     let index = this.levelEffects.findIndex(item => {
        //         return item.level == val.userLevel
        //     });

        //     if (index != -1) {
        //         let obj = {...this.levelEffects[index], ...val};
        //         this.initMachineSVGA(obj);
        //     } else {
        //         let interUserArr = this.interUserArr.length > 0 ? JSON.parse(JSON.stringify(this.interUserArr)) : [];
        //         interUserArr.splice(0, 1);
        //         this.$store.commit("setState", { interUserArr });
        //     }
        // },
        
        // 控制界面手势切换
        onTouchStart() {
            this.touchable = false;
        },
        onTouchMove() {
            this.touchable = true;
        },
        // 获取PK中的相关数据
        // getPkStatus() {
        //     let params = {
        //         anchorId: this.liveInfo.anchorId
        //     }
        //     pkStatus(params).then(res => {
        //         if (res.code === 0 || res.code === '0') {
        //             this.pkData = res.data;
        //         }
        //     }).catch(()=>{})
        // },
        // // pk时长和惩罚配置
        // getPkConfig() {
        //     pkConfig(this.member.uid).then(res => {
        //         if (res.code === 0 || res.code === '0') {
        //             this.pkRule = res.data.pkRule;
        //             this.isShowPkRule = true;
        //         }
        //     }).catch(()=>{})
        // },
        // pk 小拳头 地址目前需要修改
        // initPkSvg() {
        //     let that = this;
        //     this.player = new SVGA.Player('#pkSvg');
        //     this.parser = new SVGA.Parser('#pkSvg');
        //     this.player.loops = 0;
            
        //     // 必须是服务器地址或者是线上的地址本地是不可以的 会报错
        //     this.parser.load(`https://oss-bw03.pzgkw.com/game/game_1690281250000.svga`, (videoItem) => {
        //         that.player.setVideoItem(videoItem);
        //         that.player.startAnimation();
        //         that.player.onFinished(function(){

        //         });
        //     }, err => {
        //     })
        // },
        // 初始化操作
        initData() {
            this.$store.commit("setState", { betPramas: {}, winLotteryScreen: [], luxuryGift: [], 
            svgGift: [], comboGift: [], chat: [], pkInfo: {}, redPacket: {}, isLoadForLoad: false });

            if (this.$refs.scrollBox && this.$refs.scrollBox.refreshChat) {
                this.$refs.scrollBox.refreshChat();
            }
            this.roomInfo();
            this.anchorBase();
            this.showAcitve = this.getShowNum();
            this.changeLogo();
            this.$store.dispatch("refreshRedPacket");
            
            // 预览逻辑
            if (this.liveInfo.isPreview) {
                if (!this.liveInfo.webStatus) this.showToPay = true;
                if (this.liveInfo.webStatus) this.$store.commit("setState", { isShowDownLoad: true });
                if (this.TCPlayer && this.TCPlayer.dispose) this.TCPlayer.dispose(), this.$store.commit("setState", { TCPlayer: null });
            }
        },
        initDom() {
            let height = this.clientHeight = document.documentElement.clientHeight || window.innerHeight || document.body.clientHeight,
            liveBottom = document.querySelector('.liveBottom').clientHeight;
            this.clientWidth = document.documentElement.clientWidth || window.innerWidth || document.body.clientWidth;
            document.querySelector('.liveRoom').style.height = `${height}px`;
            document.querySelector('.liveSwipe').style.height = `${height}px`;
            document.querySelector('.scrollBox').style.maxHeight = `${height*0.235}px`;
            this.enterBoxHeight = height*0.235 + liveBottom + 15;
            setTimeout(() => {
                if (!this.liveInfo.webStatus || (this.liveInfo.webStatus && !this.liveInfo.isPreview)) {
                    this.playVideo();
                }
            }, 100);

            if (this.liveInfo.type == 1) {
                this.showCountdown = true;   
            } else if (this.liveInfo.type == 2) {
                this.showCountdown = true;   
            } else {
                this.showCountdown = false; 
            }

            if (this.liveInfo.webStatus && this.liveInfo.webStatus == 1) {
                this.showCountdown = true; 
            }

            let type = 'pay';
            if (this.liveInfo.webStatus == 1) {
                type = 'H5'
            }

            if (!this.liveInfo.isPreview && (this.liveInfo.type == 1 || this.liveInfo.type == 2 || this.liveInfo.webStatus)) {
                this.roomPreviewNum = 10;
                clearInterval(this.roomPreviewTimer);
                let that = this;
                this.roomPreviewTimer = setInterval(() => {
                    if (that.roomPreviewNum > 0) {
                        that.roomPreviewNum = that.roomPreviewNum -1;
                    } else {
                        if (type == 'H5') this.$store.commit("setState", { isShowDownLoad: true });
                        if (type == 'pay') that.showToPay = true;
                        if (that.TCPlayer && that.TCPlayer.pause) that.TCPlayer.pause();
                        clearInterval(that.roomPreviewTimer);
                    }
                }, 1000);
            }
        },
        // 销毁当前组件相关事件
        destroyCurrent() {
            if (this.comboGiftData.length > 0) {
                this.comboGiftData.forEach(item => {
                    clearInterval(item.timer);
                });
            }
            if (this.player) this.player.stopAnimation();
            if (this.splayer) this.splayer.stopAnimation();
            if (this.bgTimer) clearInterval(this.bgTimer);
            if (this.comboGiftTimer) clearInterval(this.comboGiftTimer);
            if (this.comboGift2Timer) clearInterval(this.comboGift2Timer);
            if (this.redPacketTimer) clearInterval(this.redPacketTimer);
            if (this.roomPreviewTimer) clearInterval(this.roomPreviewTimer);
            if (this.msgTimer) clearTimeout(this.msgTimer);
            if (this.timingTime) clearTimeout(this.timingTime);
            if (this.enterTimer) clearTimeout(this.enterTimer);
            if (this.TCPlayer && this.TCPlayer.dispose) this.TCPlayer.dispose(), this.$store.commit("setState", { TCPlayer: null });
        },
        // 刷新直播间
        refreshLiveRoom() {
            this.destroyCurrent();
            setTimeout(() => {
                var insertDiv = document.getElementById("liveBox");
                insertDiv.innerHTML = `<video id='live' autoplay preload='auto' playsinline webkit-playsinline></video>`
                // that.$forceUpdate();
                this.initData();
                this.initDom();
            }, 100)
        },
        // 红包倒计时时间格式转化
        getPart(endTime) {
            const current = Date.parse(new Date())
            let diff = ((endTime - current) / 1000), _diffData = "00:00:00";
            if (diff > 0) {            
                let h = Math.floor((diff % (3600 * 24)) / (60 * 60))
                let m = Math.floor(((diff % (3600 * 24)) % (60 * 60)) / 60)
                let s = Math.floor(diff % 60)
                h = h < 10? "0" + h : h;
                m = m < 10? "0" + m : m;
                s = s < 10? "0" + s : s;
                _diffData = `${h}:${m}:${s}`
            }

            return _diffData;
        },
        // 开启豪礼动画
        openLuxuryGift(arr) {
            if (arr.length > 0 && !this.showLuxuryGift) {
                this.showLuxuryGift = true;
                clearInterval(this.winLuxuryGiftTimer);
                let that = this;
                this.Luxury = JSON.parse(JSON.stringify(arr[0]));
                this.winLuxuryGiftTimer = setInterval(() => {
                    that.showLuxuryGift = false;
                    clearInterval(that.winLuxuryGiftTimer);
                    let luxuryGift = JSON.parse(JSON.stringify(arr));
                    luxuryGift.splice(0, 1);
                    that.$store.commit("setState", { luxuryGift });
                }, 12000)
            }
        },
        
        // 全屏礼物
        // initSvgGift(item) {
        //     if (!item || this.showSvg) return;
        //     let that = this;
        //     this.showSvg = true;
            
        //     this.splayer = new SVGA.Player('#svgGiftBox');
        //     this.sparser = new SVGA.Parser('#svgGiftBox');
        //     this.splayer.loops ++;
        //     // 必须是服务器地址或者是线上的地址本地是不可以的 会报错
        //     this.sparser.load(`${item.resourceUrl}`, (videoItem) => {
        //         // console.log(videoItem);
        //         // videoItem.videoSize.width = 50vw;
        //         that.splayer.setVideoItem(videoItem);
        //         that.splayer.startAnimation();
        //         that.splayer.onFinished(function(){
        //             that.showSvg = false;
        //             let svgGift = JSON.parse(JSON.stringify(that.svgGift));
        //             svgGift.splice(0, 1);
        //             that.$store.commit("setState", { svgGift });
        //         });
        //     }, err => {
        //         this.showSvg = false;
        //         let svgGift = JSON.parse(JSON.stringify(that.svgGift));
        //         svgGift.splice(0, 1);
        //         that.$store.commit("setState", { svgGift });
        //     })
        // },
        
        // 全屏礼物
        initSvgGift(item) {
            if (!item || this.showSvg) return;

            this.showSvg = true;
            const that = this;

            const handleSvgFinished = () => {
                that.showSvg = false;
                const svgGift = [...that.svgGift];
                svgGift.splice(0, 1);
                that.$store.commit("setState", { svgGift });
            };

            const loadSvg = () => {
                this.sparser.load(`${item.resourceUrl}`, videoItem => {
                that.splayer.setVideoItem(videoItem);
                that.splayer.startAnimation();
                that.splayer.onFinished(handleSvgFinished);
                }, err => {
                    handleSvgFinished();
                });
            };

            this.splayer = new SVGA.Player('#svgGiftBox');
            this.sparser = new SVGA.Parser('#svgGiftBox');
            this.splayer.loops++;

            loadSvg();
        },

        // 进房特效
        initMachineSVGA(item){
            if (!item || this.showEnterAnimation) return;
            let that = this;
            this.showEnterAnimation = true;
            this.enterUserInfo = JSON.parse(JSON.stringify(item));

            clearTimeout(this.enterTimer);
            this.enterTimer = setTimeout(() => {
                that.showEnterAnimation = false;
                let index = that.interUserArr.findIndex(eItem => {
                    return eItem.uid == item.uid
                });

                if (index != -1) {
                    let interUserArr = JSON.parse(JSON.stringify(that.interUserArr));
                    interUserArr.splice(index, 1);
                    that.$store.commit("setState", { interUserArr });
                }
            }, 3000);


            // this.player = new SVGA.Player(`#enteringSpecialEffects`);
            // this.parser = new SVGA.Parser(`#enteringSpecialEffects`);
            // this.player.loops ++;
            
            // // 必须是服务器地址或者是线上的地址 本地是不可以的 会报错
            // this.parser.load(`${item.resourceUrl}`, (videoItem) => {
            //     that.player.setVideoItem(videoItem);
            //     let url = '';

            //     if (item.roomHide) {
            //         url = require("../../assets/img/live/mysteryMan.png")
            //     } else {
            //         url = item.avatar ? item.avatar : require("../../assets/img/errorImg/2.png")
            //     }
                
            //     that.player.setImage(url, 'avatar');
            //     that.player.setText({ 
            //         text: `${item.roomHide ? this.$t('liveRoom.mysteryMan') : item.nickname} ${this.$t('liveRoom.userEnterLiveTips')}`, 
            //         family: 'Arial',
            //         size: "24px", 
            //         color: "#fff",
            //         offset: {x: 0, y: 0}
            //     }, 'content'); 
            //     that.player.startAnimation();
            //     that.player.onFinished(function(){
            //         that.showEnterAnimation = false;
            //         let index = that.interUserArr.findIndex(eItem => {
            //             return eItem.uid == item.uid
            //         });

            //         if (index != -1) {
            //             let interUserArr = JSON.parse(JSON.stringify(that.interUserArr));
            //             interUserArr.splice(index, 1);
            //             that.$store.commit("setState", { interUserArr });
            //         }
            //     });
            // }, err => {
            //     that.showEnterAnimation = false;
            //     let index = that.interUserArr.findIndex(eItem => {
            //         return eItem.uid == item.uid
            //     });

            //     if (index != -1) {
            //         let interUserArr = JSON.parse(JSON.stringify(that.interUserArr));
            //         interUserArr.splice(index, 1);
            //         that.$store.commit("setState", { interUserArr });
            //     }
            // })
        },
        // 直播流播放
        playVideo() {
            let liveInfo = JSON.parse(JSON.stringify(this.liveInfo)),
            pullStreamUrl = this.baseInfo.streamSwitch === '1' ? Aes.decryptL(liveInfo.pullStreamUrl, this.liveAE.randomKey, this.liveAE.randomVector) : liveInfo.pullStreamUrl,
            url = pullStreamUrl.replace(/rtmp/, "webrtc");

            if (!document.getElementById('live')) {
                var insertDiv = document.getElementById("liveBox");
                insertDiv.innerHTML = `<video id='live' autoplay preload='auto' playsinline webkit-playsinline></video>`
            }

            setTimeout(() => {
                let tCPlayerObj = TCPlayer('live', {
                    width: this.clientWidth,
                    height: this.clientHeight,
                    // controls: false,
                    posterImage: true,
                    // poster: this.anchorDetail.avatar,
                    autoplay: true,
                    // muted: true,
                    language: "YN",
                    controlBar: {
                        playToggle: false,
                        progressControl: false,
                        volumePanel: false,
                        currentTimeDisplay: false,
                        durationDisplay: false,
                        timeDivider: false,
                        playbackRateMenuButton: false,
                        fullscreenToggle: false,
                        QualitySwitcherMenuButton: false
                    }
                });

                tCPlayerObj.src(url);
                
                tCPlayerObj.poster(this.liveInfo.avatar ? this.liveInfo.avatar : require('../../assets/img/errorImg/2.png'));
                tCPlayerObj.ready(()=> {
                    console.log(99);
                });
                // this.TCPlayer.on('loadeddata', event => {
                //     console.log('loadeddata');
                //     console.log(event);
                // }); // url 播放地址

                tCPlayerObj.on('error', err => {
                    console.log(err);
                }); // url 播放地址

                this.$store.commit("setState", { TCPlayer: tCPlayerObj });
            }, 300)
        },
        // 生成1-4随机数 logo展示
        getShowNum() {
            let a = Math.random();      
            a = a*4;
            a = Math.ceil(a); 
            return a;
        },
        // 发送消息
        sendMsg() {
            if (!this.massage) return;
            let param = {
                isRoomPreview: 0, //0: 正常进入直播间，1：预览直播间 待取值
                liveId: this.liveInfo.liveId,
                msg: this.massage
            }
            liveChat(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.$refs.scrollBox.addMsg(this.massage);
                    this.massage = "";
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            })
        },
        // @她
        qUser(item) {
            this.massage = `@${item.nickname} `;
            this.showUserCard = false;

            setTimeout(() => {
                let input = document.getElementsByClassName('sendInput')[0].querySelector('input');
                input.focus();
            }, 100);
        },
        // 头像列表
        roomInfo() {
            let params = {
                liveId: this.liveInfo.liveId
            }
            roomInfo(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.$store.commit("setState", { avatarList: res.data });
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(()=> {})
        },
        // 用户进房之后的主播基本信息
        anchorBase() {
            let params = {
                liveId: this.liveInfo.liveId,
                anchorId: this.liveInfo.anchorId
            }
            anchorBase(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let obj = JSON.parse(JSON.stringify(res.data)), liveInfo = JSON.parse(JSON.stringify(this.liveInfo));
                    liveInfo.zb = obj.zb;
                    this.anchorDetail = obj;
                    this.$store.commit("setState", { liveInfo });
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(()=> {})
        },
        // 关注 pk对方主播  follow
        followOtherSide() {
            let params = {
                isFollow: this.pkInfo.follow,
                targetId: this.pkInfo.targetId
            }
            this.follow(params);
        },
        // 关注 - 取关
        follow(item) {
            let params = {
                isFollow: item.follow ? false : true,
                targetId: item.anchorId
            }
            liveFollow(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let str = item.follow ? this.$t('my.follow.Unfollowed') : this.$t('my.follow.followed');
                    this.$toast(str);
                    this.anchorBase();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 前往用户卡片
        goUserCard(item, key) {
            if (key && item[key]) return;
            let obj = JSON.parse(JSON.stringify(item));
            if (!obj.uid) {
                obj.uid = obj.anchorId;
            }
            this.$store.commit("setState", { betPramas: obj });
            this.showUserCard = true;
        },
        // 刷新方法
        refresh(fun) {
            this[fun]();
        },
        // 关闭礼物
        closeGiftList() {
            this.showGiftList = false;
        },
        // 用户卡片界面关闭
        closeUserCard() {
            this.showUserCard = false;
        },
        // 观众列表关闭
        closeAudienceList() {
            this.showAudienceList = false;
        },
        // 关闭礼物榜单
        closeTopList() {
            this.showTopList = false;
        },
        // 关闭弹框 无需额外处理
        colsePopup(val) {
            this[val] = false;
        },
        // 打开弹框 无需额外处理
        openPopup(val) {
            this[val] = true;
        },
        // 关闭 
        colseToPay() {
            this.TCPlayer.play();
            this.showToPay = false;
            let isSuperMan = false;

            if (this.member.badgeList && this.member.badgeList.length > 0) {
                let index = this.member.badgeList.findIndex(item => {
                    return item == 4
                });

                if (index != -1) {
                    isSuperMan = true;
                }
            }
            if (this.liveInfo.type && this.liveInfo.type == 1 && !isSuperMan) {
                this.timing();
            }
        },
        // 计时付费倒计时
        timing() {
            clearInterval(this.timingTime);
            let that = this;
            this.timingTime = setInterval(() => {
                that.chargeRoom();
            }, 59*1000);
        },
        // 计时房间付费
        chargeRoom() {
            let params = {
                anchorId: this.liveInfo.anchorId,
                liveId: this.liveInfo.liveId
            }
            chargeRoom(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    console.log(res.code);
                } else {
                    // 4003 余额不足
                    if (res.code && res.code == 4003 ) {
                        this.$emit('openPopup', 'showShortBalance');
                        this.showToPay = true;
                        if (this.TCPlayer) this.TCPlayer.pause();
                    } else {
                        if (res.msg) this.$toast(res.msg);
                    }
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 复制
        copyClick(value) {
            if (utils.copy(value)) {
                this.$toast(this.$t('common.copySuccess'));
            } else {
                this.$toast(this.$t('common.copyLost'));
            }
        },
        
        // 匹配用户等级图片
        getImg(val) {
            if (val <= 10) {
                return require("../../assets/img/noble/level/level_1-10.png");
            }

            if (10 < val && val <= 20) {
                return require("../../assets/img/noble/level/level_11-20.png");
            }

            if (20 < val && val <= 30) {
                return require("../../assets/img/noble/level/level_21-30.png");
            }

            if (30 < val && val <= 40) {
                return require("../../assets/img/noble/level/level_31-40.png");
            }

            if (40 < val && val <= 50) {
                return require("../../assets/img/noble/level/level_41-50.png");
            }

            if (50 < val && val <= 60) {
                return require("../../assets/img/noble/level/level_51-60.png");
            }

            if (60 < val && val <= 70) {
                return require("../../assets/img/noble/level/level_61-70.png");
            }

            if (70 < val && val <= 80) {
                return require("../../assets/img/noble/level/level_71-80.png");
            }

            if (80 < val && val <= 90) {
                return require("../../assets/img/noble/level/level_81-90.png");
            }

            if (90 < val) {
                return require("../../assets/img/noble/level/level_91-105.png");
            }
        },
        // 退出直播间
        closeLive() {
            this.$store.commit("setState", { liveInfo: {} });
            this.$store.dispatch('getGroupList', String(this.leftMenu[0].roomId));

            if (this.fromPath && this.fromPath != '/entryLiveBefore' && this.fromPath != '/liveRoom' && this.fromPath != '/offRoom') {
                this.$router.push(this.fromPath);
            } else {
                this.$router.push('/home');
            }
        },
        // 红包ICON点击
        clickRedIcon() {
            let params = {
                uid: this.member.uid,
                redPacketId: this.redPacket.id,
                language: 'YN'
            }
            this.$store.commit("setState", { isLoadForLoad: true });
            grabRedPacket(params).then(res => {
                this.$store.commit("setState", { isLoadForLoad: false });
                if (res.code === 0 || res.code === '0') {
                    this.updateRedket(res.data);
                } else if(res.code == 14014) {
                    this.updateRedket(res);
                } else {
                    if (res.msg) this.updateRedketError(res.msg);
                }
            }).catch(() => {
                this.$store.commit("setState", { isLoadForLoad: false });
            })
        },
        // 抢红包异常处理
        updateRedketError(msg) {
            this.redPacketTipsIn = msg;
            this.isShowRedPacket = true;
        },
        // 更新抢红包数据
        updateRedket(val) {
            this.redPacketData = val;
            this.isShowRedPacketIn = true;
        },
    },
    beforeRouteEnter(to, from, next) {
        next((e) => {
          if (from.path != '/liveRoom') {
            e.fromPath = from.path;
            e.$store.commit("setState", { fromPath: from.path });
          }
        });
    },
    beforeRouteLeave(to, from) {
        this.destroyCurrent();
    }
    // beforeDestroy() {
    //     console.log(999);
    //     if (this.player) {
    //         this.player.stopAnimation();
    //     }
    //     clearInterval(this.bgTimer);
    //     clearInterval(this.comboGiftTimer);
    //     clearInterval(this.comboGift2Timer);
    //     clearInterval(this.redPacketTimer);
    //     clearInterval(this.roomPreviewTimer);
    //     this.TCPlayer.dispose()
    // }
}
</script>

<style lang="scss" scoped>
.liveRoom {
    height: 100vh;
    overflow: hidden;
    position: relative;
    font-size: 0.24rem;

    &.pkRoom {
        background-image: url('../../assets/img/live/pk/pkBg.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;


        .liveBox {
            top: 1.48rem;
            #live{
                height: 40vh;
            }
        }
    }

    .liveRoomBg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 1;
    }

    .watermark1 {
        position: absolute;
        top: 0.8rem;
        right: 0.4rem;
        width: 1.36rem;
        z-index: 3;
    }

    .watermark2 {
        position: absolute;
        top: 2rem;
        left: 0.24rem;
        width: 1.36rem;
        z-index: 3;
    }

    .watermark3 {
        position: absolute;
        left: 0.24rem;
        width: 1.36rem;
        bottom: 40%;
        z-index: 3;
    }

    .watermark4 {
        position: absolute;
        bottom: 1rem;
        right: 0.24rem;
        width: 1.36rem;
        z-index: 3;
    }

    .liveBox {
        position: absolute;
        top: -0.05rem;
        left: 0;
        z-index: 2;
        width: 100vw;
        height: 100vh;

        #live {
            width: 100vw;
            height: 100vh;
            position: relative;
            z-index: 9999;
        }
    }

    .liveSwipe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // height: 100vh;
        z-index: 4;
        color: #fff;

        .liveHead {
            margin-top: 0.24rem;
            padding-left: 0.24rem;
            position: relative;
            z-index: 2;

            .liveHeadLeft {
                font-size: 0.24rem;
                background-color: rgba(0, 0, 0, 0.36);
                border-radius: 0.5rem;
                padding: 0.02rem 0.05rem 0.02rem 0.02rem;

                img {
                    width: 0.52rem;
                    height: 0.52rem;
                    border-radius: 50%;
                    margin-right: 0.1rem;
                    margin-left: 0.05rem;
                }

                .focusOn {
                    width: 0.4rem;
                    height: 0.4rem;
                    margin-right: 0;
                }
            }

            .liveHeadRight {
                font-size: 0.2rem;

                .userIcon {
                    white-space: nowrap;
                    min-width: 3.2rem;
                    overflow-x: scroll;
                    -webkit-overflow-scrolling: touch;
                    text-align: right;
                    /* 隐藏滚动条 */
                    scrollbar-width: none;
                    /* firefox */
                    -ms-overflow-style: none;
                    /* IE 10+ */

                    &::-webkit-scrollbar {
                        width: 0;
                        /* Safari,Chrome 隐藏滚动条 */
                        height: 0;
                        /* Safari,Chrome 隐藏滚动条 */
                        display: none;
                        /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
                    }

                    .userIconItem {
                        display: inline-block;
                        margin-right: 0.1rem;

                        &.isNoble6 {
                            padding: 0.08rem 0.1rem 0.05rem;
                            background-image: url("../../assets/img/noble/headEmblem/6.png");
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }

                        &.isNoble7 {
                            padding: 0.08rem 0.1rem 0.05rem;
                            background-image: url("../../assets/img/noble/headEmblem/7.png");
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }

                        &.isNoble8 {
                            padding: 0.08rem 0.1rem 0.05rem;
                            background-image: url("../../assets/img/noble/headEmblem/8.png");
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }

                        &.isNoble9 {
                            padding: 0.08rem 0.1rem 0.05rem;
                            background-image: url("../../assets/img/noble/headEmblem/9.png");
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }

                        &.isNoble10 {
                            padding: 0.08rem 0.1rem 0.05rem;
                            background-image: url("../../assets/img/noble/headEmblem/10.png");
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }
                    }

                    span {
                        width: 0.52rem;
                        height: 0.52rem;
                        border-radius: 50%;

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            width: 0.52rem;
                            height: 0.52rem;
                            border-radius: 50%;
                        }
                    }
                }
                .userMore {
                    padding-right: 0.2rem;
                }

            }
        }

        .hotIcon {
            position: absolute;
            top: 1.4rem;
            right: 0;
            z-index: 6;
            width: 0.44rem;
        }

        .previewBox {
            position: absolute;
            top: 1.52rem;
            left: 0.2rem;
            z-index: 2;
            padding: 0.1rem 0.24rem;
            background-color: rgba(0,0,0,0.3);
            border-radius: 0.1rem;

            span {
                color: #eb457e;
            }
        }

        .pkAnchorNickname {
            position: absolute;
            top: 1.1rem;
            left: 50%;
            z-index: 2;
            vertical-align: middle;
            padding: 0 0.1rem;
            background-color: rgba(0,0,0,0.5);
            border-radius: 1rem;

            img {
                display: inline-block;
                margin-left: 0.05rem;
                vertical-align: middle;
            }
        }

        .pkStatus {
            position: absolute;
            top: calc(40vh + 1.05rem);
            left: 0;
            right: 0;
            z-index: 2;
            display: inline-block;
            background-color: rgba(0, 0, 0, 0.3);
            color: #d10cd1;
            padding: 0.05rem 0.2rem;
            border-top-left-radius: 0.3rem;
            border-top-right-radius: 0.3rem;
            margin: 0 auto;
            width: fit-content;
            vertical-align: middle;

            img {
                vertical-align: middle;
            }
        }

        .pkSvgBox {
            position: absolute;
            top: calc(40vh + 1.5rem);
            left: 0;
            z-index: 3;
            width: 60vw;
            height: 0.56rem;


            #pkSvg {
                position: absolute;
                right: -0.73rem;
                top: -0.12rem;
                height: 0.56rem;
                width: 3rem;
            }
        }
        .pkNum {
            position: absolute;
            top: calc(40vh + 1.5rem);
            left: 0;
            z-index: 2;
            width: 100%;
            display: flex;

            &>div {
                position: relative;
                height: 0.28rem;
                z-index: 3;

                &:first-child {
                    text-align: left;
                    padding-left: 0.2rem;
                    width: 60vw;

                    img {
                        position: absolute;
                        right: -0.1rem;
                        z-index: 4;
                        top: -0.13rem;
                    }
                }

                &:last-child {
                    text-align: right;
                    padding-right: 0.2rem;
                    width: 40vw;
                }
            }

            .red {
                background: -webkit-linear-gradient(#ff779a,#ff3967);
                background: linear-gradient(#ff779a,#ff3967);
                z-index: 9;
            }

            .blue {
                background: -webkit-linear-gradient(#a0c3fc,#4289f9);
                background: linear-gradient(#a0c3fc,#4289f9);
            }
        }

        .pkRuleIcon {
            position: absolute;
            top: calc(40vh + 2rem);
            left: 0;
            right: 0;
            z-index: 2;
            margin: 0 auto;
            width: -moz-fit-content;
            width: fit-content;
            background-color: rgba(0,0,0,0.5);
            padding: 0.1rem 0.2rem;
            border-radius: 1rem;

            i {
                color: #c5c5c5;
            }
        }

        .userGiftBox {
            margin-top: 0.1rem;
            position: relative;
            z-index: 2;
            
            &>div {
                font-size: 0.2rem;
                margin-left: 0.24rem;
                border-radius: 0.3rem;
                padding: 0.07rem 0.12rem;

                &.giftList {
                    background: -webkit-linear-gradient(left,#ff7d59,#ff6960,#ff497d);
                    background: linear-gradient(90deg,#ff7d59,#ff6960,#ff497d);
                }

                &.userNum {
                    background-color: rgba(0, 0, 0, 0.36);
                    margin-left: 0.2rem;
                }
            }

            img {
                margin-right: 0.1rem;
                width: 0.28rem;
            }
        }

        .gameInfoBox {
            position: absolute;
            left: 0;
            &.gameInfoBox1 {
                bottom: calc(40% + 1.9rem);
            }

            &.gameInfoBox2 {
                bottom: calc(40% + 0.6rem);
            }

            &.animation {
                -webkit-animation: spin 4s linear 4s 4 alternate;
                animation: spin 4s linear infinite;
            }


            @-webkit-keyframes spin {
                0% {
                    left: 100vw;
                }
                30% {
                    left: 0;
                }
                70% {
                    left: 0;
                }
                100% {
                    left: -100vw;
                }
            }
            
            @keyframes spin {
                0% {
                    left: 100vw;
                }
                30% {
                    left: 0;
                }
                70% {
                    left: 0;
                }
                100% {
                    left: -100vw;
                }
            }
        }

        .floatingScreenBox {
            position: absolute;
            // left: 100vw;
            left: 0;

            &.winFloatingScreenBox {
                top: 2.8rem;
            }

            &.giftFloatingScreenBox {
                top: 2.1rem;
            }

            &.animation {
                -webkit-animation: animationLottery 12s linear 12s 5 alternate;
                animation: animationLottery 12s linear infinite;
            }


            @-webkit-keyframes animationLottery {
                0% {
                    left: 100vw;
                }
                100% {
                    left: -120vw;
                }
            }
            
            @keyframes animationLottery {
                0% {
                    left: 100vw;
                }
                100% {
                    left: -120vw;
                }
            }


            .winFloatingScreen {
                background: -webkit-linear-gradient(left,#ff54a8,#fe7bb3);
                background: linear-gradient(90deg,#ff54a8,#fe7bb3);
                font-size: 0.2rem;
                margin-left: 0.24rem;
                border-radius: 0.3rem;
                padding: 0.09rem 0.2rem 0.1rem 1.32rem;
                position: relative;
                white-space: nowrap;

                img {
                    width: 0.88rem;
                    position: absolute;
                    left: 0.16rem;
                    top: -0.2rem;
                    z-index: 6;
                }
            }

            
        }

        .giftFloatingScreen {
            background: -webkit-linear-gradient(left,#ff54a8,#fe7bb3);
            background: linear-gradient(90deg,#ff54a8,#fe7bb3);
            font-size: 0.2rem;
            margin-left: 0.24rem;
            border-radius: 0.3rem;
            padding: 0.09rem 0.2rem 0.07rem 1.32rem;
            position: relative;
            margin-top: 1rem;

            img {
                width: 0.88rem;
                position: absolute;
                left: 0.16rem;
                top: -0.16rem;
                z-index: 6;
            }
        }

        .continuousDeliveryBox {
            margin-top: 1.3rem;
            padding-left: 0.24rem;
            font-size: 0.28rem;

            .continuousDeliveryItem {
                margin-bottom: 0.2rem;
            }
            .continuousDelivery {
                background-color: rgba(0, 0, 0, 0.6);
                border-radius: 0.5rem;
                padding: 0 1rem 0 0.04rem;
                margin-right: 0.12rem;
                position: relative;

                .profilePicture {
                    width: 0.6rem;
                    height: 0.6rem;
                    border-radius: 50%;
                }

                .continuousDeliveryCont {
                    text-align: left;
                    margin: 0 0.1rem;
                    padding: 0.02rem 0;
                    font-size: 0.24rem;

                    .userName {
                        color: #8cd5ff;
                    }

                    .giftName {
                        font-size: 0.2rem;
                    }
                }

                .giftIcon {
                    width: 0.72rem;
                    height: 0.72rem;
                    position: absolute;
                    top: -0.05rem;
                    right: 0.2rem;
                }
            }

            .continuousNum {
                font-size: 0.44rem;
            }
        }

        #enteringSpecialEffects {
            max-width: 100vw;
            position: absolute;
            left: 100vw;
            text-align: left;
            z-index: 2;
            display: inline-block;
            padding: 0.08rem;
            border-radius: 0.2rem;
            white-space: nowrap;

            .nobleLevel {
                position: relative;
                height: 0.35rem;
                width: 0.7rem;
                display: inline-block;
                vertical-align: top;
                margin-right: 0.05rem;

                img {
                    width: 0.7rem;
                    height: 0.35rem;
                }

                span {
                    position: absolute;
                    right: 0.06rem;
                    color: #fff;
                    line-height: 0.35rem;
                    font-size: 0.2rem;

                    &.small {
                        right: 0.12rem;
                    }
                }
            }

            .enterName {
                max-width: 50vw;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &.level10 {
                background-color: #d9ae62;
            }

            &.level20 {
                background-color: #80bed0;
            }

            &.level30 {
                background-color: #d676ac;
            }

            &.level40 {
                background-color: #b575df;
            }

            &.level50 {
                background-color: #afc25b;
            }

            &.level60 {
                background-color: #ff8492;
            }

            &.level70 {
                background-color: #ff926a;
            }

            &.level80 {
                background-color: #f9c06d;
            }

            &.level90 {
                background-color: #f27f6d;
            }

            &.level100 {
                background-color: #f48161;
            }

            &.enter {
                -webkit-animation: spins 4s linear 4s 4 alternate;
                animation: spins 4s linear infinite;
            }


            @-webkit-keyframes spins {
                0% {
                    left: 100vw;
                }
                40% {
                    left: 0.28rem;
                }
                80% {
                    left: 0.28rem;
                }
                100% {
                    left: -100vw;
                }
            }
            
            @keyframes spins {
                0% {
                    left: 100vw;
                }
                40% {
                    left: 0.28rem;
                }
                80% {
                    left: 0.28rem;
                }
                100% {
                    left: -100vw;
                }
            }
        }

        .scrollBox {
            max-height: 3.56rem;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            width: 5.2rem;
            margin-left: 0.24rem;
            position: absolute;
            bottom: 1rem;
            left: 0;
            z-index: 6;
        }

        .hasMsgTips {
            position: absolute;
            left: 0.28rem;
            bottom: 0.9rem;
            display: inline-block;
            z-index: 7;
            padding: 0.05rem 0.2rem;
            background-color: #fff;
            color: #626872;
            border-radius: 0.2rem;

            span {
                color: #c41f1a;
            }
        }

        .liveBottom {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 0 0.24rem 0.2rem;
            z-index: 10;

            .liveBottomLeft {
                .sendInput {
                    background: rgba(0, 0, 0, 0.1);
                    width: 2rem;
                    padding: 0.04rem 0.16rem;
                    font-size: 0.24rem;
                    display: flex;
                    align-items: center;
                    border-radius: 0.5rem
                }

                img {
                    width: 0.56rem;
                    margin-left: 0.2rem;
                }
            }

            .liveBottomRight {
                img {
                    width: 0.56rem;
                    margin-left: 0.2rem;

                    &:nth-child(1) {
                        margin-left: 0;
                    }
                }
            }
        }

        .game {
            position: absolute;
            right: 0.24rem;
            background-color: rgba(0,0,0,0.3);
            border-radius: 0.1rem;
            z-index: 9;

            img {
                width: 0.72rem;
                margin: 0.1rem 0.1rem 0 0.1rem;
            }

            &.game1 {
                bottom: 3.4rem;
            }

            &.game2 {
                bottom: 1.9rem;
            }

            p {
                background-color: rgba(0,0,0,0.4);
                width: 1rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &.red {
                bottom: 5rem;

                img {
                    width: 0.72rem;
                    margin-bottom: 0.1rem;
                }

                i {
                    position: absolute;
                    right: -0.1rem;
                    top: -0.1rem;
                    font-size: 0.28rem;
                    z-index: 10;
                }
            }
        }

        #svgGiftBox {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .upgradeBox {
            background-color: #fff;
            width: 5rem;
            min-height: 6rem;
            border-radius: 0.2rem;
            overflow: hidden;
            color: #dc8915;
            font-size: 0.32rem;
            padding-bottom: 0.4rem;

            span {
                font-size: 0.4rem;
            }

            img {
                width: 5rem;
                margin-bottom: 0.3rem;
            }

            .btn {
                font-size: 0.28rem;
                background-color: #dc8915;
                color: #fff;
                border-radius: 0.4rem;
                display: inline-block;
                padding: 0.05rem 0.4rem;
                margin-top: 0.3rem;
                line-height: 0.5rem;
            }
        }
    }
}


</style>
<style lang="scss">
.redPacketDialog {
    background: rgba(0, 0, 0, 0) !important;
    color: #fff;
    font-size: 0.24rem;

    .redPacketDialogBox {
        position: relative;

        img {
            width: 5.2rem;
        }
    }

    .redPacketDialogOpen {
        position: absolute;
        bottom: 1.5rem;
        text-align: center;
        width: 100%;
        padding: 0 0.9rem;
        color: #ebcb5e;
        font-size: 0.3rem;

        p {
            font-size: 0.4rem;
            font-weight: bold;
        }
    }

    .redPaccketDialogTips {
        position: absolute;
        top: 0.6rem;
        padding: 0 0.28rem;
        text-align: center;
        width: 100%;
        font-size: 0.3rem;
    }
}

.gameListDialog {
    background: rgba(0, 0, 0, 0.7) !important;
    color: #fff;
    font-size: 0.24rem;
}

.liveRoom {
    .van-overlay {
        background: rgba(0, 0, 0, 0.3) !important;
    }

    .van-tab {
        color: #fff;

        &.van-tab--active {
            color: #fff !important;
            background-color: #eb457e;
        }
    }

    .van-tabs--line .van-tabs__wrap {
        height: 0.56rem !important;

        .van-tabs__nav {
            background: #555 !important;
        }
    }

    .van-tabs__line {
        display: none;
    }

    .sendInput {
        &::after {
            border-color: transparent !important;
        }

        .van-field__control {
            color: rgba($color: #fff, $alpha: 0.6) !important;
        }
    }
}

.confirmDialog .shortBalanceBox {
    padding: 0.4rem 0.28rem;
}

.pkRuleBox {
    font-size: 0.28rem;

    .pkRuleTitle {
        font-size: 0.4rem;
        text-align: center;
        padding: 0.1rem 0;
    }

    .pkRuleCont {
        max-height: 45vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        text-align: left;
        padding: 0 0.1rem;
    }
}
</style>