<template>
    <van-pull-refresh v-model="isLoad" :disabled="isRefreshDisable" @refresh="onRefresh">
        <template #pulling>
            <van-loading />
        </template>

        <!-- 释放提示 -->
        <template #loosing>
            <van-loading />
        </template>

        <!-- 加载提示 -->
        <template #loading>
            <van-loading />
        </template>
        <div id="tabLive" class="liveBox" :style="{ height: liveBoxHeight }">
            <div class="title" :style="{ top: topDom }">
                <span>{{ $t("footer.live") }}</span>

                <div @click="showSearch = true" class="title_ellipsis"  :style="{ top: topDom }">
                    <img src="../../assets/img/live/search.png" />
                </div>
            </div>

            <div class="barTitle">
                <div class="flexCenter">
                    <div v-for="(item, index) in bar" :key="`${index}--bar`" class="item" @click="changeTab(item, index)"
                        :class="{ 'active': activeBar == index }">
                        {{ item.name }}
                    </div>
                </div>
            </div>

            <div class="live liveMidTab" :style="{ height: liveHeight }">
                <div v-show="tagLists.length > 0" class="gameItem">
                    <div v-for="(xitem, xindex) in tagLists" :key="`${xindex}--tagLists`" @click="goLive(xitem)"
                        class="gameCont_item live_item" :class="{ 'bigLiveItem': xitem.type == -1 }">
                        <!-- flexBetween -->
                        <div v-if="xitem.type !== -1" class="liveHead">
                            <!-- <div v-if="xitem.liveStartLottery && xitem.liveStartLottery.length > 0" class="liveHeadCont">
                                <p v-for="(sitem, sindex) in xitem.liveStartLottery"
                                    :key="`${sindex}-home-xitem.liveStartLottery`">
                                    {{ sitem.lotteryName }}
                                </p>
                            </div> -->
                            <img v-show="xitem.webStatus" class="liveLock" src="../../assets/img/home/app.png" />
                            <img v-show="!xitem.webStatus && xitem.pking" class="liveLock" src="../../assets/img/home/pk.png" />
                            <img v-show="!xitem.webStatus && xitem.type == 3 && !xitem.pking" class="liveLock" src="../../assets/img/home/liveLock.png" />
                            <img v-show="!xitem.webStatus && (xitem.type == 1 || xitem.type == 2) && !xitem.pking" class="liveLock" src="../../assets/img/home/money.png" />
                        </div>
                        <img class="liveBg" v-if="xitem.type !== -1" v-real-img="xitem.avatar" />
                        <img v-if="xitem.type == -1" class="liveBg" v-real-img="xitem.content" />
                        <div v-if="xitem.type !== -1" class="liveBottom flexBetween">
                            <div>{{ xitem.nickname }}</div>
                            <div>
                                <img src="../../assets/img/home/liveUser.png" />
                                {{ xitem.rq }}
                            </div>
                        </div>
                    </div>

                </div>

                <div v-show="tagLists.length == 0" class="noData">
                    <img src="../../assets/img/record/noData.png" />
                    <p>{{ $t('common.noData') }}</p>
                </div>
            </div>
            <!-- 搜索弹框 -->
            <searchComponents :showSearch="showSearch" @colseSearch="colseSearch"></searchComponents>

            <otherWeb></otherWeb>
        </div>
    </van-pull-refresh>
</template>

<script>
import { tagList } from "@/api/index";
import { configTag } from "@/api/config";
import { mapState } from 'vuex';
import searchComponents from "@/components/search.vue";
import otherWeb from "@/pages/components/otherWeb.vue";
import interLive from '@/pages/mixin/interRoom';
export default {
    name: "live",
    mixins: [interLive],
    components: {
        otherWeb,
        searchComponents
    },
    data() {
        return {
            bar: [],
            activeBar: 1,
            tagLists: [],
            showSearch: false, //搜索弹框是否展示
            isLoad: false,
            isRefreshDisable: false,
            scrollTop: 0,
            height: 667
        }
    },
    computed: {
        ...mapState(['member', 'adverts', 'scrollHeight', 'scrollHeight', 'showDownApp']),
        isManage() {
            let flag = false;
            if (this.member && this.member.badgeList && this.member.badgeList.length > 0) {
                let index = this.member.badgeList.findIndex(item => {
                    return item == '4'
                });

                if (index != -1) {
                    flag = true;
                }
            }
            return flag;
        },
        topDom() {
            let top = '0px';
            if (this.showDownApp) {
                top = '1.1rem'
            }
            return top;
        },
        liveBoxHeight() {
            let height = `calc(100vh - 1rem)`
            if (this.showDownApp) {
                height = `calc(100vh - 2rem)`
            }
            return height;
        },
        liveHeight() {
            let height = `${this.height}px`;
            if (this.showDownApp) {
                height = `calc(${this.height}px - 1rem)`
            }
            return height;
        }
    },
    watch: {
        scrollTop(newval) {
            if (newval > 0) {
                this.isRefreshDisable = true
            } else {
                this.isRefreshDisable = false
            }
        }
    },
    created() {
        this.configTag();
    },
    mounted() {
        this.safariHacks();
        // 解决滚动条与下拉的冲突
        setTimeout(() => {
            const vantList = document.querySelector('.liveMidTab')
            if (vantList) {
                this.resolveConflict(vantList)
            }
        }, 100);
    },
    methods: {
        // 界面下方高度
        safariHacks() {
            let windowsVH = this.windowsVH = window.innerHeight,
                topH = document.getElementsByClassName('title')[0].offsetHeight,
                barH = document.getElementsByClassName('barTitle')[0].offsetHeight,
                footerH = document.getElementsByClassName('footer')[0].offsetHeight;
                if (!footerH) {
                footerH = 54;
                }
            this.height = `${windowsVH - footerH - topH - barH}`;
        },
        /**
         * @description: 下拉刷新和滚动事件冲突
         * @param {*}
         * @return {*}
         */
        resolveConflict(val) {
            const vantList = val
            vantList.addEventListener('touchmove', () => {
                this.scrollTop = vantList.scrollTop
            })
            vantList.addEventListener('touchend', () => {
                this.scrollTop = vantList.scrollTop
            })
        },
        onRefresh() {
            this.configTag();
        },
        // 关闭搜索弹框
        colseSearch() {
            this.showSearch = false;
        },
        // 点击直播间
        goLive(item) {
            if (!this.member) return this.$router.push('/login');
            if (item.isAd && this.CheckUrl(item.adJumpUrl)) {
                // window.location.href = item.adJumpUrl;
                this.$store.commit("setState", { otherWebUrl: item.adJumpUrl, isShowOtherWeb: true });
            }
            if (!item.anchorId) return;
            let liveList = this.tagLists.filter(item => {
                return item.liveId && !item.isAd
            });
            this.$store.commit("setState", { liveList });


            this.getAnchorBase(item)
        },
        // 校验是否是有效URL
        CheckUrl(url) {
            let reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
            return !!reg.test(url);
        },
        // 开播列表
        tagList(type) {
            let params = {
                uid: this.member && this.member.uid ? this.member.uid : "",
                type
            }
            this.tagLists = [];
            tagList(params).then(res => {
                this.isLoad = false;
                if (res.code === 0 || res.code === '0') {
                    this.tagLists = this.getTagList(res.data);
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
                this.isLoad = false;
            });
        },
        // 处理开播数据
        getTagList(arr) {
            let result = JSON.parse(JSON.stringify(arr)), res = [];
            if (this.adverts.length > 0 && arr.length > 6) {
                let advertsIndex = this.adverts.findIndex(item => {
                    return item.type == 2
                });

                if (advertsIndex != -1) {
                    for (let i = 0; i < arr.length; i = i + 6) {
                        if (i + 6 < arr.length) {
                            res.push(arr.slice(i, i + 6));
                        } else {
                            res.push(arr.slice(i));
                        }
                    }
                    res.forEach((item) => {
                        return item.length === 6 ? item.push({
                            ...this.adverts[advertsIndex],
                            type: -1
                        }) : item;
                    })
                    result = [].concat.apply([], res);
                }
            }
            return result;
        },
        // 主播标签
        configTag() {
            let str = this.member && this.member.uid ? this.member.uid : "";
            this.isLoad = true;
            configTag(str).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.bar = res.data;
                    if (res.data.length > 0) {
                        this.tagList(1);
                    }
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 顶部tab
        changeTab(item, index) {
            this.activeBar = index;
            this.tagList(item.type)
        },
    },
    beforeRouteEnter(to, from, next) {
        next((e) => {
            if (e.scrollHeight && e.scrollHeight.live && document.getElementsByClassName('liveMidTab')[0]) {
                document.getElementsByClassName('liveMidTab')[0].scrollTop = e.scrollHeight.live;
            }
        });
    },
    beforeRouteLeave(to, from, next) {
        let scrollHeight = JSON.parse(JSON.stringify(this.scrollHeight));
        scrollHeight.live = document.getElementsByClassName('liveMidTab')[0].scrollTop;
        this.$store.commit("setState", { scrollHeight });
        next();
    },
}
</script>

<style lang="scss" scoped>
.liveBox {
    padding-top: 0.88rem;
    height: calc(100vh - 1rem);

    .title {
        position: fixed;
        top: 0px;
        left: 0;
        z-index: 10;
        background-color: #fff;
        font-size: 0.32rem;
        text-align: center;
        height: 0.88rem;
        line-height: 0.88rem;
        background: -webkit-linear-gradient(left, #f86fab, #fa8faf);
        background: linear-gradient(90deg, #f86fab, #fa8faf);
        color: #fff;
        width: 100%;
        font-size: 0.32rem;
    }

    .title_ellipsis {
        position: fixed;
        top: 0;
        right: 0;
        padding-right: 0.28rem;
        box-sizing: border-box;
        vertical-align: middle;

        img {
            width: 0.36rem;
            height: 0.36rem;
            vertical-align: middle;
        }
    }

    .barTitle {
        width: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        background: #fff;
        height: 0.76rem !important;
        scroll-behavior: smooth;
        z-index: 10;
        padding: 0 0.28rem;

        /* 隐藏滚动条 */
        scrollbar-width: none;
        /* firefox */
        -ms-overflow-style: none;
        /* IE 10+ */

        &::-webkit-scrollbar {
            width: 0;
            /* Safari,Chrome 隐藏滚动条 */
            height: 0;
            /* Safari,Chrome 隐藏滚动条 */
            display: none;
            /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
        }

        div .item {
            text-align: center;
            height: 0.56rem;
            padding: 0 0.4rem;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 0.56rem;
            box-sizing: border-box;
            color: #666666;
            font-size: 0.28rem;
            margin-top: 0.2rem;
            border-radius: 0.4rem;

            &.active {
                color: #fff;
                background: -webkit-linear-gradient(left, #ff54a8, #ffb3b2);
                background: linear-gradient(90deg, #ff54a8, #ffb3b2);
            }
        }
    }

    .live {
        background-color: #fff;
        font-size: 0.28rem;
        color: #626872;
        margin-bottom: 0.2rem;
        position: absolute;
        width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        .noData {
            top: 40%;
        }

        .gameItem {
            padding: 0 0 0.2rem 0.28rem;
            text-align: left;
        }

        .gameCont_item {
            height: 4rem;
            position: relative;
            width: calc(50% - 0.2rem);
            display: inline-block;
            margin-right: 0.2rem;
            margin-top: 0.2rem;
            box-shadow: 0px 1px 2px 1px rgba(194, 217, 249, 0.5);
            border-radius: 0.2rem;
            vertical-align: middle;

            &.live_item {
                box-shadow: 0px 1px 2px 1px rgba(194, 217, 249, 0);
                overflow: hidden;

                .liveBg {
                    height: 4rem;
                    width: 100%;
                    object-fit: cover;
                }

                .liveHead {
                    position: absolute;
                    width: 100%;
                    text-align: right;

                    .liveLock {
                        width: 0.4rem;
                        margin: 0.1rem 0.1rem 0 0;
                    }

                    .liveHeadCont {
                        color: #fff;
                        background-image: url('../../assets/img/home/liveHeadBg.png');
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        width: 2.12rem;
                        min-height: 0.4rem;
                        line-height: 0.32rem;
                        font-size: 0.2rem;
                        padding-left: 0.2rem;
                        padding-top: 0.05rem;
                    }
                }

                .liveBottom {
                    position: absolute;
                    bottom: 0rem;
                    padding: 0.1rem 0.16rem;
                    color: #fff;
                    font-size: 0.28rem;
                    width: 100%;
                    background-color: rgba(0,0,0,0.3);

                    img {
                        width: 0.28rem;
                        margin-right: 0.05rem;
                    }
                }

                &.bigLiveItem {
                    width: calc(100% - 0.2rem);
                    height: 2rem;

                    .liveBg {
                        height: 2rem;
                    }
                }
            }

            .bg {
                width: 100%;
                height: 2rem;
                border-radius: 0.1rem 0.1rem 0 0;
            }

            &.gameCont_item_top {
                height: 2.4rem;

                .bg {
                    height: 2.4rem;
                }
            }

            .team {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 2.4rem;
                z-index: 10;
                color: #FFFFFF;

                .vs {
                    width: 0.7rem;
                    margin: 0 0.2rem;
                }
            }

            &.gameCont_itemAll {
                width: 100%;
                font-size: 0.32rem;
                height: 3.2rem;

                .bg {
                    height: 3.2rem;
                }

                .team {
                    height: 3.2rem;

                    .vs {
                        width: 1.2rem;
                    }
                }

                .name {
                    text-align: center;
                    width: 100%;
                    color: #fff;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 10;
                }
            }

            &.gameCont_itemAll_other {
                width: 100%;
                font-size: 0.32rem;
                height: 3.2rem;

                .bg {
                    width: 100%;
                    height: 2.4rem;
                }
            }

            .gameName {
                color: #626872;
                font-size: 0.24rem;
                text-align: center;
                margin-top: 0.05rem;
            }
        }
    }
}
</style>
<style lang="scss">
.liveBox {
    .van-tabs__wrap {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        /* 隐藏滚动条 */
        scrollbar-width: none;
        /* firefox */
        -ms-overflow-style: none;
        /* IE 10+ */

        &::-webkit-scrollbar {
            width: 0;
            /* Safari,Chrome 隐藏滚动条 */
            height: 0;
            /* Safari,Chrome 隐藏滚动条 */
            display: none;
            /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
        }

        .van-tab {
            padding: 0 0.28rem;
        }

        .van-tabs__nav--line {
            padding-bottom: 0.2rem;
        }
    }

    .van-tab__text--ellipsis {
        white-space: nowrap;
    }
}
</style>