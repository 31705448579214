<template>
  <div class="next transactionRecord">
    <div class="title">
      <span> {{$t('my.listView.transaciton')}} </span>
      <div class="fixed" @click="gaBack">
          <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12456" width="0.3rem" height="0.3rem"><path d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z" fill="#666666" p-id="12457"></path></svg>
      </div>

      <div class="betSearchBox flexBetween">
        <van-dropdown-menu active-color="#fff" class="betSearch">
         <van-dropdown-item :title="searchTypeActive.name" ref="searchItemType">
            <div class="searchItem">
              <div class="searchItem_item">
                <span v-for="(item, index) in typeList" :key="`${index}--typeList`" :class="searchTypeActive.id==item.id ? 'searchItem_item_active' : ''" @click="onConfirmType(item)">
                  {{ item.name }}
                </span>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>

        <van-dropdown-menu active-color="#fff" class="betSearch">
          <van-dropdown-item ref="searchItemDay">
            <template v-slot:title>
              <span v-if="dayList[searchDayActive] && dayList[searchDayActive].name">{{dayList[searchDayActive].name}}</span>
              <!-- <div v-else class="seatchTitle">
                <p>{{ getDate(currentStartDate) }}</p>
                <p>{{ getDate(currentEndDate) }}</p>
              </div> -->
            </template>
            <div class="searchItem">
              <div class="searchItem_item">
                <span v-for="(item, index) in dayList" :key="`${index}--dayList`" :class="searchDayActive==index ? 'searchItem_item_active' : ''" @click="onConfirm(index)">
                  {{ item.name }}
                </span>
              </div>
            </div>

            <div v-show="searchDayActive==5">
              <div class="customizeSearch borderBottom">
                <div @click="showStartDate=!showStartDate,showEndDate=false" class="customizeSearchItem flexBetween">
                  <div>{{$t('my.proxy.startDate')}}</div>
                  <div>{{ getDate(currentStartDate) }}<van-icon name="arrow" /></div>
                </div>

                <div v-show="showStartDate">
                  <van-datetime-picker
                    item-height="0.6rem"
                    v-model="currentStartDate"
                    :show-toolbar="false"
                    type="date"
                    :title="`${'my.proxy.selectDateTips'}`"
                    :min-date="minDate"
                  />
                </div>
              </div>

              <div class="customizeSearch">
                <div @click="showEndDate=!showEndDate,showStartDate=false" class="customizeSearchItem flexBetween">
                  <div>{{$t('my.proxy.endDate')}}</div>
                  <div>{{ getDate(currentEndDate) }} <van-icon name="arrow" /></div>
                </div>

                <div v-show="showEndDate">
                  <van-datetime-picker
                    item-height="0.6rem"
                    v-model="currentEndDate"
                    :show-toolbar="false"
                    type="date"
                    :title="`${'my.proxy.selectDateTips'}`"
                    :min-date="minDate"
                  />
                </div>
              </div>

              <div class="customizeBtnBox">
                <div @click="cancelSearch" class="customizeBtnCancel">{{$t('common.cancel')}}</div>
                <div @click="goSearch" class="customizeBtnConfirm">{{$t('common.sure')}}</div>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
    </div>

    <div class="transacitonList">
      <div style="height: calc(100vh - 3rem);" v-if="dataList.length>0">
        <van-pull-refresh v-model="isLoading" :disabled="isRefreshDisable" @refresh="onRefresh">
          <template #pulling>
            <van-loading />
          </template>

          <!-- 释放提示 -->
          <template #loosing>
            <van-loading />
          </template>

          <!-- 加载提示 -->
          <template #loading>
            <van-loading />
          </template>
          <!-- finished // 是否已加载完成，加载完成后不再触发load事件
             offset // 滚动条与底部距离小于offset时触发load事件
             immediate-check // 是否在初始化时立即执行滚动位置检查
            onLoad  // 滚动条与底部距离小于offset时触发onLoad()
            :immediate-check="flase" 
          -->
          <van-list
              v-model="loading"
              :finished="finished"
              style="height:calc(100vh - 2.8rem);overflow:scroll;"
              :offset="10" 
              :immediate-check="check" 
              :finish-text="`${$t('lottery.noMore')}`"
              @load="onLoad" 
            >
            <div class="transacitonList_item">
              <div v-for="(xitem, xindex) in dataList" :key="`${xindex}--dataList_arr`" @click="goOrderDetail(xitem)" class="transacitonList_item_item flexCenter">
                <div class="transacitonList_item_list flexBetween">
                  <div class="item_list_item">
                    <p class="listTime">{{ getDate(xitem.gmtCreate) }}</p>
                    <p class="listTime">{{ getTime(xitem.gmtCreate) }}</p>
                  </div>

                  <div class="item_list_cont">
                    {{ xitem.name }}
                  </div>

                  <div class="item_list_item_right">
                    <!-- :style="{color: xitem.color}"  {{ xitem.statusName }}-->
                    <p >{{ $t('my.detail') }}</p>
                    <p class="money" :class="{'green': xitem.goldCoin > 0 , 'red': xitem.goldCoin < 0}">
                      {{ xitem.goldCoin > 0 ? ('+'+ numFormat(digitalNumber(xitem.goldCoin))) : numFormat(digitalNumber(xitem.goldCoin)) }}
                    </p>
                    <img src="../../assets/img/record/go.png" />
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="showAll" v-show="finished"> {{$t('lottery.showAll')}} </div> -->
          </van-list>
          
        </van-pull-refresh>
      </div>
      <div v-else class="noData">
        <img src="../../assets/img/record/noData.png"/>
        <p>{{ $t('common.noData') }}</p>
        <!-- <div class="noDataBtn" @click="$router.push('/mine/finance/myDeposit')">
          {{ $t('my.finance.nowDeposit') }}
        </div> -->
      </div>

      <div class="footerBox flexBetween">
        <div>
          <p v-if="searchDayActive!=5 && dayList[searchDayActive] && dayList[searchDayActive].name">{{dayList[searchDayActive].name}}</p>
          {{ $t('my.income') }}:  <span>{{ numFormat(digitalNumber(totalIncome)) }}</span>
        </div>
        <div>
          {{ $t('my.expenditure') }}: <span>{{ numFormat(digitalNumber(totalExpenditure)) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { assetRecord, getAssetType } from "@/api/index";
import moment from "moment";
import utils from "@/utils/index";
import { mapState } from 'vuex';
export default {
  name: "transaciton",
  data() {
    return {
      value: 0,
      switch1: false,
      switch2: false,
      searchDayActive: 0,
      searchTypeActive: {}, //搜索条件第一个
      dayList: [],// "今日", "昨日", "近7日", "近30日", "自定义"
      typeList: [],
      isLoading: false,
      dataList: [],
      minDate: new Date(),
      maxDate: new Date(),
      currentStartDate: new Date(),
      currentEndDate: new Date(),
      showStartDate: false,
      showEndDate: false,
      finished: true, //是否已加载完
      page: 0, //页码
      loading: false, //下拉加载更多加载中动画
      check: false, //是否开始就检查
      // 滚动与下拉冲突
      scrollTop: 0,
      isRefreshDisable: false,
      totalExpenditure: 0, //支出
      totalIncome: 0, //收入
    };
  },
  watch: {
    scrollTop(newval) {
      if (newval > 0) {
        this.isRefreshDisable = true
      } else {
        this.isRefreshDisable = false
      }
    }
  },
  computed: {
    ...mapState(['member', 'betPramas', 'entry']),
  },
  created() {
    // let start = new Date().getTime() - 1000*60*60*24*29;
    // this.currentStartDate = this.minDate = new Date(start);

    this.getAssetType();
  },
  // mounted() {
  //   // 解决滚动条与下拉的冲突
  //   this.$nextTick(() => {
  //     const vantList = document.querySelector('.van-list')
  //     this.resolveConflict(vantList)
  //   })
  // },
  methods: {
    /**
     * @description: 下拉刷新和滚动事件冲突
     * @param {*}
     * @return {*}
     */
     resolveConflict(val) {
      const vantList = val;
      vantList.addEventListener('touchmove', () => {
        this.scrollTop = vantList.scrollTop
      })
      vantList.addEventListener('touchend', () => {
        this.scrollTop = vantList.scrollTop
      })
    },
    // 去往详情页
    goOrderDetail(item) {
      let betPramas = {
        searchDayActive: this.searchDayActive,
        searchTypeActive: this.searchTypeActive
      }

      // 自定义逻辑
      // if (this.dayList[this.searchDayActive].type == 6) {
      //   betPramas.currentStartDate = this.formatDate(this.currentStartDate);
      //   betPramas.currentEndDate = this.formatDate(this.currentEndDate);
      // }
      
      let obj = JSON.parse(JSON.stringify(item));
      // obj.name = this.searchTypeActive.name;
      this.$store.commit("setState", { betDetail: obj, betPramas });
      this.$router.push('/record/transaciton/orderDetail');
    },
    // 获取查询资产名称类型
    getAssetType() {
      getAssetType().then(res => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            let arr = [];
            arr = res.data.filter(item => {
              return item.queryType == 1
            });
            this.typeList = arr;


            let arr1 = [];
            arr1 = res.data.filter(item => {
              return item.queryType == 2
            });

            this.dayList = arr1;


            if (this.betPramas && JSON.stringify(this.betPramas) != '{}' && (this.betPramas.searchTypeActive || this.betPramas.searchDayActive)) {
              let betPramas = JSON.parse(JSON.stringify(this.betPramas));
              this.searchTypeActive = betPramas.searchTypeActive;
              this.searchDayActive = betPramas.searchDayActive;
              
              if (this.dayList[this.searchDayActive].type == 6) {
                this.currentStartDate = new Date(betPramas.currentStartDate);
                this.currentEndDate = new Date(betPramas.currentEndDate);
              }

              this.$store.commit("setState", { betPramas: {} });
            } else {
              this.searchTypeActive = res.data[0];
            }

            this.page = 0;
            let params = {
              uid: this.member.uid,
              page: this.page,
              timeType: this.dayList[this.searchDayActive].type,
              type: this.searchTypeActive.type
            }

            if (this.dayList[this.searchDayActive].type == 6) {
              params.startTime = utils.timeforamt(this.currentStartDate);
              params.endTime = utils.timeforamt(this.currentEndDate, "end");
            }
            this.getData(params);
          }
        } else {
          if (res.msg) this.$toast(res.msg);
        }
      }).catch(() => {
        console.log("catch");
      })
    },
    gaBack() {
      this.$router.push(this.entry);
    },
    getDate(val) {
      if (val) {
        return moment(val).format('DD-MM-YYYY');
      } else {
        return "";
      }
    },
    // 列表数据格式转换
    getTime(val) {
      if (val) {
        return moment(val).format('HH:mm:ss');
      } else {
        return "";
      }
    },
    // onLoad 加载更多
    onLoad() {
      if (!this.finished) {
        this.loading = true;
        this.page ++;
        let params = {
          uid: this.member.uid,
          page: this.page,
          timeType: this.dayList[this.searchDayActive].type,
          type: this.searchTypeActive.type
        }

        if (this.dayList[this.searchDayActive].type == 6) {
          params.startTime = utils.timeforamt(this.currentStartDate);
          params.endTime = utils.timeforamt(this.currentEndDate, "end");
        }
        this.getData(params);
      }
    },
    // 获取列表数据
    getData(params, refresh=false) {
      if (!params.page) {
        this.dataList = [];
      }
      this.finished = true;
      this.loading = true;
      assetRecord(params).then(res => {
        if (res.code === 0 || res.code === 0) {
          this.totalExpenditure = res.data.totalExpenditure;
          this.totalIncome = res.data.totalIncome;
          if (!params.page) {
            this.dataList = res.data.centerUserAssetsPlusVOS;
          } else {
            if (res.data.centerUserAssetsPlusVOS.length > 0) {
              let arr = JSON.parse(JSON.stringify(this.dataList));
              arr = [...arr, ...res.data.centerUserAssetsPlusVOS];
              this.dataList = arr;
            }
          }

          if (res.data.centerUserAssetsPlusVOS.length == 10) {
            this.finished = false;
          }

          if (refresh) {
            this.$toast(this.$t('common.refreshSuccess'));
          }
          // 解决滚动条与下拉的冲突  数据没回来之前获取不到dom
          this.$nextTick(() => {
            const vantList = document.querySelector('.van-list');
            if (vantList) {
              this.resolveConflict(vantList);
            }
          });
        } else {
          if (res.msg) this.$toast(res.msg);
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;
        console.log("catch");
      }) 
    },
    onConfirm(index) {
      this.searchDayActive = index;
      if (index != 5) {
        // this.$refs.searchItemDay.toggle();
        this.goSearch();
      }
    },
    cancelSearch() {
      this.$refs.searchItemDay.toggle();
    },
    async goSearch() {
      this.page = 0;
      let params = {
        uid: this.member.uid,
        page: this.page,
        timeType: this.dayList[this.searchDayActive].type,
        type: this.searchTypeActive.type
      }

      if (this.dayList[this.searchDayActive].type == 6) {
        params.startTime = utils.timeforamt(this.currentStartDate);
        params.endTime = utils.timeforamt(this.currentEndDate, "end");
      }
      await this.getData(params);
      this.$refs.searchItemDay.toggle();
    },
    async onConfirmType(item) {
      this.page = 0;
      let params = {
        uid: this.member.uid,
        page: this.page,
        timeType: this.dayList[this.searchDayActive].type,
        type: item.type
      }

      if (this.dayList[this.searchDayActive].type == 6) {
        params.startTime = utils.timeforamt(this.currentStartDate);
        params.endTime = utils.timeforamt(this.currentEndDate);
      }

      await this.getData(params);
      
      this.$refs.searchItemType.toggle();
      this.searchTypeActive = item;
    },
    onRefresh() {
      setTimeout(() => {
        this.page = 0;
        let params = {
          uid: this.member.uid,
          page: this.page,
          timeType: this.dayList[this.searchDayActive].type,
          type: this.searchTypeActive.type
        }

        if (this.dayList[this.searchDayActive].type == 6) {
          params.startTime = utils.timeforamt(this.currentStartDate);
          params.endTime = utils.timeforamt(this.currentEndDate);
        }
        this.getData(params, true);
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
  .transactionRecord {
    text-align: left;
    padding-top: 2.1rem;
    background-color: #F9F9F9;
    .title {
      height: auto;
    }
    .betSearchBox {
      text-align: left;
      background-color: #fff;
      padding: 0.1rem 0 0.1rem 0.3rem;
      margin-bottom: 0.2rem;
      display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
      display: -moz-box; /* Firefox 17- */
      display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
      display: -moz-flex; /* Firefox 18+ */
      display: -ms-flexbox; /* IE 10 */
      display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */

      .betSearch {
        height: 0.68rem;
        min-width: 1rem;
        display: inline-block;
      }
    }

    .seatchTitle {
      font-size: 0.24rem;
      line-height: 0.28rem;
    }

    .searchItem {
      .searchTip {
        color: #a5a9b3;
        font-size: .24rem;
        height: 0.34rem;
        line-height: .34rem;
        padding: 0.3rem 0 0 0.28rem;
        background: #fff;
        box-sizing: initial;
      }

      .searchItem_item {
        padding: 0.3rem 0.08rem 0.1rem 0.28rem;

        span {
          display: inline-block;
          height: 0.7rem;
          min-width: 1.58rem;
          line-height: .7rem;
          border-radius: 0.16rem;
          text-align: center;
          margin-right: 0.2rem;
          color: #a5a9b3;
          font-size: .28rem;
          box-sizing: border-box;
          margin-bottom: 0.2rem;
          -webkit-flex-grow: 0;
          flex-grow: 0;
          -webkit-flex-shrink: 0;
          flex-shrink: 0;
          -webkit-flex-basis: calc(25% - 0.2rem);
          flex-basis: calc(25% - 0.2rem);
          position: relative;
          padding: 0 0.1rem;

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            -webkit-transform-origin: left top;
            transform-origin: left top;
            border: 1px solid #cbced8;
            border-radius: 0.32rem;
            pointer-events: none;
            width: 200%;
            height: 200%;
            -webkit-transform: scale(.5);
            transform: scale(.5);
          }

          &.searchItem_item_active {
            background-color: #eb457e;
            color: #fff;
            border: none;

            &::after {
              border-color: transparent;
            }
          }
        }
      }
    }

    .customizeSearch {
      font-size: 0.28rem;
      position: relative;
      z-index: 2;

      &.borderBottom {
        border-bottom: 1px solid #ebeff1;
      }

      .customizeSearchItem {
        padding: 0 0.28rem;

        i {
          color: #a5a9b3;
        }
      }
    }

    .customizeBtnBox {
      display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
      display: -moz-box; /* Firefox 17- */
      display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
      display: -moz-flex; /* Firefox 18+ */
      display: -ms-flexbox; /* IE 10 */
      display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */

      div {
        width: 50%;
        height: 0.9rem;
        line-height: .9rem;
        text-align: center;
        font-size: .32rem;
      }

      .customizeBtnCancel {
        color: #F74995;
        background-color: #fff;
      }

      .customizeBtnConfirm {
        background-color: #F74995;
        color: #fff;
      }
    }

    .transacitonList {
      position: relative;
      height: calc(100vh - 3rem);
      width: 100%;

      .transacitonList_item {
        .transacitonList_item_head {
          padding: 0.2rem 0 0.1rem 0.28rem;
          color: #414655;
          font-weight: 700;
          font-size: .36rem;
          height: 0.5rem;
          box-sizing: initial;
          line-height: .5rem;
          background: #F9F9F9;
          width: 100%;
          z-index: 2;
        }

        .transacitonList_item_item {
          padding-left: 0.28rem;
          background-color: #fff;

          .transacitonList_item_list {
            height: 1.4rem;
            border-bottom: 0.02rem solid #eeeeee70;
            box-sizing: border-box;

            .item_list_item {
              width: 1.6rem;

              p:last-child{
                height: 0.44rem;
                line-height: 0.44rem;
                color: #333;
                font-size: 0.28rem;
                margin-bottom: 0.1rem;
              }

              .listTime {
                height: 0.3rem;
                line-height: .3rem;
                color: #6c7ba8;
                font-size: .24rem;
              }
            }

            .item_list_cont {
              width: calc(100vw - 4.1rem);
            }

            .item_list_item_right {
              padding-right: 0.64rem;
              position: relative;
              z-index: 1;
              text-align: right;
              width: 2.2rem;

              p:first-child {
                height: 0.44rem;
                line-height: .44rem;
                color: #a5a9b3;
                font-size: .28rem;
                margin-bottom: 0.1rem;
              }
              .money {
                height: 0.44rem;
                line-height: .44rem;
                color: #414655;
                font-size: .32rem;
                text-align: right;
                font-family: DinProMedium;

                  &.green {
                    color: #00B887;            
                  }
                  &.red {
                    color: #C41F1A;            
                  }
              }

              img {
                position: absolute;
                top: 50%;
                right: 0.2rem;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
    .showAll {
      font-size: 0.24rem;
      color: #626872;
      text-align: center;
      height: 0.8rem;
      line-height: 0.8rem;
    }

    .footerBox {
      position: fixed;
      bottom: 0;
      left: 0;
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      background-color: #fff;
      padding: 0.2rem 0.28rem;
      box-shadow: 1px 1px 2px 0px rgba(247, 249, 256, 1);
      color: #333;

      p {
        color: #666;
        display: inline-block;
        margin-right: 0.1rem;
      }

      div:nth-child(1) span {
        color: #00b887;
      }

      div:nth-child(2) span {
        color: #c41f1a;
      }
    }
  }
</style>
<style lang="scss">
  .transactionRecord {
    .van-dropdown-menu__bar {
      box-shadow: 0 0 0 rgba(100, 101, 102, 0);
      height: 0.68rem;
      border-radius: 0.5rem;
      text-align: center;
      background: #e5e5e5;
      margin-right: 0.2rem;
      font-size: 0.28rem;
      line-height: 0.68rem;
      padding: 0 0.4rem 0 0.2rem;
      min-width: 1rem;
      color: #414655;
    }

    .van-dropdown-menu__bar--opened {
      background-color: #eb457e;
      color: #fff;
    }

    .van-dropdown-menu__title:after {
      border-color: transparent transparent #666 #666;
    }

    .van-dropdown-menu__title--active:after {
      border-color: transparent transparent #fff #fff;
    }

    .van-dropdown-item {
      top: 2rem !important;
    }

    .van-dropdown-item__content {
      max-height: 100%;
    }
  }
</style>
