<template>
    <div class="toPay">
        <img class="bg" v-real-img="liveInfo.avatar" :errorimg="require(`../../assets/img/errorImg/2.png`)" />

        <van-popup round v-model:show="show" :close-on-click-overlay="false" position="bottom">

            <div v-show="liveInfo.type == 2" class="toPayTips">{{ $t('liveRoom.payTips1') }} <span>{{ liveInfo.price
            }}</span> {{ $t('liveRoom.payTips11') }}</div>
            <div v-show="liveInfo.type == 1" class="toPayTips">{{ $t('liveRoom.payTips2') }} <span>{{ liveInfo.price
            }}</span> {{ $t('liveRoom.payTips21') }}</div>

            <div class="flexBetween toPayBottom">
                <div @click="goOtherLive">{{ $t('liveRoom.jumpOver') }}</div>
                <div @click="chargeRoom">{{ $t('liveRoom.toPay') }}</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { chargeRoom } from "@/api/live";
import { mapState } from 'vuex';
import interLive from '@/pages/mixin/interRoom';
export default {
    name: "livetopay",
    mixins: [interLive],
    data() {
        return {
            show: true,
            password: ""
        }
    },
    computed: {
        ...mapState(['member', 'liveInfo', 'liveList']),
        isManage() {
        let flag = false;
        if (this.member && this.member.badgeList && this.member.badgeList.length > 0) {
            let index = this.member.badgeList.findIndex(item => {
            return item == '4'
            });

            if (index != -1) {
            flag = true;
            }
        }
        return flag;
        }
    },
    methods: {
        onBeforeClose(action) {
            new Promise((resolve) => {
                resolve(false);
            });
        },
        close() {
            this.$emit('colsePopup', "showToPay");
        },
        goOtherLive() {
            if (this.liveList && this.liveList.length <= 1) return this.$router.push('/home');
            let nextIndex = 0,
                arr = this.liveList && this.liveList.length > 0 ? JSON.parse(JSON.stringify(this.liveList)) : [],
                index = arr.findIndex(item => {
                    return item.liveId && item.liveId == this.liveInfo.liveId;
                });

            if (index != -1 && index != arr.length - 1) {
                nextIndex = index + 1;
            }

            if (nextIndex != -1) {
                this.getAnchorBase(arr[nextIndex], true);
            }
        },
        // 计时房间付费
        chargeRoom() {
            let isSuperMan = false;

            if (this.member.badgeList && this.member.badgeList.length > 0) {
                let index = this.member.badgeList.findIndex(item => {
                    return item == 4
                });

                if (index != -1) {
                    isSuperMan = true;
                }
            }

            if (isSuperMan) {
                this.$emit('colsePopup', 'showCountdown');
                this.$emit('colseToPay');
            } else {
                let params = {
                    anchorId: this.liveInfo.anchorId,
                    liveId: this.liveInfo.liveId
                }
                chargeRoom(params).then(res => {
                    if (res.code === 0 || res.code === '0') {
                        this.$emit('colsePopup', 'showCountdown');
                        this.$emit('colseToPay');
                    } else {
                        // 4003 余额不足
                        if (res.code && res.code == 4003) {
                            this.$emit('openPopup', 'showShortBalance');
                        } else {
                            if (res.msg) this.$toast(res.msg);
                        }
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.toPay {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 11;

    .bg {
        width: 100%;
        height: 100vh;
    }

    .toPayTips {
        font-size: 0.28rem;
        color: #333;
        padding: 0 0.28rem;
        margin-top: 0.4rem;
        font-weight: 500;

        span {
            color: #f74995;
        }
    }

    .toPayBottom {
        font-size: 0.28rem;
        padding: 0 0.28rem;
        margin: 0.4rem 0;

        div {
            padding: 0.2rem 0.4rem;
            border-radius: 0.4rem;
            background-color: #ff5669;
            color: #fff;
            line-height: 0.3rem;

            &:last-child {
                background-color: #f74995;
            }
        }
    }
}
</style>
<style lang="scss">
.toPay .van-overlay {
    background-color: rgba(0, 0, 0, 0.3) !important;
}
</style>