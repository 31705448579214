<template>
    <div class="next mail">
        <div class="title">
            <span> {{ $t('my.mail.title') }} </span>
            <div class="fixed" @click="goOtherPage('/mine')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.35rem" height="0.35rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
        </div>

        <div class="news">
            <div @click="goNext(1)"
                class="newsItem">
                <div class="flexCenter">
                    <div class="userAvatarBox">
                        <img src="../../assets/img/my/mail/backstage.png">
                        <span v-show="systemNoticeList.length > 0 && systemNoticeList[0].isUnreadMsg === 0"></span>
                    </div>
                    <div class="newsItemContent">
                        <p>THlive {{ $t('my.mail.title') }}</p>
                        <p>{{ systemNoticeList.length > 0 ? systemNoticeList[0].content : "" }}</p>
                    </div>
                </div>
                <div class="time">{{ systemNoticeList.length > 0 ? getTime(systemNoticeList[0].createTime) : "--" }}</div>
            </div>

            <div @click="goNext(2)"
                class="newsItem">
                <div class="flexCenter">
                    <div class="userAvatarBox">
                        <img src="../../assets/img/my/mail/automatic.png">
                        <span v-show="systemLetterLists.length > 0 && systemLetterLists[0].isUnreadMsg === 0"></span>
                    </div>
                    <div class="newsItemContent">
                        <p>{{ $t('my.mail.systemNotice') }}</p>
                        <p>{{ systemLetterLists.length > 0 ? systemLetterLists[0].content : "" }}</p>
                    </div>
                </div>

                <div class="time">{{ systemLetterLists.length > 0 ? getTime(systemLetterLists[0].timestamp) : "--" }}</div>
            </div>
        </div>

        <div class="news letter">
            <div v-for="(item, index) in userLetterList" :key="`${index}--mail-item`" @click="goDetail(item)"
                class="newsItem">
                <div class="flexCenter">
                    <div class="userAvatarBox">
                        <img v-real-img="item.avatar" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                        <span v-if="item && item.isUnreadMsg === 0"></span>
                    </div>
                    <div class="newsItemContent">
                        <p>{{item.nickname }}</p>
                        <p>{{ item.content }}</p>
                    </div>
                </div>

                <div class="time">{{ getTime(item.timestamp) }}</div>
            </div>
        </div>

        <privateLetter v-if="showPrivateLetter" @closePrivateLetter="closePrivateLetter"></privateLetter>
    </div>
</template>

<script>
import { systemNotice } from "@/api/config";
import { systemLetterList, allLetterList } from "@/api/index";
import moment from 'moment';
import { mapState } from 'vuex';
import privateLetter from "@/pages/components/privateLetter.vue";
export default {
    components: {
        privateLetter
    },
    data() {
        return {
            systemNoticeList: [],
            // systemLetterLists: [],
            showPrivateLetter: false,
            isGetData: false
        }
    },
    computed: {
        ...mapState(['member', 'letterList', 'systemLetterLists']),
        userLetterList() {
            let letterList = JSON.parse(JSON.stringify(this.letterList)).reverse();
            let obj = {};
            letterList = letterList.reduce((newArr, next) => {
                obj[next.otherUid] ? "" : (obj[next.otherUid] = true && newArr.push(next));
                return newArr;
            }, []);

            letterList = letterList.sort(function (a, b) {
                return (b.timestamp - a.timestamp);
            });

            return letterList;
        }
    },
    created() { 
        this.systemNotice(); //THlive 通知
        this.systemLetterList(); //系统通知
        // this.getLetterList(); //私信
        this.allLetterList(); //私信
        this.$store.commit("setState", { betPramas: {} });
    },
    methods: {
        getTime(time) {
            if (time) {
                return moment(time).format('HH:mm DD-MM-YYYY');
            } 
            return "--";
        },
        // 关闭私信弹框
        closePrivateLetter() {
            this.allLetterList(); //私信
            this.showPrivateLetter = false;
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        // 前往私信
        goDetail(item) {
            let obj = JSON.parse(JSON.stringify(item));
            obj.uid = item.otherUid;
            this.$store.commit("setState", { betPramas: obj });
            this.showPrivateLetter = true;
        },
        // 系统公告
        systemNotice() {
            systemNotice().then(res => {
                if (res.code === 0 || res.code === '0') {
                    let old = res.data.length > 0 ? JSON.parse(JSON.stringify(res.data)) : [];
                    old = old.sort(function (a, b) {
                        let atime = a.createTime ? a.createTime : a.timestamp,
                            btime = b.createTime ? b.createTime : b.timestamp;
                        return (atime - btime);
                    });

                    old = old.reverse()
                    this.systemNoticeList = old;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => { })
        },
        // 系统通知 
        systemLetterList() {
            systemLetterList().then(res => {
                if (res.code === 0 || res.code === '0') {
                    let old = this.systemLetterLists.length > 0 ? JSON.parse(JSON.stringify(this.systemLetterLists)) : [];
                    old = [...old, ...res.data]
                    old = old.filter((obj, index, self) => {
                        return index === self.findIndex((o) => (
                            o.letterId === obj.letterId
                        ));
                    });

                    old = old.sort(function (a, b) {
                        let atime = a.createTime ? a.createTime : a.timestamp,
                            btime = b.createTime ? b.createTime : b.timestamp;
                        return (atime - btime);
                    });

                    old = old.reverse();
                    this.$store.commit("setState", { systemLetterLists: old });
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => { })
        },
        // 私信
        // getLetterList() {
        //     let date = new Date().toJSON().slice(0, 10), timestamp = new Date(`${date} 00:00:00`).getTime(), params = {}
        //     if (this.letterList.length > 0) {
        //         params.localId = this.letterList[this.letterList.length - 1].letterId;
        //     } else {
        //         params.localId = 0;
        //         params.createTime = (timestamp - 7 * 24 * 3600 * 1000 - 1000); // 接口返回的数据是大于此时间戳
        //     }
        //     userLetterList(params).then(res => {
        //         if (res.code === 0 || res.code === '0') {
        //             if (params.localId > 0) {
        //                 if (res.data.length > 0) {
        //                     let old = JSON.parse(JSON.stringify(this.letterList));
        //                     old = [...old, ...res.data];
        //                     this.$store.commit("setState", { letterList: old });
        //                 }
        //             } else {
        //                 this.$store.commit("setState", { letterList: res.data });
        //             }
        //         } else {
        //             if (res.msg) this.$toast(res.msg);
        //         }
        //     }).catch(err => {
        //         console.log(err);
        //     });
        // },
        goNext(type) {
            let arr =  type == 1 ? this.systemNoticeList : this.systemLetterLists;
            if (arr.length > 0) {
                sessionStorage.setItem('mailDetail', JSON.stringify(arr));
                this.goOtherPage('/mine/mail/detail');
            }
            
        },
        // 私信
        allLetterList() {
            let date = new Date().toJSON().slice(0, 10), timestamp = new Date(`${date} 00:00:00`).getTime(), params = {}
            params.localId = 0;
            params.createTime = (timestamp - 7 * 24 * 3600 * 1000 - 1000); // 接口返回的数据是大于此时间戳
            this.isGetData = true;
            allLetterList(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (res.data.length > 0) {
                        let old = this.letterList.length > 0 ? JSON.parse(JSON.stringify(this.letterList)) : [], 
                        newArr = [];
                        res.data.forEach(item => {
                            let index = old.findIndex(oItem => {
                                return oItem.letterId == item.letterId
                            })
                            if (index == -1) {
                                newArr.push(item);
                            } 
                        });
                        
                        old = [...newArr, ...old];

                        this.$store.commit("setState", { letterList: old });
                    }
                } else {
                    if (res.msg) this.$toast(res.msg)
                }
                this.isGetData = false;
            }).catch(err => {
                this.isGetData = false;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.mail {
    background-color: #f9f9f9;

    .news {
        &.letter {
            background-color: #fff;

            .newsItem {
                align-items: flex-start;
                -webkit-align-items: flex-start;
                box-align: flex-start;
                -moz-box-align: flex-start;
                -webkit-box-align: flex-start;
            }
        }

        .newsItem {
            background-color: #fff;
            padding: 0.3rem 0.2rem;
            margin: 0.2rem auto 0;
            width: calc(100% - 0.56rem);
            border-radius: 0.2rem;
            display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
            display: -moz-box; /* Firefox 17- */
            display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
            display: -moz-flex; /* Firefox 18+ */
            display: -ms-flexbox; /* IE 10 */
            display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
        
            align-items: flex-end;
            -webkit-align-items: flex-end;
            box-align: flex-end;
            -moz-box-align: flex-end;
            -webkit-box-align: flex-end;
        
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -moz-box-pack: space-between;
            -webkit-moz-box-pack: space-between;
            box-pack: space-between;

            .userAvatarBox {
                width: 1.02rem;
                height: 1.02rem;
                margin-right: 0.2rem;
                position: relative;

                img {
                    width: 1.02rem;
                    height: 1.02rem;
                    border-radius: 50%;
                }

                span {
                    display: inline-block;
                    width: 0.15rem;
                    height: 0.15rem;
                    background-color: #C41F1A;
                    position: absolute;
                    top: 0.1rem;
                    right: 0.1rem;
                    border-radius: 50%;
                }                
            }

            .newsItemContent {
                text-align: left;
                width: 2.6rem;
                font-size: 0.24rem;
                color: #333;

                p:nth-child(1) {
                    font-size: 0.28rem;
                    margin-bottom: 0.3rem;
                }

                p:nth-child(2) {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .time {
                font-size: 0.24rem;
                color: #969696;
            }
        }
    }
}</style>
