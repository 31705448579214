<template>
  <div>
    <van-popup
        v-model:show="isShowSearch"
        position="bottom"
        class="gameListDialog"
        :style="{ width: '100%', height: '100%' }"
        >
        <div class="search next">
            <div class="title">
                <div class="fixed" @click="colseSearch">
                    <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        p-id="12456" width="0.3rem" height="0.3rem">
                        <path
                            d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                            fill="#fff" p-id="12457"></path>
                    </svg>
                </div>
                
                <div class="searchBox">
                    <van-form @submit="search">
                        <van-field v-model.trim="content" autocomplete="off" clearable :placeholder="$t('home.searchTips')"/>
                    </van-form>
                </div>
            </div>

            <div @click="search" class="title_ellipsis">
                {{ $t("home.search") }}
            </div>

            <div v-show="list.length > 0 && isShowData" class="searchList">
                <div v-for="(item, index) in list" :key="`audienceListDialog-${index}`" @click="goUserDetail(item)" class="searchListItem flexBetween">
                    <div class="flexCenter">
                        <img class="searchAvatar" v-real-img="item.avatar" />
                        <div class="searchCont">
                            <div class="flexCenter">
                                <div>{{ item.nickname }}</div>
                            </div>
                            <div class="searchSign">{{ $t("my.sign") }}: {{ item.signature }}</div>
                            <div class="searchFans">{{ $t("my.fans") }}: {{ item.fans }}</div>
                        </div>
                    </div>

                    <div @click.stop="follow(item)"  class="interactiveBtn" :class="item.isFollow ? 'notFocusOn' : 'focusOn'">
                        {{ item.isFollow ? $t('my.follow.followed') :  `${$t('my.follows')}` }}
                    </div>
                </div>
            </div>

            <div v-show="list.length == 0 && isShowData" class="noData">
                <img src="../assets/img/record/noData.png"/>
                <p>{{$t('common.noData')}}</p>
            </div>
        </div>

        <userInfo v-if="showUserInfo" @colsePopup="colsePopup" @refresh="refresh"></userInfo>
    </van-popup>
  </div>
</template>

<script>
import { userFind, liveFollow } from "@/api/index";
import userInfo from "@/pages/components/userInfo.vue";
export default {
    props: ['showSearch'],
    components: {
        userInfo
    },
    data() {
        return {
            content: "",
            list: [],
            isShowData: false,
            showUserInfo: false
        }
    },
    computed: {
        isShowSearch() {
            return this.showSearch
        },
    },
    watch: {
        content(val) {
            if (!val) {
                this.isShowData = false;
            }
        }
    },
    methods: {
        // 点击用户头像
        goUserDetail(item) {
            let obj = JSON.parse(JSON.stringify(item));
            obj.isSelf = false; // 是否是查看自己的资料
            this.$store.commit("setState", { betPramas: obj });
            this.showUserInfo = true;
        },
        colseSearch() {
            this.$emit('colseSearch')
        },
        search() {
            if (!this.content) return;
            userFind({content: this.content}).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.list = res.data;
                    this.isShowData = true;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => { })
        },
        // 关注 / 取关
        follow(item) {
            let params = {
                isFollow: item.isFollow ? false : true,
                targetId: item.uid
            }
            liveFollow(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let str = item.isFollow ? this.$t('my.follow.Unfollowed') : this.$t('my.follow.followeSuccess');
                    this.$toast(str);
                    this.search();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        // 刷新列表
        refresh() {
            this.search();
        },
        // 关闭用户资料弹框
        colsePopup() {
            this.showUserInfo = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.search {
    background-color: #fff;
    height: 100vh;
    box-sizing: border-box;
    padding-top: 1rem;
    font-size: 0.28rem;
    color: #333;

    &.next {
        .title {
            background: -webkit-linear-gradient(left,#fa8faf,#f86fab);
            background: linear-gradient(90deg,#fa8faf,#f86fab);
            box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0);
            color: #fff;
        }

        .title_ellipsis {
            color: #fff;
        }

        .fixed {
            width: 0.6rem;
        }
    }

    .searchBox {
        width: calc(100% - 2.2rem);
        margin-left: 0.6rem;
    }
}

.searchList {
    padding: 0 0.28rem;
    .searchListItem {
        border-bottom: 1px solid #e5e5e5;
        width: 100%;
        padding: 0.2rem 0;

        .searchAvatar {
            width: 0.88rem;
            height: 0.88rem;
            border-radius: 50%;
            margin-right: 0.2rem;
        }

        .searchCont {
            margin-right: 0.4rem;
            text-align: left;

            .sex {
                width: 0.28rem;
                height: 0.28rem;
            }

            .searchFans {
                font-size: 0.16rem;
                color: #999;
            }

            .searchSign {
                font-size: 0.16rem;
                color: #e5e5e5;
            }
        }

        .interactiveBtn {
            line-height: 0.52rem;
            height: 0.52rem;
            color: #fff;
            text-align: center;
            border-radius: 0.8rem;
            font-size: 0.24rem;
            padding: 0 0.4rem;
            white-space: nowrap;

            &.focusOn {
                background: -webkit-linear-gradient(left, #f15f9d, #ffb2cd);
                background: linear-gradient(90deg, #f15f9d, #ffb2cd);
            }

            &.notFocusOn {
                background-color: #d8d8d8;
            }
        }
    }
}
</style>
<style lang="scss">
.search .searchBox .van-cell{
    padding: 0.1rem 0.2rem;
    border-radius: 0.1rem;
    margin-top: 0.1rem;
}
</style>