module.exports = {
    footer: {
        index: "Trang chủ",
        discount: "Hoạt động",
        my: "Của tôi",
        game: "Trò chơi",
        live: "Trực tiếp"
    },
    register: {
        title: "Đăng ký",
        phoneRegister: "Đăng ký số điện thoại",
        suPhoneTitle: "Mời nhập số điện thoại của bạn để tạo tài khoản mới",
        entryPhoneTip: "Mời nhập số điện thoại của bạn",
        emailRegister: "Đăng ký email",
        suEmailTitle: "Mời nhập email của bạn để tạo tài khoản mới",
        entryEmailTip: "Mời nhập email của bạn",
        confirmPhoneTips: "Xác nhận đây là số điện thoại chính xác của bạn",
        confirmEmailTips: "Xác nhận đây là email chính xác của bạn",
        checkPhoneError: "mời nhập chính xác số điện thoại",
        checkEmailError: "Địa chỉ email của bạn không đúng",
        hasPhoneRegister: "Số điện thoại này đã đăng ký",
        hasEmailRegister: "Số điện thoại này đã đăng ký",
        entryCodeTips: "Mời nhập mã xác minh",
        confirmNewPassword: "Xác nhận mật khẩu mới",
        verificationCodeSent: "Mã xác minh đã gửi đến",
        perfectPersonalInfo: "Hoàn thành tông tin cá nhân",
        jumpOver: "Bỏ qua",
        inconsistentTwice: "Mật khẩu phải thống nhất giữa hai lần nhập",
        uploadedSucs: "Tải lên thành công",
    },
    login: {
        title: "Đăng nhập",
        forgetPsw: "Quên mật khẩu",
        userAccountTips: "Vui lòng nhập số điện thoại hoặc địa chỉ email của bạn",
        userPasswordTips: "Mời nhập mật khẩu",
        loginAccountError: "Định dạng số điện thoại hoặc email không đúng",
        noHasAccount: "Tài khoản chưa được đăng ký",
        loginOut: "Tài khoản đã bị đăng nhập trên một thiết bị khác",
        findPassword: {
            moreThan6Tips: "Mời nhập mật khẩu ít nhất là 6 ký tự",
            codeSend: "Mã xác minh đã được gửi đến",
            userNewPasswordTips: "Mời nhập mật khẩu mới",
            setNewPassword: "Cài đặt mật khẩu đăng nhập",
            limitPwdLength: "Mật khẩu có độ dài từ 6-12 ký tự",
            twiceSame: "Mật khẩu phải thống nhất giữa hai lần nhập",
        }
    },
    home: {
        activityDetails: "Hoạt động",
        announcement: "Thông báo",
        recommendGames: "Giới thiệu trò chơi",
        anchorRocket: "Đề Xuất IDOL",
        searchTips: "Vui lòng nhập ID hoặc biệt hiệu idol",
        search: "Tìm Kiếm",
        nowApply: "Đăng ký ngay",
        nowRechange: "Nạp tiền ngay",
        customerService: "Dịch vụ CSKH",
        downLoadContinue: "Tải APP tiếp tục quan sát",
        downLoadApp: "Tải APP",
        downLoadNow: "Lập tức tải APP",
        downLoad: "Tải",
        noLogin: "Chưa đăng nhập"
    },
    gameCenter: {
        title: "Trung tâm trò chơi",
        record: "Lịch sử",
        addGameCollectionTips: "Thu thập thành công",
        cancelGameCollectionTips: "Đã hủy thu thập",
        gameAbnormality: "Trò chơi bất thường",
        GoToGame: "Tiến vào giao diện trò chơi",
    },
    my: {
        follows: "theo dõi",
        fans: "fan hâm mộ",
        privateLetter: "Tin nhắn",
        accountBalance: "Số dư tài khoản",
        recycle: "Thu hồi",
        userInfo: "Thông tin cá nhân",
        editUserInfo: "Sửa đổi thông tin cá nhân",
        myAvatar: "Ảnh đại diện của tôi",
        city: "Thành phố",
        sign: "Chữ ký",
        signature: "Chữ ký cá nhân ",
        signatureTips: "Dễ dàng thu hút sự chú ý từ người khác hơn bằng cách điền vào hồ sơ của bạn",
        nickName: "Biệt danh",
        nickNameTips: "Nhập nhiều nhất 10 từ",
        sex: "Giới tính",
        man: "Nam",
        woman: "Nữ",
        unknown: "Không xác định",
        editPassword: "Đổi mật khẩu",
        save: "Lưu",
        imgLoadTips: "Tải lên hình ảnh có định dạng png, jpg, jpeg",
        eidtLoginPassword: "Đổi mật khẩu đăng nhập",
        enterOldPwdTips: "Nhập mật khẩu cũ",
        setNewPwd: "Đặt mật khẩu mới",
        enterNewPwdTips: "Mời nhập mật khẩu mới",
        confirmNewPwdTips: "Xác nhận mật khẩu mới",
        enterLeastSix: "Mời nhập mật khẩu ít nhất là 6 ký tự",
        transferAmount: "Số tiền chuyển khoản",
        bindPhone: "Liên kết số điện thoại",
        detail: "Chi tiết",
        income: "Thu nhập",
        expenditure: "Chi tiêu",
        editUserNameTips: "Bạn có chắc chắn bỏ ra",
        editUserNameTips2: "tiền vàngđể đổi biệt danh của mình không?Miễn phi cho lần đầu tiên ",
        aboutTilte: "ĐIỀU KHOẢN SỬ DỤNG THlive",
        menuList: {
            recharge: "nạp tiền",
            withdraw: "rút tiền",
            mall: "mua sắm",
            noble: "cấp vip"
        },
        listView: {
            jionProxy: "Tham gia đại lý",
            myProxy: "Đại lý của tôi",
            transaciton: "Lịch sử giao dịch",
            gameReport: "Báo cáo trò chơi",
            myNoble: "Quý tộc của tôi",
            myProps: "Đạo cụ của tôi",
            about: "Liên quan",
            set: "Cài đặt hệ thống"
        },
        systemSet: {
            title: "Cài đặt hệ thống",
            blacklist: "Danh sách đen",
            userAgreement: "Điều khoản sử dụng",
            loginOut: "Đăng xuất",
            loginOutTips: "Bạn có chắc chắn muốn đăng xuất không?",
            pullOut: "Bỏ chặn",
        },
        mail: {
            title: "Thông báo",
            systemNotice: "Thông báo hệ thống",
            privateLetterTips: "Nhập nội dung của tin nhắn tại đây",
            send: "Gửi",
        },
        follow: {
            follow: "Người tôi theo dõi",
            fans: "Người hâm mộ tôi",
            followeSuccess: "Theo dõi thành công",
            Unfollowed: "Đã hủy theo dõi",
            followed: "Đã theo dõi"
        },
        finance: {
            myGold: "Tiền vàng của tôi",
            amountRange: "Phạm vi số tiền nạp",
            // amountRangeTips: "nhập số tiền và kết thúc bằng 000",
            rechargeReward: " Phần thưởng nạp tiền",
            willReceive: "bạn sẽ nhận được",
            rechargeSteps: "Các bước nạp tiền",
            rechargeStepsDetail: "Chuyển khoản trực tiếp đến số tài khoản hiển thị bên trên, Nội dung chuyển tiền: điền ID tài khoản của bạn.Sau khi chuyển khoản thành công, mời nhập số tiền nạp, sau đó nhấn [Nạp tiền ngay], tiền vào tài khoản sau 5 phút!",
            bankRechargeTips: "Lưu ý: Thẻ ngân hàng của chúng tôi thường xuyên thay đổi, mỗi lần nạp tiền vui lòng kiểm tra thông tin số tài khoản mới nhất trên trang, không nạp tiền dựa vào nhật ký chuyển khoản trước đó, xin cảm ơn quý khách!",
            bankRechargeSuccess: "Tài vụ đang kiểm tra, 3-5 phút tiền không vào tài khoản, mời liên hệ CSKH",
            selectPaymentChannel: "Mời chọn kênh thanh toán",
            USDTAdress: "Địa chỉ nhận tiền USDT",
            USDTCode: "Mã nhận tiền USDT",
            USDTTips: "Vui lòng chuyển khoản đến địa chỉ  USDT bên trên trước khi tạo đơn",
            bill: "Tạo đơn",
            exchangeRate: "vui lòng lấy tỷ giá thanh toán thực tế làm chuẩn",
            USDTDetail: "1.Vui lòng sử dụng điều khoản",
            USDTDetail01: "để thanh toán, nếu bất kỳ chuyển khoản nào khác với điều khoản",
            USDTDetail02: "có thể sẽ bị mất và không đến tài khoản",
            USDTDetail1: "2.Vui lòng đảm bảo rằng tài khoản nhận được",
            USDTDetail2: " USDT (không bao gồm phí giao dịch) nếu không số tiền chuyển khoản và số tiền thực tế nhận được sẽ có sự khác nhau",
            USDTDetail3: "3.Sau khi bạn thanh toán đến địa chỉ bên trên, sẽ cần sự xác nhận phía nhà mạng, có thể sẽ có sự chậm trễ, xin bạn vui lòng đợi",
            submitAfterTransfer: "Mời chuyển khoản trước khi tạo đơn",
            thirdOrderTips: "Xác nhận sử dụng",
            thirdOrderTips1: "để nạp",
            notRecharged: "Chưa nạp tiền",
            recharged: "Đã nạp tiền",
            tutorial: "Hướng dẫn",
            payWay: "Phương thức nạp tiền",
            hotType: {
                hot: "HOT",
                recommend: "Gợi ý",
                fad: "Nóng",
                rapidly: "Cấp tốc"
            },
            depositAmount: "Số tiền nạp",
            depositAmountTips: "Mời nhập số tiền nạp",
            accountNumber: "汇款姓名",
            accountNumberTips: "请输入汇款姓名",
            accountBankName: "汇款银行名称",
            accountBankNameTips: "请输入或选择银行名称",
            orderName: "汇款银行卡号",
            orderNameTips: "请输入或选择银行卡号",
            aboutReceiptAmount: "Dự kiến đến tài khoản:",
            receiptAmount: "Số tiền đến tài khoản",
            currency: "Tiền vàng",
            currencyExchangeRate: "Tỷ giá tham khảo",
            betAmountThan: "Bạn chỉ có thể rút tiền khi số tiền cược lớn hơn",
            betAmountThan1: "",
            promotionBonus: "Tiền khuyến mãi:",
            promotionalWinnings: "Tiền cược khuyến mãi",
            withdrawalRules: "Quy tắc rút tiền",
            singleDepositAmount: "Số tiền nạp ",
            receiptRuleTips: "Số tiền thực tế nhận được sẽ được tính dựa trên tỷ lệ chuyển đổi của tiền vàng",
            receiptRuleTips2: "Phí chuyển USDTdo APP chi trả",
            nowDeposit: "Nạp tiền ngay",
            serialNo: "Số sê-ri",
            serialNoTips: "Mời nhập số sê-ri",
            serialNoTips1: "Số sê-ri có 11 hoặc 13 hoặc 14 số",
            serialNoTips2: "Số sê-ri có 14 số",
            rechangeNo: "Số thẻ cào ",
            rechangeNoTips: "Mời nhập số thẻ cào",
            rechangeNoTips1: "Số thẻ cào có 13 hoặc 15 số ",
            rechangeNoTips2: " Số thẻ cào có 12 hoặc 14 số ",
            rechangeType: "Loại thẻ",
            rechangeInfo: "Thông tin nạp tiền",
            ifPayReady: "Nếu đã hoàn tất thanh toán, vui lòng",
            name: "Họ tên：",
            account: "Tài khoản：",
            amount: "Số tiền：",
            amountTips: "（Số tiền chuyển phải trùng khớp với số tiền trên đơn）",
            adress: "Địa chỉ：",
            bindBankCard: "Đã liên kết thẻ ngân hàng",
            rechangeName: "Họ tên người nạp tiền",
            rechangeNameTips: "Mời nhập họ tên chủ thẻ",
            rechangeNameWarn: "Để tiền đến tài khoản nhanh chóng, vui lòng nhập chính xác họ tên người nạp tiền",
            rechangeBankTips: "Tài khoản ngân hàng của công ty không không cố định, mỗi lần nạp tiền vui lòng chuyển khoản đến số tài khoản hiển thị khi tạo đơn; không trực tiếp chuyển tiền vào số tài khoản dùng trước đó, nếu bạn chuyển tiền vào số tài khoản dùng trước đó và tiền không đến tài khoản thì chúng tôi không chịu trách nhiệm!",
            bankName: "Ngân hàng",
            bankNameTips: "Vui lòng chọn một ngân hàng",
            bankNo: "Số tài khoản",
            bankNoTips: "Vui lòng nhập số tài khoản",
            importantHint: "Lời nhắc quan trọng",
            usdtAmountTips: "（Vui lòng chuyển theo số tiền bên trên, nếu không sẽ ảnh hưởng đến tốc độ chuyển tiền）",
            saveCode: "Chụp ảnh màn hình lưu mã QR",
            receivingAddress: "Địa chỉ nhận tiền：",
            bindAddress: "Đã liên kết địa chỉ",
            paymentAddress: "Địa chỉ thanh toán",
            paymentAddressTips: "Mời nhập địa chỉ thanh toán",
            transferOrder: "Mã đơn chuyển tiền",
            transferOrderTips: "Mời nhập mã đơn chuyển tiền",
            QRCode: "Mã QR",
            orderDetail: "Chi tiết đơn",
            transactionType: "Loại hình giao dịch",
            transactionItem: "Mục giao dịch",
            status: "Trạng thái",
            transactionHour: "Thời gian giao dịch",
            OrderNumber: "Mã đơn",
            walletAmount: "Số tiền trong ví",
            putAway: "Thu gọn",
            interchangeTips: "Không hỗ trợ chuyển tiền giữa các ví trong sảnh",
            enterTransferAmountTips: "Mời nhập số tiền chuyển",
            insufficientBalance: "Số dư không đủ",
            nowTransfer: "Chuyển tiền ngay",
            chooseWallet: "Chọn ví tiền",
            queryFlow: "Xem tiền cược",
            recycle: "Thu hồi",
            goldWithdrawal: "Rút tiền vàng",
            withdrawalRecords: "Lịch sử rút tiền",
            withdrawal: "Rút tiền",
            withdrawableAmount: "Số tiền có thể rút",
            currentTotalTurnover: "Tổng cược hiện tại",
            totalHistory: "Lịch sử tổng cược",
            exchangeRate: "Tỷ lệ quy đổi hiện tại",
            withdrawWay: "Phương thức rút tiền",
            selectWithdrawalCurrency: "Chọn tiền tệ rút",
            withdrawCardNum: "Số tài khoản rút tiền",
            selectWithdrawCardNum: "Mời chọn số tài khoản rút tiền",
            amountCanWithdrawn: "Số tiền có thể rút",
            withdrawChAdre: "Địa chỉ rút tiền",
            withdrawChAdreTips: "Mời chọn địa chỉ rút tiền",
            withdrawalAmount: "Số lượng rút ",
            withdrawalAmountTips: "Mời nhập số lượng rút",
            withdrawalPsw: "Mật khẩu rút tiền",
            setWithdrawalPsw: "Đặt mật khẩu rút tiền",
            withdrawalPswTips: "Mời nhập 6-16 ký tự",
            nowWithdraw: "Rút tiền ngay",
            noHaveAccontTips: "Hãy liên kết 1 tài khoản tiền mã hóa trước để rút tiền",
            billingDetails: {
                billingDetails: "Chi tiết tiền cược hiện tại",
                totalTurnover: "Tổng cược yêu cầu",
                finishTurnover: "Đã hoàn thành tiền cược yêu cầu：",
                activitiesRequired: "Hoạt động yêu cầu：",
                finishActivitiesRequired: "Đã hoàn thành hoạt động yêu cầu："
            },
            withdrawalMoney: "Số tiền rút",
            withdrawalMoneyTips: "Vui lòng nhập số tiền rút",
            pleaseSelectBank: "Mời chọn ngân hàng",
            transferSuccessTips: "Chuyển tiền thành công",
            selectBank: "Chọn ngân hàng",
            moneyEndTips: "Số tiền phải kết thúc bằng 000",
            moneyNoEnough: "Số dư khả dụng không đủ",
            bindBnkFirstTips: "Vui lòng liên kết thẻ ngân hàng trước",
            canWithdrawn: "Số tiền có thể rút",
            addBank: "Thêm thẻ ngân hàng",
            immediateReturn: "hoàn trả ",
            payeeName: "Tên người nhận",
            bank: "Ngân hàng",
            remitterMessage: "Nội dung chuyển tiền",
            branch: "Chi nhánh",
            confirmWithdraw: "Xác nhận rút tiền",
            setWithdrawPwd: "Đặt mật khẩu rút tiền ",
        },
        proxy: {
            phoneNumber: "Số điện thoại",
            phoneNumberTips: "Mời nhập số điện thoại của bạn",
            phoneCode: "Mã xác minh số điện thoại",
            agentJoin: "Đại lý",
            tagentJoin: "Tham gia đại lý THlive",
            agentJoinTips1: "Một chuyến đi quanh thế giới,<br/> chúng ta tuyệt đối không tầm thường",
            agentJoinTips2: "Tỷ lệ hoa hồng cực cao khi tham gia THlive <br/> hoa hồng tiền triệu không còn là giấc mơ",
            joinConsultation: "Tham gia tư vấn",
            clickEnterOnline: "Click để được tư vấn trực tuyến",
            consult: "Tư vấn",
            ApplicationsMembership: "Đăng ký tham gia",
            dataAnalysisDetail: "Phân tích số liệu tuần",
            accountAdjustment: "Điều chỉnh tài khoản",
            instructionManual: "Ghi chú",
            commissionReport: "Bảng hoa hồng",
            subordinateCommissionShare: "Phần trăm hoa hồng cấp dưới",
            commission: "Hoa hồng",
            commissionRate: "Tỷ lệ hoa hồng",
            commissionRateExplain: "Hoa hồng sẽ thay đổi khi số người tích cực và tổng lãi lỗ của công ty tăng",
            activeMember: "Hội viên tích cực",
            addMember: "Hội viên mới",
            addMemberExplain: "Hội viên mới đăng ký tháng này",
            netWinLoss: "Lãi lỗ ròng sau khi bổ sung",
            lastMonthBalance: "Số dư tháng trước",
            netWin: "Lãi lỗ ròng",
            companyProfit: "Tổng lãi lỗ của công ty",
            platformFee: "Phí APP",
            totalRecharge: "Tổng tiền nạp của hội viên",
            totalWithdraw: "Tổng tiền rút của hội viên",
            totalBalance: "Tổng số dư của hội viên",
            liveProfit: "Thu nhập của idol",
            firstRechargeNum: "Số người nạp tiền lần đầu",
            firstRechargeMoney: "Số tiền nạp lần đầu",
            transferNextMonth: "Cộng dồn vào tháng sau",
            actualCommission: "Hoa hồng thực tế phát",
            status: {
                undistributed: "Chưa phát",
                dispatched: "Đã phát",
                unsettlement: "Chưa kết toán"
            },
            commissionReportWarn1: "1.Hệ thống cập nhật bảng báo cáo số liệu vào mỗi sáng",
            commissionReportWarn2: "2.Số liệu trong bảng, tổng lãi lỗ, lãi lỗ ròng là số dương biểu thị lợi nhuận của đại lý, nếu là số âm thì biểu thị đại lý thua lỗ；",
            commissionRules: "Quy tắc tính hoa hồng",
            descriptionCommissionRatio: "Nói rõ về tỷ lệ hoa hồng",
            descriptionCommissionRatioCont: "Tỷ lệ hoa hồng của đại lý được quyết định bởi 2 điều kiện là tổng số tiền lãi lỗ và số người hoạt động tích cực trong tháng, khi đáp ứng được cả 2 thì sẽ nhận được tỷ lệ hoa hồng của cấp độ tương ứng.",
            activeNumber: "Số người tích cực",
            activeNumberExplain: "Nếu số tiền nạp trong tháng đạt 100 tiền vàng, sẽ được coi là người dùng tích cực",
            monthlyDeposit: "Số tiền nạp trong tháng",
            level: "Level",
            noteCont: "Chú ý：Hoa hồng= Lãi lỗ ròng sau khi bổ sung *Tỷ lệ hoa hồng+ Điều chỉnh tài khoản+ Phần trăm hoa hồng đại lý cấp dưới",
            commissionCalculationMethod: "Phương thức tính hoa hồng",
            commissionCalculationMethodDetail1: "Hoa hồng=Lãi lỗ ròng sau khi bổ sung*Tỷ lệ hoa hồng+Điều chỉnh tài khoản+ Phần trăm hoa hồng đại lý cấp dưới",
            commissionCalculationMethodDetail11: "(phần trăm đại lý do THlive chịu)",
            commissionCalculationMethodDetail2: "Lãi lỗ ròng sau khi bổ sung = Lãi lỗ ròng tháng hiện tại+Số dư tháng trước (Lãi lỗ ròng tháng trước)）",
            commissionCalculationMethodDetail31: "Lãi lỗ ròng = Tổng lãi lỗ - phí APP (phí cơ bản",
            commissionCalculationMethodDetail32: "hoa hồng",
            commissionCalculationMethodDetail33: "phí địa điểm",
            commissionCalculationMethodDetail34: "nếu tổng lãi lỗ là số âm thì APP sẽ không thu phí）",
            commissionCalculationMethodDetail4: "Tổng lãi lỗ = Tổng tiền nạp vào của cấp dưới trong tháng - Tổng tiền rút - Số dư tài khoản + số dư ở lần kết toán trước- Chi phí khấu trừ ở phòng phát trực tiếp",
            commissionCalculationMethodDetail5: "(Nếu tổng lãi lỗ tháng này chưa đạt đến mức hoa hồng tối thiểu, sẽ tự động cộng dồn sang tháng sau）",
            commissionCalculationMethodDetail6: "Điều chỉnh tài khoản = Khi hội viên có đơn không thành công hoặc xảy ra lỗi khi nạp tiền, APP sẽ tiến hành điều chỉnh số tiền trong tài khoản；",
            SubAgentCommission: "Hoa hồng đại lý cấp dưới",
            SubAgentCommissionDetail1: "Chỉ cần đại lý cấp dưới của bạn kiếm được đại lý mới, thì bạn sẽ được hưởng thêm một phần hoa hồng từ những đại lý cấp dưới này. Số hội viên tích cực càng nhiều thì hoa hồng bạn nhận được càng cao; càng có nhiều đại lý cấp dưới thì tổng hoa hồng bạn nhận được càng cao. ",
            SubAgentCommissionDetail2: "THlive sẽ trả thêm một phần hoa hồng cho đại lý cấp 1 của bạn",
            SubAgentCommissionDetail3: "và một phần hoa hồng của đại lý cấp 2 ",
            SubAgentCommissionDetail4: "Dành cho bạn",
            SubAgentCommissionSuHead: "Ví dụ：",
            SubAgentCommissionSuDetail1: "Hoa hồng đại lý cấp 1 của bạn = ",
            SubAgentCommissionSuDetail2: "Phần trăm hoa hồng của bạn =",
            SubAgentCommissionSuDetail3: "Hoa hồng đại lý cấp 2 của bạn = ",
            SubAgentCommissionSuDetail4: "Phần trăm hoa hồng của bạn =",
            SubAgentCommissionSuDetail5: "Tổng hoa hồng bạn nhận được từ đại lý cấp dưới =",
            commissionPayment: "Thanh toán hoa hồng",
            paymentTime: "Thời gian thanh toán",
            paymentTimeExplain: "Ngày 5 hàng tháng sẽ thanh toán hoa hồng tháng trước. Ví dụ: Hoa hồng tháng 1 của đại lý sẽ được thanh toán vào ngày 5 tháng 2",
            paymentMethod: "Phương thức thanh toán",
            paymentMethodExplain: "Chúng tôi sẽ phát hoa hồng đến ví hoa hồng đại lý của bạn, bạn có thể trực tiếp rút tiền từ ví hoa hồng (không cần đặt cược/ giao dịch), bạn cũng có thể chuyển hoa hồng đến ví trung tâm, nếu bạn chuyển đến ví trung tâm thì cần 1 vòng cược mới có thể rút tiền.",
            ruleNetloss: "Lãi lỗ ròng âm",
            ruleNetlossExplain: "Nếu lãi lỗ ròng tháng này âm, thì số tiền âm này sẽ được cộng dồn vào lãi lỗ ròng của tháng sau, cho đến khi lãi lỗ ròng là số dương thì mới có hoa hồng.",
            commissionPaymentWarn: "Bất kỳ ai sử dụng phương pháp gian lận để lừa đảo tiền hoa hồng sẽ bị đóng băng tài khoản vĩnh viễn, đồng thời chấm dứt quan hệ hợp tác, tất cả hoa hồng sẽ không được phát",
            agentExchange: "Chuyển đổi",
            currentCommission: "Hoa hồng hiện tại",
            footer: {
                memberStatistics: "Thống kê hội viên",
                memberStatisticsExplain: "Kiểm tra và nắm rõ về số hội viên đã đăng ký của bạn bất kỳ lúc nào",
                commissionReportExplain: "Có thể xem bảng báo cáo, hoa hồng được trả hàng tháng",
                memberWinsLoses: "Lãi lỗ của hội viên",
                memberWinsLosesExplain: "Trạng thái lãi lỗ của hội viên và tổng phí của APP được thể hiện rõ ràng trực quan",
                commissionRulesExplain: "Quy tắc tính hoa hồng chi tiết minh bạch, xóa tan mọi nghi ngờ của bạn",
            },
            noBindPhoneTips: "Bạn chưa liên kết số điện thoại, mời đi liên kết",
            submitApplicationTips: "Bạn đã gửi yêu cầu tham gia, vui lòng đợi CSKH xử lý, nếu có bất kỳ thắc mắc nào hãy liên hệ CSKH trực tuyến 24/24",
            memberID: "ID hội viên",
            memberIDTips: "Mời nhập ID hội viên",
            agentIDTips: "Mời nhập ID của đại lý",
            subAgentIDTips: "Mời nhập ID của đại lý cấp trên",
            memberDepositRecord: "Lịch sử nạp tiền hội viên",
            memberWithdrawalRecord: "Lịch sử rút tiền hội viên",
            memberReport: "Bảng hội viên",
            SubAgent: "Đại lý cấp dưới ",
            SubAgentReport: "Bảng đại lý cấp dưới ",
            totalDeposit: "Tổng tiền nạp",
            totalWithdrawal: "Tổng tiền rút",
            balance: "Số dư",
            companyWinsLoses: "Lãi lỗ công ty",
            selectTime: "Mời chọn thời gian",
            filter: "Lọc",
            selectMonth: "Chọn tháng",
            myMember: "Hội viên của tôi",
            time: "Thời gian",
            startDate: "Ngày bắt đầu",
            endDate: "Ngày kết thúc",
            selectDateTips: "Chọn ngày tháng năm",
            userLevel: "Level",
            memberInfo: "Thông tin hội viên",
            grade: "Level",
            clevel: "Cấp bậc",
            numberDeposits: "Số lần nạp tiền",
            totalDepositAmount: "Tổng tiền nạp",
            numberWithdrawals: "Số lần rút tiền",
            withdrawAmount: "Tổng tiền rút",
            lastLoginTime: "Thời gian đăng nhập sau cùng",
            proxyUrl: "Nguồn đăng ký",
            myAgent: "Đại lý của tôi",
            myCommission: "Hoa hồng của tôi",
            exchange: "Chuyển đổi",
            withdrawMoney: "Rút tiền",
            dataMonth: "Số liệu tháng này",
            dataMonthExplain: "Dữ liệu được cập nhật mỗi sáng",
            numberFirstDepositors: "Số người nạp tiền lần đầu",
            numberFirstDepositorsExplain: "Hội viên nạp tiền lần đầu sau khi đăng ký",
            numberNewLowerLevel: "Số lượng đại lý cấp dưới mới",
            dataReport: "Bảng số liệu",
            promotionTool: "Công cụ quảng cáo",
            dividend: "Hoa hồng",
            serviceCharge: "Phí dịch vụ",
            baseFee: "Phí cơ bản",
            myPromotionalQRCode: "Mã quảng cáo của tôi ",
            agentWithdrawal: "Rút tiền đại lý",
            downloadBgFontProxy: "Vừa xem người đẹp livestream vừa chơi game thắng tiền",
            subAgentReportTable: {
                agentID: "ID đại lý",
                superiorAgent: "Đại lý cấp trên",
                commissionShare: "Phần trăm hoa hồng",
                totalBelowFee: "Tổng hoa hồng：",
                belowFeePercent: "Tỷ lệ phần trăm hoa hồng：",
                proxyLevel: "Cấp đại lý：",
                activeNum: "Số người tích cực：",
                justifyNetWin: "Tổng lãi lỗ sau khi bổ sung：",
                commissionPercent: "Tỷ lệ hoa hồng：",
                accountAdjustment: "Điều chỉnh tài khoản："
            },
            level1Agent: "Đại lý cấp 1",
            level2Agent: "Đại lý cấp 2",
            level1AgentIDTips: "Mời nhập ID đại lý cấp 1",
            level2AgentIDTips: "Mời nhập ID đại lý cấp 2",
            level1AgentNum: " Số lượng đại lý cấp 1",
            level2AgentNum: " Số lượng đại lý cấp 2",
            level1AgentID: "ID đại lý cấp 1",
            downlineMember: "Hội viên cấp dưới",
            searchTips: "Chỉ hiển thị số liệu của cấp dưới đã kết toán, chưa kết toán sẽ không hiển thị",
            AgentWithdrawal: "Rút tiền đại lý",
            applyProxyTips: "Sau khi nhận được yêu cầu của bạn, chúng tôi sẽ gọi điện cho bạn để xác nhận, hãy đảm rằng điện thoại của bạn luôn liên lạc được để có thể nhanh chóng thông qua xét duyệt",
        },
        invite: {
            title: "Chia sẻ",
            revenue: "Thu nhập ",
            totalRevenue: "Tổng thu nhập",
            invitationBonus: "Phần thưởng mời bạn bè",
            firstDepositBonus: "Phần thưởng nạp tiền lần đầu",
            sharePeople: "Số người chia sẻ",
            shareRedemption: "Đổi thưởng",
            shareWithdrawal: "Rút tiền",
            invitationRecord: "Lịch sử lời mời",
            accountName: "Tên tài khoản",
            registrationTime: "Thời gian đăng ký",
            nowInvita: "Mời ngay",
            inviteRedemption: "Đổi thưởng",
            exchangeRecord: "Lịch sử chuyển đổi",
            exchangeAount: "Số tiền chuyển đổi",
            enterExchangeAount: "Mời nhập số tiền chuyển đổi",
            currentBalance: "Số dư hiện tại：",
            withdrawalRecords: "Lịch sử rút tiền",
            time: "Thời gian",
            status: {
                all: "Toàn bộ",
                underReview: "Đang xét duyệt",
                success: "Thành công",
                pass: "Thông qua",
                turnDown: "Từ chối"
            },
            reasonRejection: "Lý do từ chối：",
            inviteLink: "Link mời bạn bè",
            myExclusiveLink: "Link độc quyền của tôi",
            myShareLink: "Link chia sẻ của tôi",
            download: "Tải xuống",
            inviteWithdrawal: "Rút tiền mời bạn bè",
            confirmAmount: "Xác nhận số tiền rút",
            recycledSuccessfully: "Thu hồi thành công",
            noHaveUsdtAccontTips: "Bạn chưa liên kết tài khoản tiền mã hóa, vui lòng đi đến liên kết",
            noHaveBankAccontTips: "您还未绑定银行卡，请先绑定银行卡",
            activityConditions: "Điều kiện hoạt động",
            downLoadBgFont: "Mời bạn bè kiếm tiền ngay",
            copyLink: "Sao chép liên kết",
            cont1: "1. Mời bạn bè NHẬN ĐƯỢC",
            cont11: "và",
            cont12:"tiền nạp lần đầu của người đó.",
            cont2: "2. Thu nhập có thể đổi thành tiền vàng, khi thu nhập đạt",
            cont21: "trở lên và giới hạn mỗi ngày một lần.",
            cont3: "3. Rút tiền thu nhập sẽ bị trừ",
            cont31: "phí và rút từ",
            cont32: "trở lên.",
            cont4: "4. Tiền thu nhập đổi thành TIỀN VÀNG hoặc RÚT TIỀN sẽ được xử lý trong vòng 24h",
            cont5: "5. Hệ thống sẽ sàng lọc số lượng, hội viên gian lận sẽ bị xóa và phần thưởng tương ứng sẽ bị hủy."
        },
        wallet: {
            arrowSwitchTips: "Xin lưu ý, nhấp vào mũi tên để thay đổi phương thức chuyển khoản",
            transferTips: "Xin lưu ý, tiền chuyển vào và chuyển ra đều bằng đơn vị tiền vàng",
            enterTransferNum: "Mời nhập số tiền chuyển",
            transfer: "Chuyển qũy",
            selectTransferPlatform: "Mời chọn mục chuyển quỹ",
            centerWallet: "Ví trung tâm",
            transferIn: "Chuyển vào",
            transferOut: "Chuyển ra",
            transferNum0: "Vui lòng nhập số tiền chuyển đổi chính xác"
        },
        noble: {
            title: "cấp vip",
            wzzm: "Rất mong đợi",
            wzzmDetail1: "Vào trận tuyệt vời, pháo đạn đặc sắc, ",
            wzzmDetail2: " địa vị",
            pk: "Tiền thưởng PK",
            pkDetail: "lần",
            zslw: "Quà tặng độc quyền",
            zslwDetail: "Món quà cao quý của cấp",
            zslwDetail1: "",
            gzlh: "Con số đặc biệt",
            gzlhDetail: "số đẹp tuyệt",
            gzlhNo: "Không",
            cjft: "Chống bị đá ra ngoài ",
            cjftDetail: "Quản lý phòng không thể tắt tiếng hoặc đá bạn ra khỏi phòng",
            cjftDetail1: "Quản lý phòng và Idol không thể tắt tiếng hoặc đá bạn ra khỏi phòng",
            smcl: "Trò chuyện riêng tư",
            smclDetail: "Trò chuyện riêng tư với IDOL",
            cjys: "Siêu ẩn danh",
            cjysDetail: "Vào phòng, trò chuyện, danh sách ẩn danh",
            cjysDetail1: "Vào phòng ẩn danh",
            buyTips: "Dùng",
            buyTips1: "Tiền vàng để mua cấp",
            renew: "Gia hạn",
            renewSuccess: "Gia hạn thành công",
            award: "Thưởng",
            opened: "Mở",
            expireDate: "Hạn sử dụng",
            upgrade: "Thăng cấp",
            nobleName: "Số đặc biệt của quý tộc",
            prettyNum: "Số đặc biệt",
            stealthFun: "Chức năng ẩn danh",
            roomStealth: "Vào phòng ẩn danh",
            chatStealth: "Trò chuyện ẩn danh",
            topStealth: "Danh sách ẩn danh",
            upgradeConfirmationTips: " Bạn xác nhận là muốn thăng cấp quý tộc?",
            upgradeSuccess: "Thăng cấp thành công",
            upgradeTips: "Bạn sẽ dùng tiền vàng để gia hạn, đồng thời bạn sẽ nhận được phần thưởng gia hạn tương ứng",
            noPermissions: "Hiện tại bạn không có quyền này, vui lòng nâng cấp lên Đại sư hoặc",
            expiredTips: "Thân phận quý tộc của bạn đã hết hạn, mời bạn mua lại",
            turnedOn: "Đã bật",
            turnedOff: "Đã tắt",
        },
        addCard: {
            title: "Liên kết thẻ ngân hàng",
            realName: "Tên thật",
            realNameTips: "Vui lòng nhập họ và tên",
            realNameTips2: "(khoảng cách nếu có)",
            branch: "Chi nhánh",
            branchTips: "Vui lòng nhập tên thành phố",
            code: "Mã xác minh",
            withdrawPassword: "Mật khẩu rút tiền",
            withdrawPasswordTips: "Mật khẩu gồm 6 chữ số",
            withdrawPasswordTips1: "Vui lòng nhập mật khẩu rút tiền",
            chooseBank: "Chọn ngân hàng",
            bindSuccessTips: "Liên kết thành công",
            beyondNumTips: "Số lượng thẻ ngân hàng có thể liên kết đã đạt tối đa",
        },
        mall: {
            title: "Trung tâm mua sắm",
            day: "Ngày",
            toPay: "Trả",
            confirmBuy: "Quyết định mua",
            buySuccess: "Trung tâm mua sắm",
            changeActiveMallTips: "Bạn có muốn sử dụng",
            end: "Hạn sử dụng",
            changeSuccess: "Đã kích hoạt thành công"
        }
    },
    discount: {
        header: "Hoạt động",
        automatic: "Khuyến mãi tự động",
        Application: "Đăng ký CSKH"
    },
    record: {
        dayList: {
            today: "Hôm nay",
            yesterday: "Hôm qua",
            last3Days: "3 ngày",
            last7Days: "7 ngày",
            last30Days: "30 ngày",
            customize: "Tùy chỉnh"
        },
        transactionSuccess: "Giao dịch thành công",
        orderNum: "Mã đơn",
        projectName: "Tên hạng mục",
        orderQuestion: "Bạn còn thắc mắc về đơn này",
    },
    lottery: {
        inBet: "đặt cược",
        closure: "Ngừng cược",
        issue: "Kỳ",
        lotteryNumber: "Số mở thưởng",
        howToPlay: "Cách chơi",
        betRecord: "Lịch sử đặt cược",
        betNow: "Đặt cược",
        setBetAmount: "Cài đặt tiền cược",
        backHall: "Về trang chủ",
        customize: "Tùy chỉnh",
        betAmount: "Tổng cược",
        showAll: "Đã hiển thị toàn bộ",
        betConfirm: "Xác nhận đặt cược",
        confirmBet: "Xác nhận",
        betSuccess: "Đặt cược thành công！",
        No: "",
        expect: "Kỳ",
        chooseLottery: "Chọn trò chơi",
        noMore: "Không còn nữa",
        newBetRecrod: "Không có lịch sử đặt cược mới",
        totalAmount: "Tổng tiền",
        enterCustomAmount: "Sửa đổi chip đặt cược đơn",
        sureClearBet: "Bạn chắc chắn muốn xóa đặt cược của bạn không?",
        entryNumberTips: "Mời nhập số từ 10-20000！",
        fish: {
            title: "Bầu Cua",
            inBet: "Đang đặt cược",
            issue: "Kỳ",
            lu: "Hươu",
            hulu: "Bầu",
            ji: "Gà",
            yu: "Cá",
            xia: "Tôm",
            xie: "Cua"
        },
        big: {
            title: "Tài Xỉu",
        },
        color: {
            title: "Xóc Đĩa",
        },
        status: {
            all: "Toàn bộ",
            wait: "Chờ mở thưởng",
            lose: "Thua",
            win: "Thắng",
            tie: "Hòa"
        },
        detail: {
            betDetail: "Chi tiết đặt cược",
            gameName: "Tên trò chơi：",
            issue: "Kỳ：",
            betTotal: "Tổng cược：",
            betMultiple: "Điểm cược：",
            singleBetAmount: "Tiền cược：",
            betTime: "Thời gian cược：",
            betType: "Loại hình cược：",
            betCont: "Nội dung cược：",
            lotteryResults: "Kết quả mở thưởng：",
            status: "Trạng thái:",
            bonusAmount: "Tiền thưởng：",
            bonusTime: "Thời gian phát thưởng:",
            orderNo: "Mã đơn giao dịch:",
            betNum: "Số lượng cược",
            betAmount: "Tiền cược",
            winningAmount: "Tiền trúng thưởng",
            totalBetNum: "Tổng tiền cược",
            totalWinningAmount: "Tổng tiền trúng thưởng",
        },
        note: "Tổng cược",
        placeABet: "Đặt cược",
    },
    about: {
        tips: "Đặc biệt lưu ý:",
        cont: "Trước khi sử dụng các dịch vụ do THlive cung cấp, bạn cần đọc kỹ, hiểu rõ và đồng ý với nội dung của điều khoản sửa dụng này, bao gồm những điều khoản miễn trừ hoặc hạn chế trách nhiệm. Trường hợp bạn không đồng ý với bất kỳ điều khoản, điều kiện nào trong điều khoản sử dụng này, bạn vui lòng không sử dụng THlive. Khi bạn tham gia, sử dụng THlive được hiểu là bạn đồng ý với toàn bộ nội dung trong điều khoản sử dụng này, cùng với các bản sửa đổi bổ sung tùy từng thời điểm.",
        title1: "I.Điều khoản chung",
        cont1: "1.1 Khi bạn đã đồng ý với nội dung của điều khoản sử dụng này, bạn dựa vào các gợi ý trên trang để đăng ký tài khoản. Sau khi đăng ký thành công tài khoản bạn đã đạt được thỏa thuận với THlive, hoàn toàn chấp nhận các điều kiện trong điều khoản sử dụng này.",
        cont2: "1.2 Sau khi đăng ký thành công, người dùng tự bảo quản tài khoản và mật khẩu của mình.",
        cont3: "1.3 Sau khi đăng ký tài khoản trên THlive, ngoài những kênh yêu cầu quyền hạn mở, thì người dùng có thể sử dụng tất cả các dịch vụ, chương trình trên THlive. Khi bạn sử dụng các dịch vụ của THlive được hiểu bạn đồng ý với điều khoản và thông báo trên THlive.",
        cont4: "1.4 Điều khoản sử dụng và thông báo của THlive được cập nhật thường xuyên, và công bố rông rãi. Khi bạn sử dụng các dịch vụ nào của THlive, bạn nên chú ý và tuân thủ các điều khoản liên quan áp dụng cho dịch vụ đó.",
        title2: "II. Thông tin đăng ký và bảo vệ quyền riêng tư",
        cont21: "2.1 Mọi tài khoản trên THlive thuộc quyền sở hữu của THlive,  bạn điền thông tin theo hướng dẫn của trang đăng ký, đọc và đồng ý với điều khoản sử dụng này, theo trình tự hoàn thành các thủ tục đăng ký, sở hữu tài khoản và trở thành người dùng của THlive. Bạn cần cung cấp chính xác, kịp thời thông tin cá nhân, liên tục cập nhật thông tin để đáp ứng yêu cầu kịp thời, chi tiết, chính xác. THlive không chịu trách nhiệm về các vấn đề liên quan do thông tin đăng ký không trung thực hoặc không cập nhật kịp thời. Bạn có thể sửa đổi thông tin cá nhân thông qua tài khoản trên THlive . Sử dụng thông tin cá nhân để xác thực tài khoản của mình, nếu ảnh đại diện, biệt hiệu hoặc các thông tin bất hợp pháp không được dùng để đăng ký. ",
        cont22: "Đồng thời sau khi đăng ký nếu bị phát hiện đăng ký tài khoản với thông tin sai lệch, biệt hiệu hoặc ảnh đại diện vi phạm pháp luật, THlive có quyền ngừng sử dụng, hủy đăng ký tài khoản mà không cần thông báo.",
        cont23: "2.2 Tài khoản THlive bao gồm tên đăng nhập và mật khẩu đăng nhập, bạn có thể cài đặt tên đăng nhập và mật khẩu đăng nhập; để bảo mật tài khoản của bạn chúng tôi có thể thực hiện các biện pháp xác minh khác nhau. Như khi tài khoản của bạn đăng nhập lần đầu tiên trên một thiết bị mới, chúng tôi có thể thông qua mật khẩu và mã xác minh để xác nhận danh tính của bạn, phương thức xác minh không giới hạn ở mã xác minh SMS.",
        cont24: "2.3 Bạn không được chuyển nhượng, hoặc cho người khác sử dụng tài khoản và mật khẩu của mình, nếu bạn chuyển nhượng cho người khác sử dụng thì phải chịu trách nhiệm về mọi hành động của người được ủy quyền sử dụng. Vì tài khoản được liên kết với thông tin cá nhân của người sử dụng, nên tài khoản chỉ có thể được chuyển nhượng nếu phù hợp pháp luật, hoặc được THlive chấp thuận, tuân thủ quy trình chuyển nhượng tài khoản do THlive quy định.",
        cont25: "2.4 Nếu thông tin tài khoản của bạn bị đánh mất vì lý do cá nhân, bạn có nhu cầu lấy lại thông tin tài khoản, vui lòng cung cấp thông tin tương ứng theo yêu cầu của THlive, đảm bảo thông tin cung cấp là hợp pháp, đúng sự thật. Nếu thông tin cung cấp không đáp ứng nhu cầu, bạn không thể thông qua xác minh của THlive, THlive có quyền từ chối cung cấp thông tin tài khoản.",
        cont26: "2.5 Nếu liên tục 6 tháng bạn không sử dụng tài khoản THlive, THlive có quyền hủy tài khoản của bạn, bạn không thể sử dụng tài khoản để đăng nhập hoặc sử dụng các dịch vụ của THlive.",
        cont27: "2.6 Tuyên bố về việc bảo hộ quyền riêng tư của THlive đã chỉ rõ cách THlive thu nhập và sử dụng thông tin người dùng. Bạn cần đảm bảo rằng trước khi sử dụng các sản phẩm và dịch vụ của THlive, đã hiểu đầy đủ và đồng ý rằng THlive có thể theo đó mà xử lý thông tin của người dùng.",
        cont28: "2.7 Khi bạn sử dụng sản phẩm THlive để đăng nhập cũng như các thao tác khác, máy chủ sẽ tự động nhận, xác minh và ghi lại một số thông tin cần thiết, chẳng hạn như số điện thoại di động, địa chỉ IP, thông tin ngoại lệ truy cập dịch vụ và một số thông tin thiết bị để đảm bảo độ an toàn đăng nhập tài khoản trong quá trình bạn sử dụng dịch vụ THlive để bảo vệ an ninh Internet của bạn. Việc sử dụng các dịch vụ của THlive, cũng cho thấy rằng bạn đồng ý THlive có thể xử lý thông tin cá nhân của bạn theo chính sách bảo mật quyền riêng tư có liên quan. THlive có thể làm việc với các đối tác để cung cấp cho bạn những dịch vụ mà bạn yêu cầu hoặc hiển thị cho bạn những nội dung mà bạn có thể quan tâm. Trong trường hợp thông tin cần thiết cho sản phẩm / dịch vụ, bạn đồng ý rằng THlive có thể chia sẻ thông tin đó. Ngoài ra, THlive sẽ yêu cầu nó đảm bảo an ninh dữ liệu và nghiêm cấm việc sử dụng nó cho bất kỳ mục đích nào khác. THlive sẽ không cung cấp hoặc chia sẻ thông tin cho bất kỳ bên thứ ba không liên quan nào.",
        cont29: "2.8 Bạn hiểu và cho phép THlive sử dụng hoặc đồng bộ hóa thông tin của bạn với các công ty liên kết để cung cấp cho bạn những dịch vụ liên quan đến tín dụng.",
        cont220: "2.9 Nhằm cung cấp dịch vụ tốt nhất cho bạn, THlive sẽ thông qua hình thức SMS gửi đến bạn những thông tin dịch vụ thương mại có liên quan.",
        title3: "III. Quy tắc sử dụng",
        cont31: "3.1 Khi sử dụng các dịch vụ của THlive, bạn phải tuân thủ các quy định của nhà nước CHXHCN Việt Nam về an ninh mạng, quy định quản lý dịch vụ thông tin internet. Bạn không được sử dụng dịch vụ này cho bất kỳ các hoạt động bất hợp pháp nào, như các hành vi sau:",
        cont32: "3.1.1 Tải lên, đăng, tuyên truyền thông tin có chứa một trong những nội dung sau:",
        cont33: "-  Phản đối các nguyên tắc cơ bản do hiến pháp quy định.",
        cont34: "-  Gây nguy hiểm đến an ninh quốc gia, lộ bí mật nhà nước, chống phá nhà nước và đoàn kết dân tộc.",
        cont35: "-  Ảnh hưởng đến danh dự và lợi ích của quốc gia",
        cont36: "-  Kích động hận thù dân tộc, kỳ thị sắc tộc, phá hoại đoàn kết dân tộc.",
        cont37: "-  Phá hoại chính sách tôn giáo của chính phủ, tuyên truyền cổ súy cho tà giáo và hủ tục mê tín lạc hậu.",
        cont38: "-  Phát tán tin đồn, gây dối trật tự xã hội, phá hoại sự ổn định của xã hội.",
        cont39: "-  Truyền bá nội dung tục tĩu, khiêu dâm, cờ bạc, bạo lực, giết người, khủng bố, xúi giục tội phạm.",
        cont320: "-  Xúc phạm hoặc vu khống người khác, xâm phạm các quyền hợp pháp của người khác.",
        cont321: "-  Chứa nội dung sai lệch, đe dọa, xâm phạm quyền riêng tư của người khác, hoặc các nội dung vi phạm đạo đức khác.",
        cont322: "-  Chứa các nội dung bị hạn chế hoặc bị cấm bởi luật, quy định, quy tắc của nhà nước Việt Nam.",
        cont323: "3.1.2 Không được sử dụng hệ thống dịch vụ mạng cho bất kỳ mục đích bất hợp pháp nào.",
        cont324: "3.1.3 Không lợi dụng THlive để tham gia các hoạt động sau:",
        cont325: "-  Vào mạng thông tin máy tính hoặc sử dụng tài nguyên thông tin máy tính mà không được cho phép.",
        cont326: "-  Xóa, sửa đổi hoặc thêm các chức năng mạng thông tin máy tính khi chưa được cho phép.",
        cont327: "-  Xóa, sửa đổi, hoặc thêm các dữ liệu , ứng dụng vào mạng thông tin máy tính khi chưa được cho phép.",
        cont328: "-  Cố ý tạo ra, phát tán vi rút máy tính, phá hoại các chương trình khác",
        cont329: "-  Các hành vi khác gây nguy hiểm cho an ninh mạng máy tính.",
        cont330: "3.2 Nếu bạn vi phạm điều khoản sử dụng này hoặc các quy định điều khoản dịch vụ có liên quan, gây ra tổn thất, khiếu nại từ bên thứ 3 nào, bạn phải bồi thường phí tổn thất cho THlive, các công ty đối tác và công ty liên kết của THlive. Về vấn đề này, THlive có quyền tùy theo bản chất hành vi của bạn, thực hiện các biện pháp bao gồm nhưng không giới hạn ở việc xóa thông tin đã đăng của bạn, tạm ngừng sử dụng , chấm dứt dịch vụ, hạn chế sử dụng, thu hồi tài khoản của THlive và điều tra các trách nhiệm pháp lý. Đối với việc cố ý đăng ký tài khoản THlive, lợi dụng tài khoản THlive để thực hiện các hoạt động bất hợp pháp, gây rối, lừa bip người dùng khác và các hành vi vi phạm điều khoản sử dụng này, THlive có quyền thu hồi tài khoản . Đồng thời, THlive sẽ hỗ trợ điều tra theo yêu cầu của bộ tư pháp.",
        cont331: "3.3. Bạn không được sao chép, bán, chuyển nhượng bất kỳ bộ phận nào của dịch vụ này cho bất kỳ mục đích thương mại nào khác.",
        cont332: "3.4 Bạn phải chịu trách nhiệm pháp lý về các hành động của mình trong quá trình sử dụng THlive. Các hình thức để bạn chịu trách nhiệm pháp lý bao gồm nhưng không giới hạn ở việc bồi thường cho người bị xâm hại, sau khi THlive chịu phạt hành chính do hành vi của bạn gây ra, bạn phải bồi thường cho THlive số tiền tương ứng.",
        cont333: "3.5 Bạn cần tuân thủ các điểm mẫu chốt về internet dưới đây khi sử dụng dịch vụ của THlive:",
        cont334: "3.5.1 Luật và quy định",
        cont335: "3.5.2 Hệ thống xã hội chủ nghĩa",
        cont336: "3.5.3 Lợi ích quốc gia",
        cont337: "3.5.4 Quyền và lợi ích hợp pháp của công dân",
        cont338: "3.5.5 Trật tự công cộng xã hội",
        cont339: "3.5.6 Nếp sống đạo đức",
        cont340: "3.5.7 Tính xác thực thông tin",
        title4: "IV. Nội dung dịch vụ",
        cont41: "4.1 Các nội dung dịch vụ của THlive, do THlive cung cấp dựa vào tình hình thực tế.",
        cont42: "4.2 Trừ khi có quy định rõ ràng khác trong điều khoản sử dụng dịch vụ này, các sản phẩm mới, chức năng mới và dịch vụ mới do THlive đưa ra đều phải tuân theo các chuẩn mực trong điều khoản sử dụng dịch vụ này.",
        cont43: "4.3 Để sử dụng dịch vụ này, bạn phải tự mình truy cập Internet thông qua một bên thứ ba đủ điều kiện pháp lý để cung cấp cho bạn các dịch vụ truy cập Internet và tự thanh toán các phí dịch vụ liên quan. Ngoài ra, bạn phải tự chuẩn bị và chịu trách nhiệm về tất cả các thiết bị cần thiết để kết nối mạng quốc tế, bao gồm máy tính, modem hoặc các thiết bị truy cập khác.",
        cont44: "4.4 Xét theo đặc thù của các dịch vụ mạng, bạn đồng ý rằng THlive có quyền thay đổi, gián đoạn hoặc chấm dứt một số hoặc tất cả các dịch vụ mạng (bao gồm cả các dịch vụ mạng trả phí) bất kỳ lúc nào mà không cần thông báo trước. THlive không đảm bảo rằng các dịch vụ mạng sẽ không bị gián đoạn, cũng như không đảm bảo tính kịp thời, an toàn và chính xác của các dịch vụ mạng.",
        cont45: "4.5 THlive không chịu trách nhiệm nào về việc dịch vụ mạng bị gián đoạn trong các trường hợp sau:",
        cont46: "4.5.1 THlive kiểm tra, sửa chữa, bảo trì các thiết bị, dịch vụ mạng theo định kỳ hoặc không theo định kỳ. THlive có quyền tạm dừng bất kỳ bộ phận nào của dịch vụ để bảo trì, nâng cấp, hoặc mục đích khác mà không cần thông báo trước.",
        cont47: "4.5.2 Các trường hợp bất khả kháng như: bão, động đất, lũ lụt...4.5.2 Các trường hợp bất khả kháng như: bão, động đất, lũ lụt...",
        cont48: "4.5.3 Phần mềm, phần cứng máy tính của người dùng, đường truyền tín hiệu xảy ra lỗi.",
        cont49: "4.5.4 Sự tấn công của virut và các chương trình độc hại, tắc nghẽn mạng, hệ thống không ổn định, hệ thống hoặc thiết bị bị lỗi, lỗi từ phía ngân hàng hoặc bên thứ ba. THlive sẽ thực hiện các hành động hợp lý để thúc đẩy hoạt động dịch vụ trở lại bình thường.",
        cont420: "4.5.5 Dịch vụ THlive hoặc bên thứ ba có thể cung cấp liên kết đến các trang web hoặc tài nguyên khác trên Internet quốc tế. Vì THlive không có quyền kiểm soát các trang web và tài nguyên này, bạn hiểu và đồng ý rằng THlive không chịu trách nhiệm về việc các trang web hoặc tài nguyên đó có sẵn để sử dụng hay không; THlive cũng không đảm bảo hoặc chịu trách nhiệm về bất kỳ nội dung, quảng cáo, sản phẩm hoặc thông tin nào khác tồn tại hoặc bắt nguồn từ các trang web hoặc tài nguyên đó. THlive không chịu bất cứ trách nhiệm nào về bất kỳ thiệt hại, tổn thất nào phát sinh từ việc sử dụng nội dung, hàng hóa hoặc dịch vụ nào xuất hiện hoặc có được thông qua các trang web hoặc tài nguyên đó.",
        cont421: "4.6 Bạn đồng ý rằng những rủi ro tồn tại trong việc sử dụng dịch vụ mạng THlive do bạn hoàn toàn tự chịu trách nhiệm. Bạn hiểu và chấp nhận rằng bất kỳ thông tin nào được tải xuống hoặc lấy thông qua dịch vụ THlive đều phụ thuộc vào bạn và bạn có khả năng sẽ chịu thiệt hại về hệ thống, mất mát dữ liệu hoặc bất kỳ rủi ro nào khác. THlive không đảm bảo bất kỳ dịch vụ mua sắm , tiến độ giao dịch và thông tin tuyển dụng nào có được trên mạng dịch vụ THlive.",
        cont422: "4.7 Lưu ý : Trong số các dịch vụ thúc đẩy khai thác do THlive cung cấp, các liên kết đến các trang web hoặc tài nguyên mà người dùng đã truy cập sẽ tự động được khởi chạy dựa trên các thuật toán của máy tính. THlive không đưa ra bất cứ đảm bảo nào về sự hiệu quả, an toàn và hợp lệ về mặt nội dung.",
        cont423: "4.8 THlive có quyền chấm dứt tạm thời hoặc vĩnh viễn dịch vụ này ( hoặc bất kỳ bộ phận nào), bất kể có thông báo hay không, THlive không chịu trách nhiệm với người dùng hoặc bất kỳ bên thứ ba nào.",
        cont424: "4.9 Chấm dứt dịch vụ",
        cont425: "Bạn đồng ý rằng THlive Live sẽ thu hồi mật khẩu và tài khoản của bạn hoặc tạm dừng việc sử dụng dịch vụ (hoặc bất cứ bộ phận nào của dịch vụ) dựa trên những cân nhắc riêng của mình vì bất kỳ lý do gì, bao gồm nhưng không giới hạn ở việc bạn không sử dụng tài khoản lâu dài (hơn một năm), hoặc THlive cho rằng bạn đã vi phạm tinh thần của điều khoản sử dụng dịch vụ này . Bạn cũng đồng ý rằng dịch vụ được cung cấp theo bất kỳ điều khoản nào của điều khoản sử dụng dịch vụ này có thể bị gián đoạn hoặc chấm dứt mà không cần thông báo trước. Bạn xác nhận cũng như đồng ý rằng THlive có thể ngay lập tức đóng hoặc xóa tài khoản của bạn cũng như tất cả thông tin và tập tin liên quan trong tài khoản của bạn, hoặc cấm tiếp tục sử dụng các tệp tin nói trên trên dịch vụ này. Ngoài ra, bạn cũng đồng ý rằng nếu việc sử dụng dịch vụ này bị gián đoạn, chấm dứt hoặc tài khoản và các thông tin và tập tin liên quan bị đóng hoặc bị xóa, THlive Live sẽ không chịu trách nhiệm với bạn hoặc bất kỳ bên thứ ba nào.",
        title5: "V. Quyền sở hữu trí tuệ và các quyền hợp pháp khác",
        cont51: "5.1 Quyền độc quyền của người dùng",
        cont52: "THlive tôn trọng quyền sở hữu trí tuệ và các quyền hợp pháp của người khác, đồng thời kêu gọi bạn cũng tôn trọng quyền sở hữu trí tuệ và quyền hợp pháp của người khác. Nếu bạn cho rằng quyền sở hữu trí tuệ và quyền hợp pháp của bạn bị vi phạm , vui lòng làm theo hướng dẫn bên dưới để cung cấp thông tin cho THlive",
        cont53: "Xin lưu ý: Nếu điều bạn trình bày là không đúng sự thật, bạn phải chịu mọi trách nhiệm pháp lý ( bao gồm nhưng không giới hạn ở việc đền bù các chi phí và phí luật sư). Nếu bạn không chắc chắn các thông tin trên internet có vi phạm quyền sở hữu trí tuệ và các quyền hợp pháp khác của mình hay không, THlive khuyến nghị bạn trước tiên nên tham khảo ý kiến chuyên gia.",
        cont54: "Để THlive xử lý hiệu quả thông tin bảo vệ quyền lợi nêu trên của bạn, vui lòng sử dụng quy cách sau:",
        cont55: "5.1.1 Cung cấp bằng chứng về nội dung vi phạm quyền sở hữu trí tuệ và các quyền hợp pháp khác theo quy định của pháp luật.",
        cont56: "5.1.2 Vui lòng mô tả đầy đủ, rõ ràng hành vi xâm phạm quyền sở hữu trí tuệ hoặc các quyền và lợi ích hợp pháp khác, đồng thời vui lòng cung cấp trang web (nếu có) bị nghi ngờ vi phạm.",
        cont57: "5.1.3 Vui lòng cho biết nội dung nào của trang web bị cáo buộc vi phạm các quyền được liệt kê trong mục 2.",
        cont58: "5.1.4 Vui lòng cung cấp vị trí của nội dung bị cáo buộc vi phạm trên mạng thông tin (ví dụ: ghi rõ nguồn nội dung vi phạm mà bạn đã báo cáo, đó là: địa chỉ trang web hoặc vị trí trong trang web) để chúng tôi có thể liên hệ với chủ sở hữu /quản trị viên của trang web mà bạn đã báo cáo chứa nội dung vi phạm.",
        cont59: "-  Đối với bất kỳ nội dung nào mà bạn tải lên trên trang web phát trực tiếp THlive thông qua dịch vụ phát trực tiếp THlive, bạn đồng ý rằng các chương trình phát trực tiếp THlive là miễn phí, vĩnh viễn, không thể thu hồi, không độc quyền và được sao chép hoàn toàn trên toàn thế giới. Cho phép sử dụng, sao chép, sửa đổi, phóng tác, xuất bản, dịch, tạo các tác phẩm truyền tải, biểu diễn và hiển thị nội dung đó (toàn bộ hoặc một phần), hoặc biên dịch nội dung đó thành nội dung hiện có được biết đến hoặc phát triển sau này trong bất kỳ hình thức công việc, phương tiện hoặc công nghệ nào khác.",
        cont520: "-  THlive sở hữu bản quyền của tất cả các tài liệu trên trang web này.  Mọi hoạt động duyệt, sao chép, in ấn và phổ biến tài liệu thuộc trang web này phải được phép và đáp ứng các điều kiện sau:",
        cont521: "Tất cả các tài liệu và hình ảnh nhằm mục đích thu thập thông tin; ",
        cont522: "Tất cả các tài liệu và hình ảnh sẽ không được sử dụng cho mục đích thương mại;",
        cont523: "Tất cả các tài liệu, hình ảnh và bất kỳ bộ phận nào phải bao gồm thông báo bản quyền này;",
        cont524: "Tất cả các sản phẩm, công nghệ và chương trình trên trang web này thuộc quyền sở hữu trí tuệ của THlive. 'THlive Live' và các đồ họa liên quan là thương hiệu đã đăng ký của THlive Live.",
        title6: "VI. Thanh thiếu niên đặc biệt lưu ý",
        cont61: "Thanh thiếu niên phải tuân thủ các công ước văn minh mạng quốc gia, hoàn thành tốt việc học tập trên mạng, không xem các thông tin xấu, giao tiếp với bạn bè tốt, không xúc phạm lừa dối người khác, có ý thức tự bảo về bản thân, không tùy ý hẹn hò với bạn bè trên mạng, bảo vệ an ninh mạng, giữ gìn sức khỏe, không mê đắm vào thời gian và không gian ảo.",
        title7: "VII. Những điều khoản khác",
        cont71: "7.1 Luật pháp của CHXHCN Việt Nam sẽ được áp dụng để ký kết, thực hiện và giải thích điều khoản sử dụng này và giải quyết tranh chấp.",
        cont72: "7.2 Nếu có bất kỳ tranh chấp nào giữa hai bên về nội dung của điều khoản sử dụng này hoặc việc thực hiện điều khoản sử dụng này, hai bên sẽ cố gắng hết sức để giải quyết thông qua thương lượng hữu nghị.",
        cont73: "7.3 Việc THlive không thực hiện hoặc thực hiện các quyền lợi và quy định trong điều khoản sử dụng này không cấu thành sự từ bỏ những quyền lợi đã nêu ở trên.",
        cont74: "7.4 Nếu bất kỳ điều khoản nào trong thỏa thuận này không có giá trị hoàn toàn hoặc một phần hoặc không thể thi hành vì bất kỳ lý do gì, thì các điều khoản còn lại trong thỏa thuận này vẫn có giá trị và ràng buộc.",
        cont75: "7.5 Hoan nghênh bạn sử dụng dịch vụ phát trực tiếp THlive (dưới đây gọi là 'dịch vụ đơn lẻ') trên cơ sở điều khoản sử dụng này, đồng thời tuân theo các quy định cụ thể của thỏa thuận dịch vụ cá nhân.",
        cont76: "Vui lòng thông báo cho THlive khi bạn phát hiện bất kỳ vi phạm nào đối với điều khoản sử dụng này và các điều khoản dịch vụ của bất kỳ dịch vụ cá nhân nào khác cũng như các thông báo khác của THlive.  Bạn có thể liên hệ với chương trình phát trực tiếp THlive thông qua phương thức liên hệ sau: Phản hồi ý kiến về chương trình phát trực tiếp THlive.",
    },
    liveRoom: {
        blocked: "Đã chặn",
        blockedTips: "Bỏ chặn",
        pullBlack: "Chặn",
        pullBlackTips: "Chặn thành công",
        audience: "Người xem",
        contribute: "Đóng góp",
        previewCountdown: "Thời gian xem trước còn",
        wellcome: "Chào mừng đến với phòng phát trực tiếp của",
        liveRoom: "",
        anchorLeave: "IDOL đã rời phòng",
        anchorComeBack: "IDOL đã trở lại",
        mysteryMan: "Người bí ẩn",
        focusOnTips: "Đã yêu IDOL ngay từ cái nhìn đầu tiên, và đã theo dõi IDOL",
        honorable: "chào mừng các bậc cao quý, hoan nghênh",
        wellcomeN: "Đến rồi, hoan nghênh",
        sendOut: "Đã tặng",
        noTalking: "Bạn đã bị cấm phát ngôn",
        talking: "Đã gỡ cấm, bạn có thể phát ngôn ",
        kickOutLiveRoom: "Đã bị đuổi ra khõi phòng phát trực tiếp này",
        system: "Hệ thống",
        winLottery: "Trúng thưởng",
        isHousingManagement: "Xin chúc mừng",
        isHousingManagement2: "đã được bổ nhiệm làm quản lý phòng",
        noHousingManagement: "thật đáng tiếc",
        noHousingManagement2: "bạn đã bị hủy bỏ chức vụ quản lý",
        betMsgIn: "Trong ",
        betMsgBet: "đã đặt cược",
        followUp: "Chi tiết",
        winLotteryMsg: "đã tăng",
        anchorUpgradeTips: "Xin chúc mừng IDOL đã được thăng cấp",
        userUpgradeTips: "Chúc mừng bạn đã lên cấp",
        shortBalanceTips: "Số dư không đủ, vui lòng nạp thêm",
        inProgress: "Đang tiến hành",
        userEnterLiveTips: "Đã vào phòng live",
        setChips: "Thiết lập chip",
        result: "Kết quả",
        passwordRoom: "Mật khẩu phòng",
        entryPswTips: "Nhập mật khẩu",
        lotteryRecord: "Lịch sử mở thưởng",
        endLive: "Đã kết thúc phát trực tiếp",
        kickOutLiveRoomTips: "Bạn đã bị đá khỏi phòng",
        goHome: "Quay lại trang chủ",
        recommended: "Bảng xếp hạng",
        payTips: "Xác nhận trả",
        payTips1: "Thanh toán",
        payTips11: "tiền vàng để tiếp tục xem live",
        payTips2: "Mỗi phút thanh toán",
        payTips21: "tiền vàng để tiếp tục xem live",
        jumpOver: "Bỏ qua",
        toPay: "Thanh toán",
        giftList: "Danh sách đóng góp",
        today: "Hôm nay",
        month: "Tháng",
        hasSend: "đã tặng",
        received: "Đã nhận",
        homePage: "Trang chủ",
        qHer: "Bạn ấy",
        saySomeThing: "Nói chuyện",
        newMsg: "Tin nhắn mới",
        silence: "Tắt tiếng",
        silenceSecc: "Tắt tiếng thành công",
        cancelSilence: "Bật tiếng",
        cancelSilenceSecc: "Hủy tắt tiếng thành công",
        kickOutLive: "Đã người",
        kickOutLiveSecc: "Đá thành công",
        changeRoom: "Chuyển đổi phòng",
        manage: "Quản lý",
        disabledNum: "Khoá tài khoản",
        disabledNumSecc: "Khóa tài khoản thành công",
        disabledAir: "Khoá máy",
        disabledAirSecc: "Khóa máy thành công",
    },
    common: {
        finish: "Hoàn thành",
        cancel: "Hủy",
        confirm: "Xác nhận",
        more: "Nhiều hơn",
        getCode: "Lấy mã xác nhận",
        getCodeSuccess: "Lấy mã thành công",
        nextStep: "Tiếp tục",
        refreshSuccess: "Làm mới thành công ",
        refreshFailed: "Làm mới không thành công!",
        eidt: "Sửa đổi ",
        submit: "Gửi ",
        submitSuccess: "Gửi thành công ",
        hint: "Lời nhắc ",
        copy: "Sao chép",
        copySuccess: "Sao chép thành công! ",
        copyLost: "Sao chép không thành công! ",
        eidtSuccess: "Sửa đổi thành công! ",
        fullAmount: "Toàn bộ số dư", 
        noData: "Không có dữ liệu",
        inDevelop: "Đang khai thác, vui lòng chờ đợi",
        kindTips: "Lời nhắc",
        completeInformation: "Hoàn thiện thông tin",
        sure: "Xác định",
        reset: "Cài đặt lại",
        all: "Toàn bộ",
    }
}
